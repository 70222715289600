import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * It takes an array of usernames and deletes them from the database
 * @param {string[]} usernames - string[]
 * @returns A promise that resolves to an object with the following properties:
 */
export const deletePreviewUsers = (usernames: string[], tenant: string | null): Promise<any> => {
  const url = `${API_URL}/users/preview`;
  const method = 'DELETE';
  const query = { tenant };
  return fetcher(queryString.stringifyUrl({ url, query }), {
    method,
    body: JSON.stringify({ usernames }),
  });
};
