import { emptyQuota } from '../../quotas-admin/dialogs/EditQuotaSalesDialog/quota.model';
import { emptyTemplate } from '../../templates-admin/dialogs/EditTemplateDialog/emptyTemplate';
import { emptyUser } from '../../users-admin/dialogs/EditUserDialog/emptyUser';
import { emptyVehicle } from '../../vehicles-admin/dialogs/EditVehicleDialog/emptyVehicle';
import { invoiceTemplateFormConfig } from '../configs/invoiceTemplateFieldsConfig';
import { quotaFormConfig } from '../configs/quotaSaleFieldsConfig';
import { templateFormConfig } from '../configs/templateFieldsConfig';
import { userFormConfig } from '../configs/userFieldsConfig';
import { vehicleFormConfig } from '../configs/vehicleFieldsConfig';

import { FieldHelper } from './fieldHelper';
import { FormConfigurationTypeEnum, IButtonState, IFormConfig, IFormField } from './interfaces';
import { TabsHelper } from './tabsHelper';
import { FieldValidationResult } from './validationHelper';

export class FormHelper extends FieldHelper {
  /**
   * Holds the type of the object we are
   * currently working with (user or vehicle
   * for now).
   */
  private type: FormConfigurationTypeEnum;
  /**
   * Helps process the tabs and checks if there
   * are any tabs that should not be show in
   * the current mode.
   */
  private tabsHelper = new TabsHelper();

  constructor(type: FormConfigurationTypeEnum) {
    super();
    this.type = type;
  }

  /**
   * Get the list of required fields
   * for the current form.
   *
   * @param type FormConfigurationTypeEnum
   * @param mode string
   * @returns IFormConfig
   */
  public getFormConfiguration(type: FormConfigurationTypeEnum, tenant: string): IFormConfig | null {
    switch (type) {
      case FormConfigurationTypeEnum.USER:
        return this.tabsHelper.processConfig(userFormConfig(tenant));
      case FormConfigurationTypeEnum.VEHICLE:
        return this.tabsHelper.processConfig(vehicleFormConfig);
      case FormConfigurationTypeEnum.TEMPLATE:
        return this.tabsHelper.processConfig(templateFormConfig);
      case FormConfigurationTypeEnum.INVOICE_TEMPLATE:
        return this.tabsHelper.processConfig(invoiceTemplateFormConfig);
      case FormConfigurationTypeEnum.QUOTA_SALE:
        return this.tabsHelper.processConfig(quotaFormConfig);
      default:
        return null;
    }
  }

  /**
   * Get an empty object of the type currently
   * set in the instance.
   *
   * @returns any
   */
  public getEmptyObject(): any {
    switch (this.type) {
      case FormConfigurationTypeEnum.USER:
        return emptyUser;
      case FormConfigurationTypeEnum.VEHICLE:
        return emptyVehicle;
      case FormConfigurationTypeEnum.TEMPLATE:
        return emptyTemplate;
      case FormConfigurationTypeEnum.QUOTA_SALE:
        return emptyQuota;
      default:
        return null;
    }
  }

  /**
   * Validates the provided list of fields and return an array with the validation errors.
   *
   * @param fields the fields to be validated
   * @returns an array containing all the validation errors; if there are no errors, then an empty
   * array is returned.
   */
  public validateForm(fields: IFormField[]): FieldValidationResult[] {
    return this.validationHelper.validateForm(fields);
  }

  /**
   * Update the provided button state depending on
   * the depending field from the list of provided
   * fields.
   *
   * @param buttonState IButtonState
   * @param fields IFormField[]
   * @param setButtonState Function
   * @returns void
   */
  public updateButtonStates(buttonStates: IButtonState[], fields: IFormField[], setButtonStates: Function): void {
    return this.validationHelper.updateButtonStates(buttonStates, fields, setButtonStates);
  }

  /**
   * Get the object to be saved.
   */
  public getSaveObject(fields: IFormField[], mode: 'edit' | 'create', type: FormConfigurationTypeEnum): any {
    /**
     * Check which fields should be removed
     * given the current save mode (create | edit).
     */
    let filteredFields = this.filterFieldsByMode(fields, mode);
    /**
     * Remove the fields which are marked to not be
     * saved if changes didn't occur.
     */
    filteredFields = this.filterFieldsByChange(filteredFields);
    /**
     * CREATE mode: empty fields will be removed.
     * EDIT mode: empty field values will be set to NULL.
     */
    // Also, remove 'remoteEmail' field when you create a new vehicle
    filteredFields = this.removeEmptyFields(filteredFields, mode).filter(
      (field) => type !== FormConfigurationTypeEnum.VEHICLE || field.key !== 'remoteEmail',
    );
    // Convert the list of fields to a vehicle object.
    return filteredFields.reduce((prev: any, currField: IFormField) => {
      return { ...prev, [currField.key]: currField.value };
    }, {});
  }
}
