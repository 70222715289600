import { useContext } from 'react';
import { Copyright } from '@mui/icons-material';
import { Box, Container, Grid, Toolbar } from '@mui/material';

import ProgressCard from '../common/cards/ProgressCard';
import { ContextActionEnum, GlobalContext } from '../context/GlobalState';

import EditInvoiceTemplateDialog from './dialogs/EditInvoiceTemplateDialog/EditInvoiceTemplateDialog';
import InvoiceTemplatesList from './InvoiceTemplatesList';

const InvoiceTemplates = () => {
  /**
   * Get all the required properties from the
   * global context.
   */
  const { modal, setModal, triggerAction, selectedIds, invoiceTemplates } = useContext(GlobalContext);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />

      <Container
        sx={{ mt: 4, mb: 4 }}
        maxWidth={false}
      >
        <Grid
          container
          spacing={2}
        >
          {triggerAction && (
            <Grid
              item
              xs={12}
            >
              <ProgressCard />
            </Grid>
          )}

          <Grid
            item
            lg={12}
          >
            <InvoiceTemplatesList />
          </Grid>
        </Grid>
        <Copyright sx={{ pt: 4 }} />
      </Container>

      {/* Edit Invoice Template Dialog. */}
      {modal && modal.type === ContextActionEnum.EDIT_INVOICE_TEMPLATE && (
        <EditInvoiceTemplateDialog
          setModal={setModal}
          template={selectedIds?.length === 1 ? invoiceTemplates.find((t) => t.id === selectedIds[0]) : null}
        />
      )}
    </Box>
  );
};

export default InvoiceTemplates;
