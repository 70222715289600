import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

export function ServerError() {
  const { t } = useTranslation('errors');

  return (
    <Box sx={{ p: 4 }}>
      <Typography
        variant="h5"
        sx={{ mb: 2 }}
      >
        {t('500.title')}
      </Typography>
      <Typography>{t('500.message')}</Typography>
    </Box>
  );
}
