const timeOptions: Intl.DateTimeFormatOptions = {
  timeZone: 'Europe/Berlin',
  timeStyle: 'short',
};

/**
 * Returns formatted date time (YYYY-MM-DD hh-mm)
 * @param value Date or timestamp
 */
export const formatDateTime = (value: Date | string | null) => {
  if (!value) {
    return null;
  }

  if (!(value instanceof Date)) {
    value = new Date(value);
  }

  const date = formatDate(value);
  const time = value.toLocaleTimeString('de-DE', timeOptions);

  return `${date} ${time}`;
};

/**
 * Returns formatted date (YYYY-MM-DD)
 * @param value Date or timestamp
 */
export const formatDate = (value: Date | string | null) => {
  if (!value) {
    return null;
  }

  if (!(value instanceof Date)) {
    value = new Date(value);
  }

  return value.toISOString().split('T').shift();
};

/**
 * Returns the formatted date or '---' if the date is invalid.
 * @param {string} date - Usually in ISO format.
 * @returns Returns the parsed date if it is successfully formatted using the `formatDate` function.
 * If the input date is empty or cannot be parsed, it returns '---'.
 */
export const formatQuotaDate = (date: string): string => {
  if (!date) return '---';

  const parsedDate = formatDate(date);
  if (!parsedDate) return '---';

  return parsedDate;
};
