import { fetchAuthSession } from '@aws-amplify/core';
import { Amplify } from 'aws-amplify';

/**
 * New authentication will gradually replace `auth.ts`.
 */
const userPoolId = process.env.REACT_APP_USER_POOL_ID;
const userPoolClientId = process.env.REACT_APP_USER_POOL_CLIENT_ID;
const isUserPoolSet = userPoolId !== undefined && userPoolId.length && userPoolClientId !== undefined;

if (isUserPoolSet) {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId,
        userPoolClientId,
      },
    },
  });
}

export async function getAccessToken(): Promise<string> {
  const session = await fetchAuthSession();
  if (!session.tokens) throw new Error('Cannot get tokens from Amplify session');

  const { exp } = session.tokens.accessToken.payload;

  // Refresh token if it expires in less than 2 minutes
  const freshSession =
    !exp || exp - Date.now() / 1000 >= 120 ? session : await fetchAuthSession({ forceRefresh: true });

  if (!freshSession.tokens) throw new Error('Cannot get fresh tokens from Amplify session');

  return freshSession.tokens.accessToken.toString();
}
