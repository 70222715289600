import queryString from 'query-string';

import { API_URL } from '../../../../../constants/api';
import { QUERY_QUOTAS } from '../../../../../constants/parameters';
import { VehicleRegistrationListedItem } from '../../../../../types/openapi';
import { fetcher } from '../../../../../utils/fetcher';
import { IDuplicateQuotaData, IVehicle } from '../../api/dto';

/**
 * Check the quota duplication state for the
 * provided vehicle object.
 *
 * Note: this method will update the quotaSales
 * lists each individual quotaState object with
 * an isDuplicate property depending on its
 * duplication state.
 */
export const markDuplicate = async (vehicle: IVehicle): Promise<IVehicle> => {
  // Check if current vehicle is a duplicate.
  return await markQuotaDuplicationsForVehicle(vehicle);
};

/**
 * Check the quotas duplication state for
 * the provided vehicle and update the quota
 * objects with the isDuplicate property.
 *
 * @param vehicle IVehicle
 * @returns Promise<IVehicle>
 */
const markQuotaDuplicationsForVehicle = async <T extends IVehicle | VehicleRegistrationListedItem>(
  vehicle: T,
): Promise<T & { duplicateQuota?: IDuplicateQuotaData }> => {
  if (vehicle?.carId) {
    /**
     * Check which quotas already exist for the current
     * vehicle and then query for duplication for the
     * ones that have not yet been created.
     */
    const foundQuotas = QUERY_QUOTAS.reduce((result: [year: number, quotaId: string][], currYear: number) => {
      const quotaSales = vehicle.quotaSales?.find((currQuota) => currQuota.uba_year === currYear);
      if (quotaSales?.uuid) {
        result.push([currYear, quotaSales?.uuid]);
      }

      return result;
    }, []);

    /**
     * Parse through the quotaSales and make the
     * call for all the uba_years available for the
     * current vehicle.
     */
    for (const [quotaYear, quotaId] of foundQuotas) {
      const preparedParams: any = {
        vin: vehicle.carId,
        uba_year: quotaYear,
        ignoreQuotaSaleIds: [quotaId],
        status: 'VEHICLE_AUDITED',
      };
      const query = { tenant: vehicle.tenant };
      const url = `${API_URL}/admin/vin/checkDuplicate`;
      const currentQuotaDuplicateState = await fetcher(queryString.stringifyUrl({ url, query }), {
        method: 'POST',
        body: JSON.stringify(preparedParams),
      });
      vehicle = addDuplicateQuotaYear(vehicle, currentQuotaDuplicateState);
    }
  }
  return await new Promise((resolve: any) => resolve(vehicle));
};

/**
 * Add the DuplicateQuotaYear data on
 * the provided vehicle object.
 *
 * @param vehicle IVehicle
 * @param result boolean
 * @returns void
 */
const addDuplicateQuotaYear = <T extends IVehicle | VehicleRegistrationListedItem>(
  vehicle: T,
  result: IDuplicateQuotaData,
): T & { duplicateQuota?: IDuplicateQuotaData } => {
  const previousDuplicates = (vehicle as any).duplicateQuota?.duplicates || [];
  return {
    ...vehicle,
    duplicateQuota: {
      duplicates: [...previousDuplicates, ...result.duplicates],
      duplicatesFound: result.duplicatesFound,
    },
  };
};
