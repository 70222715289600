import toast from 'react-hot-toast';

import { parseResponseJsonError } from '../../../../../utils/fetcher';
import { FormHelper } from '../../../common/form-generator/formHelper';
import { FormConfigurationTypeEnum, IButtonState, IFormField } from '../../../common/form-generator/interfaces';
import {
  hasCorrectStatus,
  hasDuplicateQuota,
  hasIncompleteStatus,
  noValue,
  required,
} from '../../../common/form-generator/validators';
import { IVehicle } from '../../api/dto';
import { manuallyMarkVehicleAudit } from '../../api/manuallyApproveAudit';
import { updateVehicleStatus } from '../../api/updateVehicleStatus';

import { markDuplicate } from './useDuplicateCheck';

/**
 * Manually approve the audit.
 */
const handleAuditMark = async (vehicleId: string, approve: boolean, tenant: string) => {
  try {
    const response = await manuallyMarkVehicleAudit(vehicleId, approve, tenant);

    if (!response.ok) {
      const error = await parseResponseJsonError(response);
      toast.error('Failed to change the vehicle status.' + (error ? ` Error: ${error}` : ''));
      return false;
    }

    toast.success('Vehicle status changed successfully');
    return true;
  } catch (error) {
    toast.error(`Failed to change the vehicle status. Error: ${error}`);
    return false;
  }
};

export const vehicleDialogButtonsStates = (tenant: string): IButtonState[] => [
  {
    key: 'approved',
    label: 'Fahrzeug geprüft',
    active: true,
    conditions: [
      { key: 'errMsg', validator: noValue },
      { key: 'imageErrorStatus', validator: noValue },
      { key: 'status', validator: hasCorrectStatus },
      { key: 'duplicateQuota', validator: hasDuplicateQuota },
    ],
    onClick: async (
      vehicleId: string,
      setShouldSave: Function,
      fields: IFormField[],
      setFields: Function,
      currentObject: IVehicle,
      setCurrentObject?: any,
      isForced?: boolean,
    ) => {
      // Helps transform form elements according to needs.
      const formHelper: FormHelper = new FormHelper(FormConfigurationTypeEnum.VEHICLE);
      // Check for duplication.
      const saveObject = formHelper.getSaveObject(fields, 'edit', FormConfigurationTypeEnum.VEHICLE);
      if (isForced) {
        // Mark the vehicle for the new audit status.
        const success = await handleAuditMark(vehicleId, true, tenant);
        // Signal the FormGenerator that we want to save the vehicle.
        setShouldSave(success);
        return;
      }
      // A1BS31Z0430336179 4
      // YV1745883L2266024 1
      const completeObject = {
        ...saveObject,
        quotaSales: currentObject.quotaSales,
      };
      markDuplicate(completeObject)
        .then(async (vehicle: IVehicle) => {
          const hasDuplicates = !!vehicle.duplicateQuota?.duplicatesFound;
          if (!hasDuplicates) {
            // Update status field
            setFields(
              [...fields].map((field) => (field.key === 'status' ? { ...field, value: 'VEHICLE_AUDITED' } : field)),
            );
            // Mark the vehicle for the new audit status.
            const success = await handleAuditMark(vehicleId, true, tenant);
            // Signal the FormGenerator that we want to save the vehicle.
            setShouldSave(success);
          } else {
            setCurrentObject && setCurrentObject(vehicle);
          }
        })
        .catch((error: any) => console.error('Error while trying to fetch quota duplication:', error));
    },
  },
  {
    key: 'failed',
    label: 'Fehler - Email senden',
    active: true,
    conditions: [
      { key: 'errMsg', validator: required },
      { key: 'imageErrorStatus', validator: required },
      { key: 'status', validator: hasCorrectStatus },
    ],
    onClick: async (vehicleId: string, setShouldSave: Function, fields: IFormField[], setFields: Function) => {
      // Update status field
      setFields([...fields].map((field) => (field.key === 'status' ? { ...field, value: 'INCOMPLETE' } : field)));
      // Mark the vehicle for the new audit status.
      const success = await handleAuditMark(vehicleId, false, tenant);
      // Signal the FormGenerator that we want to save the vehicle.
      setShouldSave(success);
    },
  },
  {
    key: 'reupload',
    label: 'Bilder ausgetauscht',
    active: true,
    conditions: [
      { key: 'errMsg', validator: noValue },
      { key: 'imageErrorStatus', validator: noValue },
      { key: 'status', validator: hasIncompleteStatus },
    ],
    onClick: async (vehicleId: string, setShouldSave: Function, fields: IFormField[], setFields: Function) => {
      // Update status field
      setFields([...fields].map((field) => (field.key === 'status' ? { ...field, value: 'INCOMPLETE' } : field)));
      // Mark the vehicle for the new audit status.
      await updateVehicleStatus(vehicleId, 'reupload', tenant);
      // Signal the FormGenerator that we want to save the vehicle.
      setShouldSave(true);
    },
  },
];
