import { productOptionFilters, quotaStatusFilters, yearFilters } from './quotaSaleFilters';
import { FILTER_TYPE_ENUM, IFilterItem } from './types';
import { vrStatusFilters } from './vehicleFilters';

export * from './types';

export const ALL_FILTERS_MAP = [
  ...vrStatusFilters,
  ...quotaStatusFilters,
  ...productOptionFilters,
  ...yearFilters,
].reduce((acc, filter) => {
  const id = FILTER_TYPE_ENUM[filter.id];
  acc.set(id, filter);
  return acc;
}, new Map<string, IFilterItem>());
