import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { Button, SxProps } from '@mui/material';

import { IFilterItem } from '../../common/PredefinedFilters';
import { ContextActionEnum, ContextScopeEnum } from '../../context/GlobalState';

/**
 * It returns a button that opens a modal to request a quote for a vehicle
 * @param props - {
 * @returns A button that will open a modal to request a quote.
 */
const RequestQuotaAction = (props: {
  predefinedFilters: IFilterItem[];
  selectedIds: string[];
  modal?: ContextActionEnum;
  setModal: Function;
  context: ContextScopeEnum;
  sx?: SxProps;
}) => {
  const hasSelection = props?.selectedIds?.length;
  const hasVehicleContext = props.context === ContextScopeEnum.VEHICLES;
  const show = hasSelection && hasVehicleContext;

  if (show) {
    return (
      <Button
        color="inherit"
        onClick={() => props.setModal({ type: props.modal ?? ContextActionEnum.REQUEST_QUOTA })}
        sx={props.sx}
      >
        <RequestQuoteIcon />
        &nbsp;Request Quota
      </Button>
    );
  }
  return <></>;
};

export default RequestQuotaAction;
