export const hideBoringMuiXLicenseWarning = () => {
  const checkElement = () => {
    const muiXTextXPath = "//div[text()='MUI X: License key expired']";
    const muiXElement = document.evaluate(
      muiXTextXPath,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null,
    ).singleNodeValue;

    if (muiXElement && muiXElement instanceof HTMLElement) {
      muiXElement.style.display = 'none';
      return true;
    } else {
      return false;
    }
  };

  const intervalId = setInterval(() => {
    if (checkElement()) {
      clearInterval(intervalId); // stop checking once found
    }
  }, 500);
};
