import { Report } from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PaidIcon from '@mui/icons-material/Paid';
import PersonIcon from '@mui/icons-material/Person';
import PublishIcon from '@mui/icons-material/Publish';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SellIcon from '@mui/icons-material/Sell';

import { QuotaSaleProductOption } from '../cards/vehicle-overview/VehicleOverview';

import { FILTER_TYPE_ENUM, IFilterItem } from './types';

export const yearFilters: IFilterItem[] = [
  {
    name: 'Quota year filter',
    id: FILTER_TYPE_ENUM.DIVIDER,
    type: FILTER_TYPE_ENUM.DIVIDER,
  },
  {
    name: '2022',
    filter: { uba_year: 2022 },
    id: FILTER_TYPE_ENUM.UBA_2022,
    type: FILTER_TYPE_ENUM.UBA_2022,
    checked: false,
    active: true,
    icon: <CalendarMonthIcon />,
  },
  {
    name: '2023',
    filter: { uba_year: 2023 },
    id: FILTER_TYPE_ENUM.UBA_2023,
    type: FILTER_TYPE_ENUM.UBA_2023,
    checked: false,
    active: true,
    icon: <CalendarMonthIcon />,
  },
  {
    name: '2024',
    filter: { uba_year: 2024 },
    id: FILTER_TYPE_ENUM.UBA_2024,
    type: FILTER_TYPE_ENUM.UBA_2024,
    checked: false,
    active: true,
    icon: <CalendarMonthIcon />,
  },
];

export const productOptionFilters: IFilterItem[] = [
  {
    name: 'Product option filter',
    id: FILTER_TYPE_ENUM.DIVIDER,
    type: FILTER_TYPE_ENUM.DIVIDER,
  },
  {
    name: 'Instant',
    filter: { productOption: QuotaSaleProductOption.INSTANT },
    id: FILTER_TYPE_ENUM.PO_INSTANT,
    type: FILTER_TYPE_ENUM.PO_INSTANT,
    checked: false,
    active: true,
    icon: <CalendarMonthIcon />,
  },
  {
    name: 'Standard',
    filter: { productOption: QuotaSaleProductOption.STANDARD },
    id: FILTER_TYPE_ENUM.PO_STANDARD,
    type: FILTER_TYPE_ENUM.PO_STANDARD,
    checked: false,
    active: true,
    icon: <CalendarMonthIcon />,
  },
  {
    name: 'Bonus',
    filter: { productOption: QuotaSaleProductOption.BONUS },
    id: FILTER_TYPE_ENUM.PO_BONUS,
    type: FILTER_TYPE_ENUM.PO_BONUS,
    checked: false,
    active: true,
    icon: <CalendarMonthIcon />,
  },
  {
    name: 'Not selected',
    filter: { productOption: null },
    id: FILTER_TYPE_ENUM.PO_NOT_SELECTED,
    type: FILTER_TYPE_ENUM.PO_NOT_SELECTED,
    checked: false,
    active: true,
    icon: <CalendarMonthIcon />,
  },
];

export const quotaStatusFilters: IFilterItem[] = [
  {
    name: 'Quota status filters',
    id: FILTER_TYPE_ENUM.DIVIDER,
    type: FILTER_TYPE_ENUM.DIVIDER,
  },
  {
    name: 'User Prepared',
    filter: { status: 'USER_PREPARED' },
    id: FILTER_TYPE_ENUM.USER_PREPARED,
    type: FILTER_TYPE_ENUM.USER_PREPARED,
    checked: false,
    active: true,
    icon: <AccessTimeIcon />,
  },
  {
    name: 'User Requested',
    filter: { status: 'USER_REQUESTED' },
    id: FILTER_TYPE_ENUM.USER_REQUESTED,
    type: FILTER_TYPE_ENUM.USER_REQUESTED,
    checked: false,
    active: true,
    icon: <PersonIcon />,
  },
  {
    name: 'UBA Submitted',
    filter: { status: 'UBA_SUBMITTED' },
    id: FILTER_TYPE_ENUM.UBA_SUBMITTED,
    type: FILTER_TYPE_ENUM.UBA_SUBMITTED,
    checked: false,
    active: true,
    icon: <PublishIcon />,
  },
  {
    name: 'UBA Approved',
    filter: { status: 'UBA_APPROVED' },
    id: FILTER_TYPE_ENUM.UBA_APPROVED,
    type: FILTER_TYPE_ENUM.UBA_APPROVED,
    checked: false,
    active: true,
    icon: <CheckCircleIcon />,
  },
  {
    name: 'UBA Failed',
    filter: { status: 'UBA_FAILED' },
    id: FILTER_TYPE_ENUM.UBA_FAILED,
    type: FILTER_TYPE_ENUM.UBA_FAILED,
    checked: false,
    active: true,
    icon: <Report />,
  },
  {
    name: 'Sold',
    filter: { status: 'SOLD' },
    id: FILTER_TYPE_ENUM.SOLD,
    type: FILTER_TYPE_ENUM.SOLD,
    checked: false,
    active: true,
    icon: <SellIcon />,
  },
  {
    name: 'Invoice Generated',
    filter: { status: 'INVOICE_GENERATED' },
    id: FILTER_TYPE_ENUM.INVOICE_GENERATED,
    type: FILTER_TYPE_ENUM.INVOICE_GENERATED,
    checked: false,
    active: true,
    icon: <ReceiptIcon />,
  },
  {
    name: 'Invoice Sent',
    filter: { status: 'INVOICE_SENT' },
    id: FILTER_TYPE_ENUM.INVOICE_SENT,
    type: FILTER_TYPE_ENUM.INVOICE_SENT,
    checked: false,
    active: true,
    icon: <AttachEmailIcon />,
  },
  {
    name: 'Paid Out',
    filter: { status: 'PAID_OUT' },
    id: FILTER_TYPE_ENUM.PAID_OUT,
    type: FILTER_TYPE_ENUM.PAID_OUT,
    checked: false,
    active: true,
    icon: <PaidIcon />,
  },
  {
    name: 'Rejected',
    filter: { status: 'REJECTED' },
    id: FILTER_TYPE_ENUM.REJECTED,
    type: FILTER_TYPE_ENUM.REJECTED,
    checked: false,
    active: true,
    icon: <CancelIcon />,
  },
];
