import { useContext, useEffect, useState } from 'react';
import { Grid, LinearProgress, Stack } from '@mui/material';
import { DataGridPro, GridOverlay } from '@mui/x-data-grid-pro';

import { GlobalContext } from '../context/GlobalState';

import { Columns } from './Columns';

/**
 * Custom loading overlay to indicate to the user that
 * requested data is being loaded from the backend.
 */
function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

const TemplatesList = ({ templates }: { templates: any }) => {
  const { loading, pagination, setPagination, total, selectedIds, setSelectedIds } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rows, setRows] = useState<any[]>([]);

  /**
   * Prepare the vehicle registrations table.
   */
  useEffect(() => {
    setRows(templates && templates.length ? templates.map((v: any) => v) : []);
  }, [templates]);

  const getItemsRange = (currentPageNumber: number, currentPageSize: number): void => {
    const totalItemsCount = total;
    const numberOfItemsPerPage = currentPageSize;
    const page = currentPageNumber;

    // page: 0 => 10 start: 0 end: 9
    // page: 1 => 10 start: 11 end: 20
    const start = page * numberOfItemsPerPage;
    // start = start < 0 ? 0 : start;
    const end = Math.min(start + numberOfItemsPerPage - 1, totalItemsCount);
    /**
     * Set the changed pagination properties on the
     * global context.
     */
    setPagination({ range: [start, end], pageSize: currentPageSize });
    // Set the current page in the component state.
    setCurrentPage(page);
  };

  return (
    <Grid container>
      {/* <InputLabel>Tenants</InputLabel>
      {tenants && tenants.length && <Select
        value={tenants[0] || ''}
        onChange={(event: any) => handleTenantChange(event)}
        label={tenants[0]}
      >
        {field?.options.map((option: IFieldOption) =>
          <MenuItem key={`${option.name}_${option.value}`} value={option.value}>{option.name}</MenuItem>
        )}
      </Select>}
      {(!tenants || !tenants.length) && 
      <Typography component='div' sx={{ flex: 1, color: 'red', ml: 1 }}>
        Keine Tenant gefunden.
      </Typography>} */}

      <Grid
        item
        md={12}
      >
        <div style={{ height: pagination.pageSize > 10 ? '85vh' : 630 }}>
          <DataGridPro
            components={{
              LoadingOverlay: CustomLoadingOverlay,
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  No rows in DataGrid
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  There are no results to display
                </Stack>
              ),
            }}
            sx={{ backgroundColor: 'white' }}
            rows={rows}
            columns={Columns}
            loading={loading}
            paginationMode="server"
            page={currentPage}
            columnBuffer={Columns?.length + 1} // important for testing
            pageSize={pagination.pageSize}
            rowsPerPageOptions={[100, 500, 1000, 2000]}
            onPageChange={(page) => !loading && getItemsRange(page, pagination.pageSize)}
            onPageSizeChange={(newPageSize) => !loading && getItemsRange(currentPage, newPageSize)}
            pagination={true}
            rowCount={total}
            onSelectionModelChange={(newSelection: any) => setSelectedIds(newSelection)}
            selectionModel={selectedIds}
            checkboxSelection
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default TemplatesList;
