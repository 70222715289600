import { useState } from 'react';
import { DialogActions, DialogContent, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { useTenants } from '../../../../tenant';
import { userFormConfig } from '../../../common/configs/userFieldsConfig';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../common/dialog/BootstrapDialog';
import FormGenerator from '../../../common/form-generator/FormGenerator';
import { FormConfigurationTypeEnum, IActiveTab, IFormField } from '../../../common/form-generator/interfaces';

/**
 * Defines the properties required by the
 * current dialog component.
 */
interface IEditUserDialogProps {
  setModal: Function;
  user: any;
  onSubmit?: (userData: any) => void;
}

export default function EditUserDialog(props: IEditUserDialogProps) {
  const { currentTenant } = useTenants();
  // Holds the vehicle data.
  const [activeTab, setActiveTab] = useState<IActiveTab>({
    current: 1,
    total: userFormConfig(currentTenant).tabs.length,
  });
  // Indicates if the user wishes to save the data or not.
  const [shouldSave, setShouldSave] = useState<boolean>(false);
  // Indicates if the form has a validation error.
  const [isValid, setIsValid] = useState<boolean>(true);
  // Holds the list of fields.
  const [fields, setFields] = useState<IFormField[]>([]);

  const handleStepAdvance = () => {
    if (activeTab.current < userFormConfig(currentTenant).tabs.length) {
      setActiveTab({ current: activeTab.current + 1, total: userFormConfig(currentTenant).tabs.length });
    }
  };

  const handleSave = () => {
    setShouldSave(true);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={() => props.setModal(null)}
        aria-labelledby="customized-dialog-title"
        maxWidth="md"
        open={true}
      >
        {/* The title of the current dialog */}
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => props.setModal(null)}
        >
          {props.user ? 'Edit user' : 'Add new user'}
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{ height: 600 }}
        >
          <FormGenerator
            type={FormConfigurationTypeEnum.USER}
            currentObject={props.user}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            shouldSave={shouldSave}
            setShouldSave={setShouldSave}
            isValid={isValid}
            setIsValid={setIsValid}
            setFields={setFields}
            fields={fields}
            onSubmit={
              props.onSubmit &&
              ((type, response) => {
                type === FormConfigurationTypeEnum.USER && props.onSubmit?.(response);
              })
            }
          />
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Validation error message */}
          {!isValid && (
            <Typography
              component="div"
              sx={{ flex: 1, color: 'red', ml: 1 }}
            >
              Das Formular enthält Validierungsfehler
            </Typography>
          )}

          <div style={{ flex: 1, width: 50, display: 'flex', justifyContent: 'flex-end' }}>
            {/* The NEXT button */}
            {activeTab.current < activeTab.total && <Button onClick={handleStepAdvance}>Nächste</Button>}
            {/* The SAVE button */}
            <Button onClick={handleSave}>Speichern</Button>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
