import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';
import { IPagination } from '../../context/GlobalState';

/**
 * Make an API request using the provided url.
 */
export const getInvoicesPromise = async (pagination: IPagination, tenant: string): Promise<any> => {
  // Calculate the range.
  const range = [pagination.range[0], pagination.range[1]];
  // Add the required query params.
  const params = {
    range: JSON.stringify(range),
    tenant,
  };
  // Stringify the query params.
  const stringParams = `${queryString.stringify(params)}`;
  // Build up the complete url
  const url = `${API_URL}/v2/invoice/all?${stringParams}`;

  return fetcher(url);
};
