import PersonIcon from '@mui/icons-material/Person';
import { Button } from '@mui/material';

import { ContextActionEnum, ContextScopeEnum } from '../../context/GlobalState';

/**
 * Check if the marketable filter has been selected
 * and we have a few selections present.
 *
 * @param selectedIds string[]
 * @returns boolean
 */
const EditTemplateAction = (props: { selectedIds: string[]; setModal: Function; context: ContextScopeEnum }) => {
  const hasOneOrNoSelection = !props.selectedIds.length || (props.selectedIds && props.selectedIds.length === 1);
  const hasRequiredContext = props.context === ContextScopeEnum.TEMPLATES;
  const isSelection = props.selectedIds && props.selectedIds.length;
  const isEdit = isSelection;
  const show = hasOneOrNoSelection && hasRequiredContext;

  if (show) {
    return (
      <Button
        color="inherit"
        onClick={() => props.setModal({ type: ContextActionEnum.ADD_TEMPLATE })}
      >
        <PersonIcon />
        &nbsp;{isEdit ? 'Vorlage bearbeiten' : 'Neue Vorlage'}
      </Button>
    );
  }
  return <></>;
};

export default EditTemplateAction;
