import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * Preview if the vehicle can be deleted.
 */
export const deletePreviewVehicles = (body: { vehicles: string[] }, tenant: string | null): Promise<any> => {
  const subUrl = `vehicle-registrations/preview`;
  const url = `${API_URL}/${subUrl}`;
  const method = 'DELETE';
  /**
   * Send the request to the provided endpoint
   * and check if the response was successful
   * or not.
   */
  const query = { tenant };
  return fetcher(queryString.stringifyUrl({ url, query }), {
    method,
    body: JSON.stringify(body),
  });
};
