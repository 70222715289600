import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import RuleIcon from '@mui/icons-material/Rule';
import UploadIcon from '@mui/icons-material/Upload';

import { FILTER_TYPE_ENUM, IFilterItem } from './types';

export const vrStatusFilters: IFilterItem[] = [
  {
    id: FILTER_TYPE_ENUM.DIVIDER,
    name: 'Vehicle filters',
    type: FILTER_TYPE_ENUM.DIVIDER,
  },
  {
    id: FILTER_TYPE_ENUM.UPLOADED,
    name: 'Uploaded',
    filter: { status: 'UPLOADED' },
    checked: false,
    active: true,
    icon: <UploadIcon />,
  },
  {
    id: FILTER_TYPE_ENUM.AI_PARSED,
    name: 'AI Parsed',
    filter: { status: 'AI_PARSED' },
    checked: false,
    active: true,
    icon: <FilterAltIcon />,
  },
  {
    id: FILTER_TYPE_ENUM.INCOMPLETE,
    name: 'Incomplete',
    filter: { status: 'INCOMPLETE' },
    checked: false,
    active: true,
    icon: <RuleIcon />,
  },
  {
    id: FILTER_TYPE_ENUM.INCOMPLETE_RESUBMIT,
    name: 'Incomplete Resubmit',
    filter: { status: 'INCOMPLETE_RESUBMIT' },
    checked: false,
    active: true,
    icon: <RestartAltIcon />,
  },
  {
    id: FILTER_TYPE_ENUM.VEHICLE_AUDITED,
    name: 'Vehicle Audited',
    filter: { status: 'VEHICLE_AUDITED' },
    checked: false,
    active: true,
    icon: <CheckBoxIcon />,
  },
];
