import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * Approve or fail the vehicle audit.
 *
 * @param vehicleId string
 * @param approve boolean
 * @returns Promise<any>
 */
export const manuallyMarkVehicleAudit = (vehicleId: string, approve: boolean, tenant: string): Promise<any> => {
  const actionString = approve ? 'markApproved' : 'markFailed';

  const url = `${API_URL}/vehicle-registrations/${vehicleId}/${actionString}`;
  const query = { tenant };
  return fetcher(
    queryString.stringifyUrl({ url, query }),
    {
      method: 'PUT',
    },
    undefined,
    { skipReadOfBody: true },
  );
};
