import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';
import { ContextActionEnum } from '../../context/GlobalState';

/**
 * Save the provided template object.
 *
 * @param template any
 */
export const flagUploadPromise = (
  uploadId: string,
  flag: boolean,
  setTriggerAction: Function,
  setRefreshList: Function,
  tenant: string | null,
): Promise<any> => {
  /**
   * Decide if we are dealing with a new or
   * existing template. The request props changed
   * based on that.
   */
  const url = `${API_URL}/pdf-uploads/${uploadId}`;
  /**
   * Send the request to the provided endpoint
   * and check if the response was successful
   * or not.
   */
  return fetcher(queryString.stringifyUrl({ url, query: { tenant } }), {
    method: 'PUT',
    body: JSON.stringify({
      isProcessed: flag,
    }),
  }).then(() => {
    /**
     * Notify the user that the marking
     * was performed.
     */
    setTriggerAction({
      type: flag ? ContextActionEnum.UPLOAD_PROCESSED : ContextActionEnum.UPLOAD_NOT_PROCESSED,
      progress: 100,
    });
    // Refresh the list of uploads.
    setRefreshList(Math.floor(Math.random() * 10000));
  });
};
