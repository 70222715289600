import { createContext, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useLocation, useSearchParams } from 'react-router-dom';

import { DEFAULT_PAGE_SIZE } from '../../../constants/pagination';
import type { VehicleRegistrationListedResponse } from '../../../types/openapi';
import { useTenants } from '../../tenant';
import { ALL_FILTERS_MAP, FILTER_TYPE_ENUM, IFilterItem } from '../common/PredefinedFilters';
import { getInvoiceTemplatesPromise } from '../invoice-templates-admin/api/getInvoiceTemplatesPromise';
import { getInvoicesPromise } from '../invoicing-admin/api/getInvoices';
import { getUploadsPromise } from '../pdf-uploads/api/getUploads';
import { getQuotasPromise } from '../quotas-admin/api/getQuotas';
import { getTemplatesPromise, IEmailTemplate } from '../templates-admin/api/getTemplates';
import { sendAllEmails } from '../users-admin/api/sendEmail';
import { IChecksum } from '../vehicles-admin/api/getMarketable';
import { getVehiclesPromise } from '../vehicles-admin/api/getVehicles';

import { ResponseProcessorHelper } from './ResponseProcessorHelper';

export interface IModalType {
  type: ContextActionEnum;
  ids?: string[];
}

export interface IPagination {
  range: number[];
  pageSize: number;
}

/**
 * Enumerates the types of context scopes
 * that we can use.
 */
export enum ContextScopeEnum {
  USERS = 'Liste mit allen Benutzern',
  VEHICLES = 'Liste mit allen Fahrzeugen',
  QUOTAS = 'Liste mit allen Quotas',
  INVOICING = 'Rechnungen',
  TEMPLATES = 'E-Mail-Vorlagen',
  INVOICE_TEMPLATES = 'Rechnungen-Vorlagen',
  PDF_UPLOADS = 'Alle pdf-Datei-Uploads',
  USER_INFO = 'Benutzer-Informationen',
}

const pathToScopeMap: Record<string, ContextScopeEnum> = {
  '/users': ContextScopeEnum.USERS,
  '/user-info': ContextScopeEnum.USER_INFO,
  '/vehicles': ContextScopeEnum.VEHICLES,
  '/quotas': ContextScopeEnum.QUOTAS,
  '/invoicing': ContextScopeEnum.INVOICING,
  '/templates': ContextScopeEnum.TEMPLATES,
  '/invoice-templates': ContextScopeEnum.INVOICE_TEMPLATES,
  '/uploads': ContextScopeEnum.PDF_UPLOADS,
};

function getScopeFromPath(path: string): ContextScopeEnum {
  const scope = pathToScopeMap[path];
  if (!scope) throw new Error(`Unknown path: ${path}`);
  return scope;
}

/**
 * Enumerates the action types that can
 * be triggered as bulk for users and vehicles.
 */
export enum ContextActionEnum {
  BULK_EMAIL = 'Sending bulk emails',
  REQUEST_QUOTA = 'Requesting Quota Sales for Vehicles',
  REQUEST_QUOTA_FROM_VEHICLE_FORM = 'Requesting Quota Sales from Vehicle Form',
  SHOW_DIALOG_APPROVE_QUOTA_SALES = 'Approve Quota Sale wizard',
  SHOW_DIALOG_DECLINE_QUOTA_SALES = 'Decline Quota Sale wizard',
  SUBMIT_QUOTAS = 'Mark selected Quota Sales as Submitted',
  BULK_FETCH_LATEST_QUOTAS = 'Fetching Quota IDs',
  BULK_APPROVE_QUOTA_SALES = 'Approving Quota Sales for selected vehicles',
  BULK_DECLINE_QUOTA_SALES = 'Declining Quota Sales for selected vehicles',
  BULK_SELL_QUOTAS = 'Sell Approved Quotas',
  BULK_PAY_QUOTA_SALES = 'Paying Quota Sales for selected vehicles',
  ADD_VEHICLE = 'Add new vehicle',
  DELETE_VEHICLES = 'Delete vehicles',
  ADD_USER = 'Add new user',
  DELETE_USER = 'Delete user',
  GENERATE_INVOICE = 'Generate invoices',
  SEND_INVOICE = 'Sending invoices',
  ADD_TEMPLATE = 'Neue Vorlage hinzufügen',
  DELETE_TEMPLATE = 'Vorlage löschen',
  EDIT_INVOICE_TEMPLATE = 'Rechnungsvorlage bearbeiten',
  UPLOAD_PROCESSED = 'Upload als bearbeitet markiert',
  UPLOAD_NOT_PROCESSED = 'Upload als NICHT verarbeitet markiert',
  BANK_TRANSFER_EXPORTS = 'Download bank transfer exports',
  INVOICE_MARKED = 'Invoice marked',
  INVOICE_UNMARKED = 'Invoice unmarked',
  MARK_PAID_OUT = 'Mark invoices as paid out',
  CREATE_UBA_SUBMISSION = 'Create UBA submission',
  EDIT_QUOTA = 'Edit quota',
}

/**
 * Indicate the type and the progress of
 * the current bulk action being performed.
 */
export interface ITriggerAction {
  type: ContextActionEnum;
  progress: number;
}

/**
 * Defines the apps global state
 * signature.
 */
interface IGlobalState {
  currentScope: ContextScopeEnum;
  // The list of all tasks.
  vehicles: any[];
  quotas: any[];
  users: any[];
  analytics: any;
  setUsers: Function;
  setAnalytics: Function;
  invoices: any[];
  invoiceTemplates: any[];
  uploads: any[];
  predefinedFilters: IFilterItem[];
  setPredefinedFilters: Function;
  pagination: IPagination;
  setPagination: Function;
  sort: string[];
  setSort: Function;
  // Shows the number of total items.
  total: number;
  setTotal: Function;
  // The number of items that were selected.
  selectedIds: string[];
  setSelectedIds: Function;
  selectedUsernames: string[];
  setSelectedUsernames: Function;
  // The modal that should be opened.
  modal: IModalType | null;
  setModal: Function;
  // The loading indicator.
  loading: boolean;
  triggerAction: ITriggerAction | null;
  setTriggerAction: Function;
  /**
   * Actions made available to
   * other components.
   */
  sendBulkEmails: Function;
  // Holds the checksum information used by the /market endpoint.
  checksum: any;
  setChecksum: Function;
  // Holds the selected item (click on row).
  selectedItem: any;
  setSelectedItem: Function;
  // Instruct the refresh of the current list.
  refreshList: number | null;
  setRefreshList: Function;
  // Update the edited vehicle.
  updateVehicleInList: Function;
  getTemplates: Function;
  templates: IEmailTemplate[] | null;
}

const initialState: IGlobalState = {
  currentScope: ContextScopeEnum.USERS,
  vehicles: [],
  quotas: [],
  users: [],
  analytics: {},
  setUsers: () => {},
  setAnalytics: () => {},
  invoices: [],
  invoiceTemplates: [],
  uploads: [],
  predefinedFilters: [],
  setPredefinedFilters: () => {},
  pagination: {
    range: [],
    pageSize: 0,
  },
  setPagination: () => {},
  sort: [],
  setSort: () => {},
  total: 0,
  setTotal: () => {},
  selectedIds: [],
  setSelectedIds: () => {},
  selectedUsernames: [],
  setSelectedUsernames: () => {},
  modal: null,
  setModal: () => {},
  loading: false,
  triggerAction: null,
  setTriggerAction: () => {},
  sendBulkEmails: () => {},
  checksum: null,
  setChecksum: () => {},
  selectedItem: null,
  setSelectedItem: () => {},
  refreshList: null,
  setRefreshList: () => {},
  updateVehicleInList: () => {},
  getTemplates: () => {},
  templates: null,
};

export const GlobalContext = createContext(initialState);

type Props = {
  children: React.ReactNode;
};

export const GlobalProvider: FC<Props> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigationScope = getScopeFromPath(location.pathname);

  // Instantiate the response helper.
  const responseProcessorHelper = useMemo(() => new ResponseProcessorHelper(), []);
  /**
   * Set the current context scope (USERS or VEHICLES) so we know what
   * type of data to fetch.
   */
  const [currentScope, setCurrentScope] = useState<ContextScopeEnum>(navigationScope);
  // Store the list of vehicles fetched from remote.
  const [vehicles, setVehicles] = useState<VehicleRegistrationListedResponse>([]);
  // Store the list of vehicles fetched from remote.
  const [quotas, setQuotas] = useState<any[]>([]);
  // Store the list of invoices V2 fetched from remote.
  const [invoices, setInvoices] = useState<any[]>([]);
  // Stores the list of users fetched from remote.
  const [users, setUsers] = useState<any[]>([]);
  // Stores the list of analytics fetched from remote.
  const [analytics, setAnalytics] = useState<any>({});
  // Stores the list of pdf uploads fetched from remote.
  const [invoiceTemplates, setInvoiceTemplates] = useState<any[]>([]);
  // Stores the list of pdf uploads fetched from remote.
  const [uploads, setUploads] = useState<any[]>([]);
  // Indicates the currently set sorting.
  const [sort, setSort] = useState<string[]>(['id', 'ASC']);
  // Indicates the total number of items on remote.
  const [total, setTotal] = useState<number>(0);
  // Indicates how many items were selected.
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  // Indicates how many items were selected.
  const [selectedUsernames, setSelectedUsernames] = useState<string[]>([]);
  // Indicates the type of bulk action that should be triggered.
  const [triggerAction, setTriggerAction] = useState<ITriggerAction | null>(null);
  const [templates, setTemplates] = useState<IEmailTemplate[]>([]);
  /**
   * Holds the checksum info returned by the /marketable endpoint
   * and required by the /marketable/market endpoint.
   * After the market action, this state should be emptied.
   */
  const [checksum, setChecksum] = useState<IChecksum | null>(null);
  /**
   * Set the state of the loading property.
   * We use this to show loading indicators throughout
   * the UI.
   */
  const [loading, setLoading] = useState<boolean>(false);
  /**
   * Used to set the modal that should
   * be opened from anywhere inside the
   * application.
   */
  const [modal, setModal] = useState<IModalType | null>(null);
  // Holds the currently selected item (click on the row).
  const [selectedItem, setSelectedItem] = useState<any>(null);
  // Indicates if the currently loaded list should be refreshed.
  const [refreshList, setRefreshList] = useState<number>(0);

  const { currentTenant } = useTenants();

  const pagination = useMemo(() => {
    const range = searchParams.get('range')?.split(',').map(Number) ?? [0, DEFAULT_PAGE_SIZE - 1];
    const pageSize = Number(searchParams.get('pageSize')) || DEFAULT_PAGE_SIZE;
    return { range, pageSize };
  }, [searchParams]);

  const setPagination = useCallback(
    (pagination: IPagination) => {
      setSearchParams((searchParams) => {
        searchParams.delete('range');
        searchParams.append('range', pagination.range.join(','));
        searchParams.set('pageSize', pagination.pageSize.toString());
        return searchParams;
      });
    },
    [setSearchParams],
  );

  const predefinedFilters = useMemo(() => {
    const filterTypes = searchParams.getAll('filter');
    const predefinedFiltersFromQuery = filterTypes
      .map((filter) => ALL_FILTERS_MAP.get(filter))
      .filter((filter): filter is IFilterItem => !!filter);
    return predefinedFiltersFromQuery.map((filter) => ({ ...filter, checked: true }));
  }, [searchParams]);

  const setPredefinedFilters = useCallback(
    (filters: IFilterItem[]) => {
      const filterTypes = filters.map((f) => f.id).map((id) => FILTER_TYPE_ENUM[id]);
      setSearchParams((searchParams) => {
        searchParams.delete('filter');
        filterTypes.forEach((filter) => searchParams.append('filter', filter));

        return searchParams;
      });
    },
    [setSearchParams],
  );

  /**
   * Fetch the list of vehicles using the
   * available params.
   */
  const getVehicles = (currentTenant: string) => {
    // Fetch the list of vehicles.
    getVehiclesPromise(currentTenant, predefinedFilters[0], sort, pagination)
      .then((response) => {
        setVehicles([...response.data!]);
        responseProcessorHelper.process(response, setChecksum, setTotal);
      })
      .catch((error) => console.error('Error getting list of vehicles:', error))
      .finally(() => setLoading(false));

    // Signal that there is data loading.
    setLoading(true);
  };

  /**
   * Fetch the list of quotas using the
   * available params.
   */
  const getQuotas = (currentTenant: string) => {
    // Fetch the list of quotas.
    getQuotasPromise(predefinedFilters, sort, pagination, currentTenant)
      // Extract the checksum and totalCount properties.
      .then((response) => responseProcessorHelper.processResponse(response, setChecksum, setTotal))
      // Set the list of quotas globally.
      .then((response) => {
        const restructureQuotas = response?.map((qs: any) => ({
          ...qs,
          firstName: qs?.vehicle?.firstName,
          lastName: qs?.vehicle?.lastName,
          licensePlate: qs?.vehicle?.licensePlate,
          carId: qs?.vehicle?.carId,
        }));
        setQuotas(restructureQuotas);
      })
      .catch((error: any) => console.error('Error getting list of quotas:', error))
      .finally(() => setLoading(false));

    // Signal that there is data loading.
    setLoading(true);
  };

  /**
   * Fetch the list of invoices using the
   * available params.
   */
  const getInvoices = (currentTenant: string) => {
    // Signal that there is data loading.
    setLoading(true);
    // Fetch the list of invoices.
    getInvoicesPromise(pagination, currentTenant)
      // Extract the checksum property from the response data.
      .then((response: any) => responseProcessorHelper.processResponse(response, setChecksum, setTotal))
      .then((response) => {
        setInvoices(
          response?.map((obj: any) => ({
            id: obj.id,
            invoiced: obj.invoiced,
            ts: obj.ts,
            issued: obj.issued,
            ...obj.invoice,
            tenant: obj.tenant ?? obj.invoice.tenant ?? '',
          })),
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  /**
   * Fetch the list of invoice templates using the
   * available params.
   */
  const getInvoiceTemplates = () => {
    // Signal that there is data loading.
    setLoading(true);
    // Fetch the list of vehicles.
    getInvoiceTemplatesPromise(currentTenant)
      // // Extract the checksum and totalCount properties.
      // .then(response =>{
      //   responseProcessorHelper.processResponse(response, setChecksum, setTotal)
      // })
      .then((response) => setInvoiceTemplates(response?.data?.map((doc: any) => ({ ...doc, id: doc.name }))))
      .catch((error: any) => console.error('Error getting list of users:', error))
      .finally(() => setLoading(false));
  };

  const getTemplates = (tenant: string, _callback: Function) => {
    getTemplatesPromise(tenant)
      .then((result) => {
        // Add the missing row ids.
        const withIds = responseProcessorHelper.addIDs(result.data);
        setTemplates(withIds);
      })
      .catch((error) => console.error(error));
  };

  /**
   * Fetch the list of templates using
   * the available params.
   */
  const getPdfUploads = (currentTenant: string) => {
    // Signal that there is data loading.
    setLoading(true);
    // Fetch the list of vehicles.
    getUploadsPromise(sort, pagination, predefinedFilters, currentTenant)
      .then((response) => responseProcessorHelper.processResponse(response, setChecksum, setTotal))
      .then((response) => setUploads([...response]))
      .catch((error: any) => console.error('Error getting list of pdf uploads:', error))
      .finally(() => setLoading(false));
  };

  /**
   * Update the provided vehicle object in
   * the existing list of vehicles.
   */
  const updateVehicleInList = (vehicle: any) => {
    // Indicate to the user that an operation is ongoing.
    setLoading(true);
    // Create a new list of vehicles.
    let newVehiclesList = [...vehicles];
    /**
     * TODO: find the vehicle we need to update.
     */
    const foundVehicle = newVehiclesList.find((v) => v.uuid === vehicle.uuid);
    /**
     * TODO: update the vehicle in a new list copy.
     */
    const newVehicle = { ...foundVehicle, ...vehicle };
    // Replace the new vehicle object in the list.
    newVehiclesList = newVehiclesList.map((v) => (v.uuid === newVehicle.uuid ? newVehicle : v));
    /**
     * TODO: refresh the list of vehicles.
     */
    // Fetch the list of vehicles.
    setVehicles(newVehiclesList);
    setLoading(false);
  };

  /**
   * Monitor route changes so we can perform
   * pagination and predefinedFilters reset.
   * savedLocation needed to prevent extra re-renders due to
   * the useSearchParams hook: https://github.com/remix-run/react-router/issues/9991
   */
  const [savedLocation, setSavedLocation] = useState(location.pathname);
  useEffect(() => {
    if (location.pathname === savedLocation) return;
    /**
     * Reset some properties since we switch scope.
     * These changes will trigger the reload of the
     * required lists automatically since we listen
     * to changes on sort, predefinedFilters, pagination.
     */
    setPagination({ range: [0, DEFAULT_PAGE_SIZE - 1], pageSize: DEFAULT_PAGE_SIZE });
    setPredefinedFilters([]);
    setCurrentScope(getScopeFromPath(location.pathname));
    setSavedLocation(location.pathname);
  }, [location.pathname, savedLocation, setPredefinedFilters]);

  /**
   * Fetch the list of tasks from the
   * remote MongoDB - Atlas database.
   */
  useEffect(() => {
    if (loading) return;
    /**
     * Decide which type of data do we need
     * to fetch based on the set global scope
     * (currentScope).
     */
    switch (currentScope) {
      // case ContextScopeEnum.USERS:
      //   getUsers(tenant);
      //   break;
      case ContextScopeEnum.VEHICLES:
        getVehicles(currentTenant);
        break;
      case ContextScopeEnum.QUOTAS:
        getQuotas(currentTenant);
        break;
      case ContextScopeEnum.PDF_UPLOADS:
        getPdfUploads(currentTenant);
        break;
      case ContextScopeEnum.INVOICING:
        getInvoices(currentTenant);
        break;
      case ContextScopeEnum.INVOICE_TEMPLATES:
        getInvoiceTemplates();
        break;
      case ContextScopeEnum.TEMPLATES:
        getTemplates(currentTenant, setTemplates);
        break;
      default:
        break;
    }
  }, [sort, predefinedFilters, pagination, refreshList, currentTenant]);

  /**
   * Send out emails to all the provided userIds using
   * the provided template name.
   *
   * @param ids number[]
   * @param template string
   */
  const sendBulkEmails = async (usernames: string[], template: string) => {
    /**
     * Build the IIFE so we execute all generated
     * promises and update the general progress of
     * the current triggerAction.
     */
    sendAllEmails(usernames, template, currentTenant);
    toast.success('Bulk Email Triggered', { duration: 5000 });
  };

  return (
    <GlobalContext.Provider
      value={{
        currentScope,
        vehicles,
        quotas,
        users,
        analytics,
        setAnalytics,
        setUsers,
        invoices,
        invoiceTemplates,
        uploads,
        predefinedFilters,
        setPredefinedFilters,
        pagination,
        setPagination,
        sort,
        setSort,
        total,
        setTotal,
        selectedIds,
        setSelectedIds,
        selectedUsernames,
        setSelectedUsernames,
        modal,
        setModal,
        loading,
        triggerAction,
        setTriggerAction,
        sendBulkEmails,
        checksum,
        setChecksum,
        selectedItem,
        setSelectedItem,
        refreshList,
        setRefreshList,
        updateVehicleInList,
        getTemplates,
        templates,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
