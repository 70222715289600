import { formatDate, formatDateTime } from '../../../utils/date';
import { IVehicle } from '../vehicles-admin/api/dto';

import VehicleIdsColumn from './custom-columns/VehicleIdsColumn/VehicleIdsColumn';
import BooleanIcon from './list-formatters/BooleanIcon';

export const Columns = [
  {
    field: 'tenant',
    headerName: 'Tenant',
    width: 100,
  },
  { field: 'username', headerName: 'Username', width: 300 },
  {
    field: 'vehicleRegistrations',
    headerName: 'Vehicles',
    width: 220,
    /* Use valueGetter when no vehicles (so filtering works) & renderCell for vehicle buttons */
    valueGetter: (params: any) => (params?.row?.vehicleRegistrations?.length ? undefined : 'No vehicles'),
    renderCell: (params: any) =>
      params?.row?.vehicleRegistrations?.length ? (
        <VehicleIdsColumn
          vehicleIds={
            new Map<number, string>(params?.row?.vehicleRegistrations?.map((vr: IVehicle) => [vr.id, vr.uuid]))
          }
        />
      ) : undefined,
  },
  {
    field: 'debtorNumber',
    headerName: 'Debtor',
    width: 100,
  },
  {
    field: 'isCompany',
    headerName: 'B2B',
    renderCell: (params: any) => BooleanIcon({ success: params.row.isCompany }),
    width: 40,
  },
  {
    field: 'updatedAt',
    headerName: 'Updated',
    width: 160,
    valueGetter: (params: any) => formatDateTime(params.row.updatedAt),
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    width: 120,
    valueGetter: (params: any) => formatDate(params.row.createdAt),
  },
  {
    field: 'firstName',
    headerName: 'Firstname',
    width: 120,
  },
  {
    field: 'lastName',
    headerName: 'Lastname',
    width: 120,
  },
  {
    field: 'gender',
    headerName: 'Geschlecht',
    width: 120,
  },
  {
    field: 'company',
    headerName: 'Company',
    width: 100,
    valueGetter: (params: any) => (!params.row.company ? 'none' : params.row.company),
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 200,
  },
  {
    field: 'emailVerified',
    headerName: 'Email Verified',
    renderCell: (params: any) => BooleanIcon({ success: params.row.emailVerified }),
    width: 120,
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    width: 120,
  },
  {
    field: 'status',
    headerName: 'Cognito Status',
    width: 120,
  },
  {
    field: 'enabled',
    headerName: 'Enabled',
    renderCell: (params: any) => BooleanIcon({ success: params.row.enabled }),
    width: 80,
  },
  // {
  //   field: '',
  //   headerName: 'Fahrzeuge',
  //   width: 50,
  // },
  // {
  //   field: '',
  //   headerName: 'Rechnungen',
  //   width: 120,
  // },
  {
    field: 'street',
    headerName: 'Strasse',
    width: 110,
  },
  {
    field: 'addressAddOn',
    headerName: 'Adresszusatz',
    width: 120,
  },
  {
    field: 'houseNumber',
    headerName: 'Hausnummer',
    width: 120,
  },
  {
    field: 'zip',
    headerName: 'PLZ',
    width: 70,
  },
  {
    field: 'city',
    headerName: 'Stadt',
    width: 110,
  },
  {
    field: 'country',
    headerName: 'Land',
    width: 110,
  },
  {
    field: 'branch',
    headerName: 'Branch',
    width: 110,
  },
  {
    field: 'accountOwner',
    headerName: 'Kontoinhaber',
    width: 110,
  },
  {
    field: 'iban',
    headerName: 'IBAN',
    width: 110,
  },
  {
    field: 'internalNote',
    headerName: 'Internal Note',
    width: 110,
  },
  {
    field: 'vatReclaimEntitled',
    headerName: 'Vorsteuerabzugsberechtigt',
    renderCell: (params: any) => BooleanIcon({ success: params.row.vatReclaimEntitled }),
    width: 110,
  },
  {
    field: 'taxNumber',
    headerName: 'Steuernummer',
    width: 110,
  },
  {
    field: 'vatId',
    headerName: 'USt-ID',
    width: 110,
  },
  {
    field: 'uniqueId',
    headerName: 'Unique ID',
    width: 110,
  },
  {
    field: 'regFlowComplete',
    headerName: 'Registration Flow',
    renderCell: (params: any) => BooleanIcon({ success: params.row.regFlowComplete }),
    width: 150,
  },
  {
    field: 'acceptedTAndC',
    headerName: 'T&C',
    renderCell: (params: any) => BooleanIcon({ success: params.row.acceptedTAndC }),
    width: 70,
  },
  {
    field: 'acceptedTAndC2023',
    headerName: 'T&C 2023',
    renderCell: (params: any) => BooleanIcon({ success: params.row.acceptedTAndC2023 }),
    width: 120,
  },

  {
    field: 'marketingOptIn',
    headerName: 'Marketing',
    renderCell: (params: any) => BooleanIcon({ success: params.row.marketingOptIn }),
    width: 80,
  },
  {
    field: 'remarketing2023',
    headerName: 'Remarketing2023',
    renderCell: (params: any) => BooleanIcon({ success: params.row.marketingOptIn }),
    width: 80,
  },
  {
    field: 'priceUpdateOptIn',
    headerName: 'Preisupdate',
    renderCell: (params: any) => BooleanIcon({ success: params.row.priceUpdateOptIn }),
    width: 80,
  },
  {
    field: 'membershipCode',
    headerName: 'Mitgliedsnummer',
    width: 100,
  },
  {
    field: 'discountOption',
    headerName: 'Mitgleidervorteil',
    valueGetter: (params: any) =>
      params.row.discountOption === 0 ? 'Gutschrift' : params.row.discountOption === 1 ? 'Auszahlung' : null,
    width: 80,
  },
  {
    field: 'raffleCheckbox',
    headerName: 'Gewinnspiel',
    renderCell: (params: any) => BooleanIcon({ success: params.row.raffleCheckbox }),
    width: 120,
  },
  {
    field: 'maxVehicleCount',
    headerName: 'Max Vehicles',
    renderCell: (params: any) => params.row.maxVehicleCount || 'Tenant based',
    width: 120,
  },
  {
    field: 'isNoRightOfRevocation',
    headerName: 'No Right Of Revocation',
    renderCell: (params: any) => BooleanIcon({ success: params.row.isNoRightOfRevocation }),
    width: 250,
  },
  {
    field: 'utmSource',
    headerName: 'UTM Source',
    width: 120,
    valueGetter: (params: any) => params.row.trackingParameters?.utmSource ?? '',
  },
  {
    field: 'utmMedium',
    headerName: 'UTM Medium',
    width: 120,
    valueGetter: (params: any) => params.row.trackingParameters?.utmMedium ?? '',
  },
  {
    field: 'utmCampaign',
    headerName: 'UTM Campaign',
    width: 120,
    valueGetter: (params: any) => params.row.trackingParameters?.utmCampaign ?? '',
  },
];
