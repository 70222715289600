import { Fragment, useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { CircularProgress, DialogActions, DialogContent, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { useLazyGetTenantConfigQuery } from '../../../../../services/tenantConfigurator';
import { useTenants } from '../../../../tenant';
import { vehicleFormConfig } from '../../../common/configs/vehicleFieldsConfig';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../common/dialog/BootstrapDialog';
import FormGenerator from '../../../common/form-generator/FormGenerator';
import { FormHelper } from '../../../common/form-generator/formHelper';
import {
  FormConfigurationTypeEnum,
  IActiveTab,
  IButtonState,
  IFormField,
} from '../../../common/form-generator/interfaces';
import { IVehicle } from '../../api/dto';
import { getVehicleById } from '../../api/getVehicleById';

import { errorMessageOptions } from './errorMessage';
import { markDuplicate } from './useDuplicateCheck';
import { vehicleDialogButtonsStates } from './vehicleDialogButtonsStates';

/**
 * Defines the properties required by the
 * current dialog component.
 */
interface IEditVehicleDialogProps {
  setModal: Function;
  vehicle: IVehicle;
  setSelectedIds: Function;
  nextVehicleId: string | null;
  onSubmit?: Function;
}

export default function EditVehicleDialog(props: IEditVehicleDialogProps) {
  const [_searchParams, setSearchParams] = useSearchParams();
  const { currentTenant } = useTenants();
  // Holds the vehicle data.
  const [activeTab, setActiveTab] = useState<IActiveTab>({ current: 1, total: vehicleFormConfig.tabs.length });
  // Indicates if the user wishes to save the data or not.
  const [shouldSave, setShouldSave] = useState<boolean>(false);
  // Indicates if the modal should go to next vehicle on save.
  const [shouldGoNext, setShouldGoNext] = useState<boolean>(false);
  // Indicates if the form has a validation error.
  const [isValid, setIsValid] = useState<boolean>(true);
  /**
   * Indicates which action buttons should be active
   * depending on the field states set inside the form
   * generator component.
   */
  const [activeButtons, setActiveButtons] = useState<IButtonState[]>(vehicleDialogButtonsStates(currentTenant));
  // Holds the list of fields.
  const [fields, setFields] = useState<IFormField[]>([]);
  // Loading vehicle.
  const [loading, setLoading] = useState<boolean>(false);
  // Holds current vehicle.
  const [currentObject, setCurrentObject] = useState<IVehicle | null>(null);
  // Indicates if the current vehicle has duplicates.
  const [hasDuplicateQuotas, setHasDuplicateQuotas] = useState<boolean>(false);

  // Helps transform form elements according to needs.
  const formHelper: FormHelper = new FormHelper(FormConfigurationTypeEnum.VEHICLE);

  const [getTenantConfig] = useLazyGetTenantConfigQuery();

  const [initialFields, setInitialFieldsValue] = useState<Record<string, Partial<IFormField>> | undefined>(undefined);

  const handleStepAdvance = () => {
    if (activeTab.current < 3) {
      setActiveTab({ current: activeTab.current + 1, total: vehicleFormConfig.tabs.length });
    }
  };

  const handleSave = (goNext: boolean) => {
    setShouldGoNext(goNext);

    // YV1745883L2266024 1
    /**
     * Before saving, we need to call the duplicate check
     * endpoint and present the DuplicateQuotaWarning when
     * a duplication is detected. Also the save button should
     * disable than.
     */
    /**
     * Get the save object so we know if there
     * is a VIN provided or not.
     */
    const mode = currentObject ? 'edit' : 'create';
    const saveObject = formHelper.getSaveObject(fields, mode, FormConfigurationTypeEnum.VEHICLE);
    const completeObject = {
      ...saveObject,
      quotaSales: currentObject?.quotaSales ?? [],
    };
    markDuplicate(completeObject)
      .then((vehicle: IVehicle) => {
        // const hasDuplicates = !!vehicle.duplicateQuota?.duplicatesFound;
        if (!vehicle.duplicateQuota?.duplicatesFound) {
          setShouldSave(true);
        } else {
          setCurrentObject(vehicle);
        }
      })
      .catch((error: any) => console.error('Error while trying to fetch quota duplication:', error));
  };

  /**
   * Check if the provided vehicle object has
   * duplicate quotas or not.
   */
  const checkIfDuplicateQuotas = (vehicle: IVehicle | null): boolean => {
    const markedAsDuplicate = !!vehicle?.duplicateQuota?.duplicatesFound;
    const hasListOfQuotas = (vehicle?.duplicateQuota?.duplicates?.length ?? 0) > 0;
    return markedAsDuplicate && hasListOfQuotas;
  };

  const getVehicle = useCallback(async () => {
    try {
      setLoading(true);
      // set related query params
      setSearchParams((searchParams) => {
        searchParams.set('editVehicleUuid', props.vehicle.uuid);
        searchParams.set('editVehicleId', props.vehicle.id.toString());
        return searchParams;
      });

      const data = await getVehicleById(props.vehicle?.uuid, currentTenant);
      let frontImageFieldType: string | null = null;
      let backImageFieldType: string | null = null;

      if (data?.error) {
        toast.error(data?.error);
      } else {
        try {
          const [frontType, backType] = await Promise.all([
            getResourceContentType(data?.frontImage),
            getResourceContentType(data?.backImage),
          ]);
          frontImageFieldType = frontType;
          backImageFieldType = backType;
        } catch (error) {
          console.error(error);
        }
      }

      const restructuredVehicle = {
        ...data,
        userFirstName: data?.owner?.firstName,
        userLastName: data?.owner?.lastName,
        userCompany: data?.owner?.company,
        frontImageFieldType,
        backImageFieldType,
      };

      try {
        const initialValue = await handleInitialFieldsValue(restructuredVehicle);
        if (initialValue) {
          setInitialFieldsValue(initialValue);
        }
      } catch (err) {
        console.error(`Failed to set initial fields value`, err);
      }

      setCurrentObject(restructuredVehicle);
    } catch (err) {
      toast.error('Error encountered while loading vehicle');
    } finally {
      setLoading(false);
    }
  }, [props.vehicle]);

  useEffect(() => {
    // nothing to do if the vehicle is loading or null
    if (!props.vehicle || loading) {
      return;
    }

    // prevent another loading if we have already the data
    if (currentObject?.uuid === props.vehicle.uuid) {
      return;
    }

    // load the vehicle data
    getVehicle();
  }, [props.vehicle]);

  /**
   * Monitors the state of the saved currentObject
   * and each time it changes it will check if there
   * are duplicate quotas present.
   */
  useEffect(() => {
    if (!currentObject) {
      setHasDuplicateQuotas(false);
    }
    setHasDuplicateQuotas(checkIfDuplicateQuotas(currentObject));
    setActiveButtons(vehicleDialogButtonsStates(currentTenant));
  }, [currentObject, currentTenant]);

  useEffect(() => setShouldGoNext(false), [props.nextVehicleId]);

  const handleClose = () => {
    // remove related query params
    setSearchParams((searchParams) => {
      searchParams.delete('editVehicleId');
      searchParams.delete('editVehicleUuid');

      return searchParams;
    });

    props.setModal(null);
  };

  const noErrorsLabel = 'No errors';
  const handleInitialFieldsValue = async (vehicle: IVehicle | null) => {
    if (!vehicle) {
      return undefined;
    }
    const config = await getTenantConfig({ tenant: currentTenant });
    const options = errorMessageOptions[config?.data?.country ?? vehicle.owner?.country ?? 'DE'];
    if (!options) {
      return undefined;
    }
    options.sort();

    const opt = options.find((opt) => opt === vehicle.errMsg);

    let initialValue = '';
    // this is for the case the field was already filled with a value before when the field was free text & not a selectable list
    const additionalOptions = [];
    if (vehicle.errMsg && !opt) {
      initialValue = vehicle.errMsg;
      additionalOptions.push({ name: initialValue, value: initialValue });
    } else {
      initialValue = opt ?? noErrorsLabel;
    }
    return {
      errMsg: {
        options: [
          { name: noErrorsLabel, value: noErrorsLabel },
          ...additionalOptions,
          ...options.map((option) => ({ value: option, name: option })),
        ],
        value: initialValue,
      },
    } as Record<string, Partial<IFormField>>;
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="md"
        open={true}
        sx={{
          '& .MuiDialog-paper': {
            margin: 0,
            minHeight: loading ? 'auto' : '98vh',
          },
        }}
      >
        {loading && <CircularProgress />}
        {/* The title of the current dialog */}
        {!loading && (
          <>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              {props.vehicle ? 'Edit vehicle' : 'Add new vehicle'}
            </BootstrapDialogTitle>
            <DialogContent
              dividers
              sx={{ height: 600 }}
            >
              <FormGenerator
                type={FormConfigurationTypeEnum.VEHICLE}
                currentObject={currentObject}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                shouldSave={shouldSave}
                setShouldSave={setShouldSave}
                isValid={isValid}
                setIsValid={setIsValid}
                buttonStates={activeButtons}
                setButtonStates={setActiveButtons}
                setFields={setFields}
                fields={fields}
                nextSelectedId={shouldGoNext ? props.nextVehicleId : null}
                initialFieldsValue={initialFields}
                onSubmit={(_type, response) => props.onSubmit && props.onSubmit(response)}
              />
            </DialogContent>

            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* Validation error message */}
              {!isValid && (
                <Typography
                  component="div"
                  sx={{ flex: 1, color: 'red', ml: 1 }}
                >
                  Das Formular enthält Validierungsfehler
                </Typography>
              )}

              {/* No buttons on the last tab (overview)  */}
              {activeTab.current < activeTab.total && (
                <>
                  {' '}
                  <div
                    style={{ flex: 1, width: 50, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                  >
                    {activeTab.current <= 2 && currentObject?.duplicateQuota?.duplicatesFound !== true && (
                      <Fragment>
                        {!!props.vehicle && activeButtons && activeButtons.length && (
                          <>
                            {/* The configured action buttons */}
                            {activeButtons.map((buttonState) => (
                              <Button
                                key={buttonState.key}
                                disabled={!buttonState.active}
                                onClick={() => {
                                  if (buttonState.key === 'approved') {
                                    setShouldGoNext(true);
                                  }
                                  buttonState.onClick(
                                    props.vehicle?.uuid,
                                    setShouldSave,
                                    fields,
                                    setFields,
                                    currentObject,
                                    setCurrentObject,
                                    false,
                                  );
                                }}
                              >
                                {buttonState.label}
                              </Button>
                            ))}
                          </>
                        )}
                      </Fragment>
                    )}
                    {hasDuplicateQuotas && (
                      <Typography
                        sx={{
                          color: 'red',
                          width: '100%',
                          ml: 5,
                        }}
                      >
                        Aktionen deaktiviert. Überprüfen Sie die Registerkarte „Fahrzeughalter“.
                      </Typography>
                    )}
                    {/* The NEXT button */}
                    {activeTab.current < 2 && <Button onClick={handleStepAdvance}>Nächste</Button>}
                    {/* The SAVE button */}
                    {activeTab.current > 1 && (
                      <Button
                        onClick={() => handleSave(false)}
                        disabled={hasDuplicateQuotas}
                      >
                        Speichern
                      </Button>
                    )}
                    {/* The SAVE & GO TO NEXT VEHICLE button */}
                    {props.nextVehicleId && activeTab.current === 2 && !hasDuplicateQuotas && (
                      <Button onClick={() => handleSave(true)}>Speichern - zum nächsten Fahrzeug</Button>
                    )}
                  </div>
                </>
              )}
            </DialogActions>
          </>
        )}
      </BootstrapDialog>
    </div>
  );
}

async function getResourceContentType(url: string): Promise<string | null> {
  if (!url) {
    return null;
  }
  const response = await fetch(url);
  const contentType = response?.headers?.get('content-type');
  return contentType ?? null;
}
