export const errorMessageOptions: Record<string, string[]> = {
  AT: [
    'Geprüft - Rückseite fehlt',
    'Geprüft - Vorderseite fehlt',
    'Geprüft - Rückseite fehlt (Tabellarische Angaben zum Fahrzeug)',
    'Geprüft - Vorderseite fehlt (Stempel und Unterschrift)',
    'Geprüft - Falsches Dokument: Bitte Zulassungsbescheinigung Teil 1 hochladen',
    'Abgelehnt - Kein rein elektrisch betriebenes Elektrofahrzeug',
    'Geprüft - Zulassungsbescheinigung nichtlesbar',
    'Geprüft - Bild nicht zu öffnen (Bitte jpg, png- oder PDF-Bildformat hochladen)',
    'Abgelehnt - Keine österreichische Zulassungsbescheinigung',
    'Abgelehnt - Fahrzeughalter entspricht nicht dem Antragsteller',
    'Abgelehnt - Fahrzeugklasse ist nicht antragsberechtigt',
  ],
  DE: [
    'Geprüft - Vorderseite fehlt',
    'Abgelehnt - Kein rein elektrisch Elektrofahrzeug',
    'Geprüft - Bild nicht lesbar',
    'Geprüft - Fahrzeugschein nicht vollständig lesbar',
    'Geprüft - Fahrzeugschein zusammengeklappt',
    'Geprüft - Bild nicht zu öffnen (Bitte jpg-, png- oder PDF-Bildformat hochladen)',
    'Abgelehnt - Keine deutsche Zulassungsbescheinigung',
    'Geprüft - Falsches Dokument: Bitte Zulassungsbescheinigung Teil 1 hochladen',
    'Abgelehnt - Fahrzeugklasse ist nicht antragsberechtigt',
  ],
};
