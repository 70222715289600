import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';
import { IFilterItem } from '../../common/PredefinedFilters';
import { IPagination } from '../../context/GlobalState';

/**
 * Make an API request using the provided url.
 */
export const getQuotasPromise = (
  predefinedFilters: IFilterItem[],
  sort: string[],
  pagination: IPagination,
  tenant: string,
): Promise<any> => {
  // Calculate the range.
  const range = [pagination.range[0], pagination.range[1]];
  // get the filters
  const filters = predefinedFilters?.length ? predefinedFilters?.map((item) => item.filter) : [];
  // Add the required query params.
  const params = {
    filter: JSON.stringify(filters?.reduce((curr, acc) => ({ ...acc, ...curr }), {}) || {}),
    range: JSON.stringify(range),
    sort: JSON.stringify(sort),
    tenant,
  };
  // Stringify the query params.
  const stringParams = `${queryString.stringify(params)}`;
  // Build up the complete url
  const url = `${API_URL}/quota-sales?${stringParams}`;

  return fetcher(url)
    .then((data) => data)
    .catch((error) => {
      return error;
    });
  // Handle errors related to wrong status (401...).
  // error && handleStatusError(error);
};
