import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * It creates a UBA submission for a given year
 * @param {string} ubaYear - The year for which the UBA submission is being created.
 * @param {tenant} tenant
 * @returns A promise that resolves to null.
 */
export const createUBASubmission = (ubaYear: string, tenant: string | null): Promise<any> => {
  // Resolve silently if no UBA year provided.
  if (!ubaYear) return new Promise((resolve) => resolve(null));
  const query = { tenant };
  const url = `${API_URL}/quota-sales/generateUbaSubmission/${ubaYear}`;
  return fetcher(queryString.stringifyUrl({ url, query }), { method: 'POST' }).then();
};
