import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, DialogActions, DialogContent } from '@mui/material';

import { useTenants } from '../../../../tenant';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../common/dialog/BootstrapDialog';
import { deletePreviewVehicles } from '../../api/deletePreviewVehicles';
import { deleteVehicles } from '../../api/deleteVehicles';

import DeleteVehiclesDialogContent from './DeleteVehiclesDialogContent';
/**
 * Defines the properties required by the
 * current dialog component.
 */
interface IDeleteVehiclesDialogProps {
  setModal: Function;
  vehicles: any;
}

export interface IDeleteVehicles {
  vehicleRegistrations: {
    id: string;
    status: string;
  }[];
  token: string;
}

const DeleteVehiclesDialog = (props: IDeleteVehiclesDialogProps) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [vehicles, setVehicles] = useState<IDeleteVehicles | null>(null);
  const { currentTenant } = useTenants();

  const deletePreview = async () => {
    try {
      setLoading(true);
      setError(null);

      const res = await deletePreviewVehicles({ vehicles: props?.vehicles }, currentTenant);

      if (res?.error) {
        setError(res.error as string);
      } else {
        setVehicles(res);
      }
    } catch (error) {
      setError('Etwas ist schief gelaufen. Bitte versuche es erneut');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteVehicles = async () => {
    try {
      if (!vehicles) {
        throw new Error('Sie haben keinen Benutzer ausgewählt');
      }
      const res = await deleteVehicles(vehicles?.token, currentTenant);
      if (res?.status === 204) {
        toast.success('Benutzer erfolgreich gelöscht');
      } else if (res?.status >= 400) {
        const body = await res?.json();
        toast.error(body?.error as string);
      }
    } catch (error: any) {
      toast.error(error?.message as string);
    } finally {
      props.setModal(null);
    }
  };

  useEffect(() => void deletePreview(), [props.vehicles]);

  return (
    <div>
      <BootstrapDialog
        onClose={() => props.setModal(null)}
        aria-labelledby="customized-dialog-title"
        maxWidth="md"
        open={true}
      >
        <BootstrapDialogTitle
          id="delete-vehicles-dialog-title"
          onClose={() => props.setModal(null)}
        >
          Delete Vehicles
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{ minWidth: 200 }}
        >
          <DeleteVehiclesDialogContent
            loading={loading}
            error={error ?? undefined}
            vehicles={vehicles ?? undefined}
          />
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* The DELETE button */}
          <div style={{ flex: 1, width: 50, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={!vehicles?.token}
              onClick={() => handleDeleteVehicles()}
            >
              Delete
            </Button>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default DeleteVehiclesDialog;
