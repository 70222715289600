import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

export const saveInvoiceTemplate = (template: any): Promise<any> => {
  /**
   * Decide if we are dealing with a new or
   * existing template. The request props changed
   * based on that.
   */
  const document = template?.id;
  const tenant = template?.tenant;
  const url = `${API_URL}/v2/invoice/template/${document}`;
  const query = { tenant };
  const fullUrl = queryString.stringifyUrl({ url, query });

  const method = 'PUT';
  const body = template.content;
  /**
   * Send the request to the provided endpoint
   * and check if the response was successful
   * or not.
   */
  return fetcher(fullUrl, {
    method,
    body, // payload must be pure json
  });
};
