import toast from 'react-hot-toast';
import { Button, Link, Typography } from '@mui/material';

import { formatDateTime } from '../../../utils/date';
import GetEmailButton from '../common/GetEmailButton';

import { flagUploadPromise } from './api/flagUpload';

/**
 * Flag the provided upload id.
 *
 * @param uploadId number
 * @returns void
 */
const flagUpload = (
  uploadId: string,
  flag: boolean,
  setTriggerAction: Function,
  setRefreshList: Function,
  tenant: string | null,
) => {
  flagUploadPromise(uploadId, flag, setTriggerAction, setRefreshList, tenant)
    /**
     * Notify the user the upload that
     * the upload has been flagged.
     */
    .then(() => toast.success('Upload als bearbeitet markiert".'))
    .catch(() => toast.error('Upload als NICHT bearbeitet markiert.'));
};

export const getColumns = (setTriggerAction: Function, setRefreshList: Function, tenant: string | null) => [
  {
    field: 'tenant',
    headerName: 'Tenant',
    width: 100,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 80,
  },
  {
    field: 'uuid',
    headerName: 'UUID',
    width: 80,
  },
  {
    field: 'ownerUsername',
    headerName: 'Owner',
    width: 300,
    cellClassName: 'center--cell',
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
    renderCell: (params: any) => <GetEmailButton username={params?.row?.ownerUsername} />,
  },
  {
    field: 'updatedAt',
    headerName: 'Updated',
    width: 160,
    valueGetter: (params: any) => formatDateTime(params.row.updatedAt),
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    width: 150,
    valueGetter: (params: any) => formatDateTime(params.row.createdAt),
  },
  {
    field: 'docFile',
    headerName: 'File',
    width: 250,
    cellClassName: 'open--cell',
    disableClickEventBubbling: true,
    renderCell: (params: any) => (
      <Link
        href={params.row.docFile}
        target="_blank"
      >
        Öffnen
      </Link>
    ),
  },
  {
    field: 'isProcessed',
    headerName: 'Status',
    width: 110,
    cellClassName: 'center--cell',
    renderCell: (params: any) => {
      if (params.row.isProcessed) {
        return <Typography sx={{ color: '#2e7d32' }}>Erledigt</Typography>;
      }
      return <Typography sx={{ color: '#d32f2f' }}>Nicht erledigt</Typography>;
    },
  },
  {
    field: 'actionButton',
    headerName: 'Markieren als',
    width: 150,
    cellClassName: 'center--cell',
    disableClickEventBubbling: true,
    renderCell: (params: any) => {
      if (params.row.isProcessed) {
        return (
          <Button
            variant="outlined"
            color="error"
            onClick={() => flagUpload(params.row.uuid, false, setTriggerAction, setRefreshList, tenant)}
          >
            NICHT ERLEDIGT
          </Button>
        );
      }
      return (
        <Button
          variant="contained"
          color="success"
          onClick={() => flagUpload(params.row.uuid, true, setTriggerAction, setRefreshList, tenant)}
        >
          ERLEDIGT
        </Button>
      );
    },
  },
  {
    field: 'uba_years',
    headerName: 'UBA Years',
    width: 120,
    valueGetter: (params: any) => params.row.uba_years.join(', '),
  },
  {
    field: 'productOption',
    headerName: 'Product Option',
    width: 120,
  },
  {
    field: 'productPrice',
    headerName: 'Price for M1',
    width: 120,
  },
];
