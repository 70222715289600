import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * It takes an array of usernames and returns a promise that resolves to an array of vehicle
 * registrations
 * @param {string[]} usernames - string[]
 * @returns An array of objects.
 */
export const getVehiclesByUsernames = (usernames: string[], tenant: string | null): Promise<any> => {
  const url = `${API_URL}/vehicle-registrations/users`;
  const query = { tenant };
  return fetcher(queryString.stringifyUrl({ url, query }), {
    method: 'POST',
    body: JSON.stringify({ usernames }),
  });
};
