import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { Button } from '@mui/material';

import { FILTER_TYPE_ENUM, IFilterItem } from '../../common/PredefinedFilters';
import { ContextActionEnum, ContextScopeEnum } from '../../context/GlobalState';

/**
 * Check if the marketable filter has been selected
 * and we have a few selections present.
 *
 * @param predefinedFilters IFilterItem[]
 * @param selected number
 * @returns boolean
 */
const SendInvoiceAction = (props: {
  predefinedFilters: IFilterItem[];
  selectedIds: string[];
  setModal: Function;
  context: ContextScopeEnum;
}) => {
  const hasRequiredContext = props.context === ContextScopeEnum.QUOTAS;
  const hasNoQuotasSelected = !props.selectedIds.length;
  const isTabInvoiceGen = props?.predefinedFilters?.find(
    (filter) => filter?.type === FILTER_TYPE_ENUM.INVOICE_GENERATED,
  );
  const show = hasRequiredContext && hasNoQuotasSelected && isTabInvoiceGen;

  if (show) {
    return (
      <Button
        color="inherit"
        onClick={() => props.setModal({ type: ContextActionEnum.SEND_INVOICE })}
      >
        <ForwardToInboxIcon />
        &nbsp;Send invoices
      </Button>
    );
  }
  return <></>;
};

export default SendInvoiceAction;
