import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';

import { useFixedSearchParams } from '../hooks/useFixedSearchParams';
import { getTenants } from '../utils/api/getTenants';
import { StatusError } from '../utils/fetcher';

import NotAdmin from './Admin/common/NotAdmin';
import { AccessDenied, ServerError } from './errors';

type TenantContextData = {
  tenants: string[];
  currentTenant: string;
  setCurrentTenant: (value: string) => void;
};

const TenantContext = createContext<TenantContextData | undefined>(undefined);

export function TenantProvider({ children }: { children: React.ReactNode }) {
  const [searchParams, setSearchParams] = useFixedSearchParams();
  const [error, setError] = useState<unknown>();
  const [tenants, setTenants] = useState<string[] | undefined>();
  const [currentTenant, setCurrentTenantInternal] = useState<string | undefined>(
    // searchParams.get returns null if the key is not found, so we need to convert it to undefined
    searchParams.get('tenant') || undefined,
  );

  useEffect(() => {
    getTenants()
      .then((tenants) => {
        if (!tenants.length) throw new StatusError(403, 'No accessible tenants');
        setTenants(tenants);
      })
      .catch(setError);
  }, []);

  useEffect(() => {
    if (!tenants) return;
    // this check assures it set the first tenant from the list only if it's not already present
    // without this check when you enter a url with a specific tenant it will automatically redirect you to the first tenant from the list
    if (!searchParams.get('tenant')) {
      setCurrentTenantInternal(tenants[0]);
      setSearchParams((currentSearchParams) => {
        currentSearchParams.set('tenant', tenants[0]);
        return currentSearchParams;
      });
    }
  }, [setSearchParams, tenants]);

  const setCurrentTenant = useCallback(
    (value: string) => {
      if (!tenants?.includes(value)) {
        throw new Error(`Cannot set current tenant to ${value} as it is not in the list of accessible ones`);
      }

      setSearchParams((currentSearchParams) => {
        currentSearchParams.set('tenant', value);
        return currentSearchParams;
      });
      setCurrentTenantInternal(value);
    },
    [setSearchParams, tenants],
  );

  const context = useMemo<TenantContextData | undefined>(
    () =>
      tenants !== undefined && currentTenant !== undefined ? { tenants, currentTenant, setCurrentTenant } : undefined,
    [tenants, currentTenant, setCurrentTenant],
  );

  // Needed for old way of authorizing users where we might use expired token
  if (error && error instanceof StatusError && error.status === 401) return <NotAdmin />;

  if (error && error instanceof StatusError && error.status === 403) return <AccessDenied />;
  if (error) return <ServerError />;

  if (!context) {
    return (
      <Box sx={{ p: 8, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return <TenantContext.Provider value={context}>{children}</TenantContext.Provider>;
}

export function useTenants(): TenantContextData {
  const context = useContext(TenantContext);
  if (!context) throw new Error('useTenants must be called within a TenantProvider');
  return context;
}
