import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_URL } from '../../constants/api';
import { fetcher } from '../../utils/fetcher';

const api = createApi({
  reducerPath: 'invoicesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/v2/invoice/all`,
    fetchFn: (input, options) => fetcher(input, options, undefined, undefined, true),
  }),
  endpoints: (builder) => ({
    getInvoicesByEmail: builder.query<any, { email: string; tenant: string }>({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ email, tenant }) => {
        return {
          url: `?range=%5B0%2C99%5D&tenant=${tenant}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export default api;
