import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { DEFAULT_PAGE_SIZE } from '../../../../constants/pagination';
import { fetcher } from '../../../../utils/fetcher';
import { IPagination } from '../../context/GlobalState';
import { getVehiclesByUsernames } from '../../vehicles-admin/api/getVehiclesByUsernames';

/**
 * It takes a string of usernames, and returns a promise that resolves to an array of user objects
 * @param {string} [usernames] - A comma separated list of usernames to search for.
 * @returns An array of users.
 */
export const searchUsersPromise = async (
  tenant: string | null,
  pagination: IPagination,
  total: number,
  usernames?: string,
): Promise<any> => {
  // Add the required query params.
  const data = {
    usernames: usernames?.split(',').map((username) => username.trim()),
  };
  // Build up the complete url
  const url = `${API_URL}/users/bulkSearch`;

  const range = pagination.range ?? [0, total];
  const size = pagination.pageSize || DEFAULT_PAGE_SIZE;
  const page = !range[0] ? 0 : range[0] / size;

  const query = { tenant, page, size };
  const users = await fetcher(queryString.stringifyUrl({ url, query }), {
    method: 'POST',
    body: JSON.stringify(data),
  });

  /**
   * Now get the list of vehicles for the
   * found usernames.
   */
  const vehiclesByUsernames = data?.usernames?.length ? await getVehiclesByUsernames(data.usernames, tenant) : null;
  // const withDuplicateMarkings = markDuplicates(response.data!);

  /* Mapping the users to their vehicles. */
  const result = users.data.map((user: any) => ({
    ...user,
    vehicleRegistrations:
      vehiclesByUsernames?.data?.find((vehicle: any) => vehicle.username === user.username)?.vehicleRegistrations || [],
  }));

  /* Returning the original users object with the data property replaced with the new data. */
  return { ...users, data: result };
};
