import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Link, Typography } from '@mui/material';

import { useAuth } from '../auth';

export function AccessDenied() {
  const { t } = useTranslation('errors');
  const { signOut } = useAuth();

  const messageComponents = useMemo(
    () => ({
      signOutLink: (
        <Link
          component="button"
          onClick={signOut}
        />
      ),
    }),
    [signOut],
  );

  return (
    <Box sx={{ p: 4 }}>
      <Typography
        variant="h5"
        sx={{ mb: 2 }}
      >
        {t('403.title')}
      </Typography>
      <Typography>
        <Trans
          t={t}
          i18nKey="403.message"
          components={messageComponents}
        />
      </Typography>
    </Box>
  );
}
