export interface QuotaSaleModel {
  id: number;
  uuid: string;
  vehicleId: number;
  vehicleUuid: string;
  quotaSaleWaveId?: number;
  quotaSaleWaveUuid?: string;
  invoiceId?: number;
  uba_year: number;
  status: string;
  tenant: string;
  productOption?: 'INSTANT' | 'STANDARD' | 'BONUS';
  productPrice?: number;
}
export const emptyQuota: QuotaSaleModel = {
  id: 0,
  uuid: '',
  vehicleId: 0,
  vehicleUuid: '',
  quotaSaleWaveId: 0,
  invoiceId: 0,
  uba_year: 0,
  status: '',
  tenant: '',
  productPrice: 0,
};
