import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';

import { useTenants } from '../../../../tenant';
import { ContextActionEnum, GlobalContext } from '../../../context/GlobalState';
import { getVehicleById } from '../../../vehicles-admin/api/getVehicleById';
import DeleteVehiclesDialog from '../../../vehicles-admin/dialogs/DeleteVehiclesDialog/DeleteVehiclesDialog';
import EditVehicleDialog from '../../../vehicles-admin/dialogs/EditVehicleDialog/EditVehicleDialog';

import CustomTooltip from './CustomTooltip';
import PreviewVehicleIds from './PreviewVehicleIds';

const VehicleIdsColumn = ({ vehicleIds }: { vehicleIds: Map<number, string> }) => {
  const { modal, setModal } = useContext(GlobalContext);
  const [searchParams, _setSearchParams] = useSearchParams();
  const { currentTenant } = useTenants();

  const limit = 3;
  const [editVehicle, setEditVehicle] = useState<any>(null);

  const handleClick = (id: string | null) => handleEditVehicleRegistrationModal(id);

  const handleEditVehicleRegistrationModal = async (id: string | null) => {
    if (!id) return setEditVehicle(null);

    try {
      const vehicle = await getVehicleById(id, currentTenant);
      setEditVehicle(vehicle);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const editVehicleId = searchParams.get('editVehicleId');
    const editVehicleUuid = searchParams.get('editVehicleUuid');

    if (editVehicleId && editVehicleUuid && !editVehicle && vehicleIds.get(parseInt(editVehicleId))) {
      handleEditVehicleRegistrationModal(editVehicleUuid);
    }
  }, []);

  if (!vehicleIds?.size) return <></>;

  return (
    <>
      <PreviewVehicleIds
        vehicleIds={vehicleIds}
        limit={limit}
        handleClick={handleClick}
        customElement={
          <CustomTooltip
            title={
              <PreviewVehicleIds
                vehicleIds={vehicleIds}
                handleClick={handleClick}
                variant="contained"
              />
            }
            placement="top"
          >
            {vehicleIds?.size > limit ? <Button>More</Button> : <></>}
          </CustomTooltip>
        }
      />
      {/* Open edit vehicle dialog. */}
      {editVehicle && (
        <EditVehicleDialog
          setModal={handleEditVehicleRegistrationModal}
          setSelectedIds={() => {}}
          vehicle={editVehicle}
          nextVehicleId={null}
        />
      )}

      {/* Open delete vehicle dialog. */}
      {editVehicle && modal?.type === ContextActionEnum.DELETE_VEHICLES && (
        <DeleteVehiclesDialog
          setModal={setModal}
          vehicles={[editVehicle?.uuid]}
        />
      )}
    </>
  );
};

export default VehicleIdsColumn;
