import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';
import { markDuplicate } from '../dialogs/EditVehicleDialog/useDuplicateCheck';

import { IVehicle } from './dto';

/**
 * It makes a GET request to the `/vehicle-registrations/:vehicleId` endpoint and returns the response
 * @returns A promise that resolves to the data returned from the API.
 */
export const getVehicleById = (vehicleId: string, tenant: string | null): Promise<any> => {
  // Build up the complete url
  const url = `${API_URL}/vehicle-registrations/${vehicleId}`;

  const query = { tenant };
  return (
    fetcher(queryString.stringifyUrl({ url, query }), {
      method: 'GET',
    })
      // Get the quota uniqueness specific props.
      .then(async (vehicle: IVehicle) => await markDuplicate(vehicle))
      .catch((error) => {
        return error;
      })
  );
};
