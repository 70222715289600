import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';

import { ContextScopeEnum } from '../../context/GlobalState';

/**
 * Check if the marketable filter has been selected
 * and we have a few selections present.
 *
 * @param predefinedFilters IFilterItem[]
 * @param selectedIds string[]
 * @returns boolean
 */
const DownloadB2BCSVAction = (props: { downloadCSV: Function; context: ContextScopeEnum; selectedIds: string[] }) => {
  const show = props.context === ContextScopeEnum.INVOICING;
  const hasNoneSelected = !props.selectedIds.length;

  if (show && hasNoneSelected) {
    return (
      <Button
        color="inherit"
        onClick={() => props.downloadCSV()}
      >
        <DownloadIcon />
        &nbsp;Download B2B CSV
      </Button>
    );
  }
  return <></>;
};

export default DownloadB2BCSVAction;
