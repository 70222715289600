import { useEffect, useState } from 'react';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

import { getCognitoUser } from '../../../../../utils/api/getCognitoUser';
import { useTenants } from '../../../../tenant';
import { getProductOptionsPromise, getVehiclesByUsernamePromise } from '../../../vehicles-admin/api/getVehicles';
import { IFormField } from '../../form-generator/interfaces';

/**
 * Defines the list of properties required
 * by the EmailSearch component.
 */
interface IEmailSearchProps {
  emailField: IFormField;
  ownerUsernameField: IFormField;
  welcomeBonusField: IFormField;
  productPriceBonusValueField: IFormField;
  onChange: (arg0: { field: IFormField; event: any }[]) => void;
}

/**
 * Provides the option to the user to search for
 * user email addresses and returns an asynchronously
 * loading list of results.
 */
export default function EmailSearch(props: IEmailSearchProps) {
  // Indicates if a NOT FOUND error should be shown or not.
  const [error, setError] = useState<boolean>(false);
  // Holds the typed in email address.
  const [email, setEmail] = useState<string>('');
  // Indicates if the list of results is being loaded or not.
  const [loading, setLoading] = useState<boolean>(false);

  const { currentTenant } = useTenants();

  /**
   * Set the value of the current field from
   * the received field prop value.
   */
  useEffect(() => {
    setEmail(props.emailField.value as string);
  });

  /**
   * Store the provided email address so
   * we can trigger the backend search.
   *
   * @param value string
   */
  const onChangeEvent = (email: string) => {
    const theEmail = email.toLowerCase();
    setEmail(theEmail);
    props.onChange([{ field: props.emailField, event: theEmail }]);
  };

  const checkIfValid = (
    productOptionBonus: { value: number; validFrom: string; validTo: string } | undefined,
    currentUser: { createdAt: string | number | Date },
    vehicles: string | any[] | undefined,
  ) => {
    const endDate = productOptionBonus?.validTo;
    const startDate = productOptionBonus?.validFrom;
    const didStart = !startDate || (startDate && new Date().getTime() > new Date(startDate).getTime());
    const didNotEnd = !endDate || (endDate && new Date().getTime() < new Date(endDate).getTime());
    const userCreationValid =
      !currentUser ||
      (startDate &&
        currentUser?.createdAt &&
        new Date(currentUser?.createdAt).getTime() >= new Date(startDate).getTime());
    return !!productOptionBonus && !!didStart && !!didNotEnd && !vehicles?.length && !!userCreationValid;
  };

  /**
   * Find the user by using the email local state.
   */
  const findUserByEmail = async () => {
    if (props.emailField.validationErrorText) {
      return;
    }
    // Start the loading indicator.
    setLoading(true);
    // Trigger the search.
    const user = await getCognitoUser({ email, tenant: currentTenant });
    if (!user?.email) {
      setLoading(false);
      setError(true);
      return;
    }

    const { data: vehicles } = await getVehiclesByUsernamePromise(user.username, currentTenant);
    const { data: productOptions } = await getProductOptionsPromise(currentTenant);

    // @ts-expect-error FIXME Fix return type of `getVehiclesByUsernamePromise`
    const isEligible = checkIfValid(productOptions?.productPriceBonus, user, vehicles?.vehicleRegistrations ?? []);

    setLoading(false);
    setError(!user?.email);
    props.onChange([
      {
        field: props.productPriceBonusValueField,
        event: {
          target: {
            value:
              productOptions?.productPriceBonus?.value && isEligible
                ? productOptions?.productPriceBonus?.value.toString()
                : '',
          },
        },
      },
      { field: props.welcomeBonusField, event: { target: { checked: isEligible, disabled: !isEligible } } },
      { field: props.ownerUsernameField, event: user },
    ]);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        type="text"
        error={!!props.emailField.validationErrorText}
        helperText={props.emailField.validationErrorText}
        label={props.emailField.name}
        defaultValue={props.emailField.value}
        onChange={(event: any) => onChangeEvent(event.target.value)}
        variant="standard"
        sx={{ mb: 1 }}
      />

      <Button
        variant="contained"
        onClick={findUserByEmail}
        disabled={!!props.emailField.validationErrorText}
      >
        Finden
      </Button>

      {/* Show the loading indicator. */}
      {loading && <LinearProgress sx={{ mt: 2 }} />}

      {/* Show the NOT FOUND error. */}
      {error && (
        <Typography
          sx={{ mt: 2 }}
          color="error"
        >
          Benutzer nicht gefunden
        </Typography>
      )}
    </Box>
  );
}
