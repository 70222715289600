import React, { useRef } from 'react';
import {
  Button,
  Container,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { useLazyGetQuotasByVehicleUuidQuery } from '../../../services/quotas';
import { formatDate } from '../../../utils/date';
import { useTenants } from '../../tenant';
import { downloadFile } from '../invoicing-admin/api/downloadFile';

interface VehiclesQuotasDetailsProps {
  vehicleUuid: string;
}

const Title = styled('h3')(() => ({
  padding: '8px',
  margin: '0',
}));

const HeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  padding: '8px',
}));

const DataContentCell = styled(TableCell)(() => ({
  padding: '8px',
}));

const VehiclesQuotasDetails: React.FC<VehiclesQuotasDetailsProps> = ({ vehicleUuid }) => {
  const { currentTenant } = useTenants();
  const fileDownloadLink = useRef<HTMLAnchorElement>(null);

  const [fetchQuotas, { data: quotas, isLoading, error }] = useLazyGetQuotasByVehicleUuidQuery();

  React.useEffect(() => {
    if (vehicleUuid) {
      fetchQuotas({ filter: { vehicleUuid }, tenant: currentTenant });
    }
  }, [fetchQuotas, vehicleUuid]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error fetching quotas 😭</div>;
  }
  return (
    <>
      <Title>Quotas</Title>
      <TableContainer
        component={Paper}
        sx={{ overflowX: 'scroll' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <HeaderCell>UUID</HeaderCell>
              <HeaderCell>UBA Year</HeaderCell>
              <HeaderCell>Status</HeaderCell>
              <HeaderCell>Product Option</HeaderCell>
              <HeaderCell>Product Price</HeaderCell>
              <HeaderCell>Product Price Bonus Value</HeaderCell>
              <HeaderCell>Invoice ID</HeaderCell>
              <HeaderCell sx={{ textAlign: 'right' }}>Download</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quotas &&
              quotas.length > 0 &&
              quotas.map((quota: any) => {
                const hasSampleInvoice = quota.invoiceId && quota.invoiceId.toString()[0] === '7'; // sample invoices starts with 7
                return (
                  <TableRow key={quota.uuid}>
                    <DataContentCell sx={{ whiteSpace: 'nowrap' }}>{quota.uuid}</DataContentCell>
                    <DataContentCell>{quota.uba_year}</DataContentCell>
                    <DataContentCell>{quota.status}</DataContentCell>
                    <DataContentCell>{quota.productOption}</DataContentCell>
                    <DataContentCell>{quota.productPrice}</DataContentCell>
                    <DataContentCell>{quota.productPriceBonusValue}</DataContentCell>
                    <DataContentCell>{quota.invoiceId}</DataContentCell>
                    <DataContentCell>
                      <Container
                        disableGutters
                        sx={{ display: 'inline-flex', gap: 1, justifyContent: 'flex-end', padding: 0 }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          disabled={!quota.invoiceId}
                          sx={{ whiteSpace: 'nowrap' }}
                          onClick={() =>
                            downloadFile(
                              'Vehicle List',
                              `/v2/invoice/${quota.invoiceId}/download/vehiclesList`,
                              `vehicle_list_${quota.invoiceId}_${formatDate(new Date())}`,
                              fileDownloadLink,
                              currentTenant,
                            )
                          }
                        >
                          Vehicle List
                        </Button>
                        {hasSampleInvoice ? (
                          <Button
                            variant="outlined"
                            size="small"
                            disabled={!quota.invoiceId}
                            sx={{ whiteSpace: 'nowrap' }}
                            onClick={() =>
                              downloadFile(
                                'Sample Invoice',
                                `/v2/invoice/${quota.invoiceId}/download/sampleInvoice`,
                                `sample_invoice_${quota.invoiceId}_${formatDate(new Date())}`,
                                fileDownloadLink,
                                currentTenant,
                              )
                            }
                          >
                            Sample Invoice
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            size="small"
                            disabled={!quota.invoiceId}
                            sx={{ whiteSpace: 'nowrap' }}
                            onClick={() =>
                              downloadFile(
                                'Credit Note',
                                `/v2/invoice/${quota.invoiceId}/download/creditNote`,
                                `credit_note_${quota.invoiceId}_${formatDate(new Date())}`,
                                fileDownloadLink,
                                currentTenant,
                              )
                            }
                          >
                            Credit Note
                          </Button>
                        )}
                      </Container>
                    </DataContentCell>
                  </TableRow>
                );
              })}
            {quotas && quotas.length === 0 && (
              <TableRow>
                <DataContentCell colSpan={10}>No quotas found</DataContentCell>
              </TableRow>
            )}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              ref={fileDownloadLink}
              style={{ visibility: 'hidden' }}
            >
              Download FILE
            </a>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default VehiclesQuotasDetails;
