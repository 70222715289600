import { Box, TextField } from '@mui/material';

import { IDuplicateQuotaVehicleData } from '../../../api/dto';

const DuplicateQuotaWarningRow = (props: { quotaWarning: IDuplicateQuotaVehicleData }) => {
  return (
    <Box sx={styles().container}>
      {/* First name. */}
      <TextField
        type="string"
        disabled={true}
        label="User First Name"
        value={props.quotaWarning?.vehicle?.firstName || '---'}
        variant="standard"
        sx={styles().input}
      />

      {/* Last name. */}
      <TextField
        type="string"
        disabled={true}
        label="User Last Name"
        value={props.quotaWarning?.vehicle?.lastName || '---'}
        variant="standard"
        sx={styles().input}
      />

      {/* Company. */}
      <TextField
        type="string"
        disabled={true}
        label="User company"
        value={props.quotaWarning?.vehicle?.companyName || '---'}
        variant="standard"
        sx={styles().input}
      />

      <Box sx={styles().quotaLabel}>{props.quotaWarning.uba_year}</Box>
    </Box>
  );
};

export default DuplicateQuotaWarningRow;

const styles = (): any => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  input: {
    width: '150px',
  },
  quotaLabel: {
    width: '130px',
    height: '40px',
    border: '2px solid #45639a',
    background: '#4472c4',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    fontWidth: 900,
    fontSize: '20px',
  },
});
