import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Grid, LinearProgress, Stack } from '@mui/material';
import { DataGridPro, GridOverlay } from '@mui/x-data-grid-pro';

import { useTenants } from '../../tenant';
import JumpTo from '../common/JumpTo';
import { GlobalContext } from '../context/GlobalState';

import { Columns } from './Columns';

/**
 * Custom loading overlay to indicate to the user that
 * requested data is being loaded from the backend.
 */
function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

const InvoiceList = () => {
  const {
    invoices,
    loading,
    pagination,
    setPagination,
    total,
    selectedIds,
    setSelectedIds,
    setTriggerAction,
    setRefreshList,
  } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rows, setRows] = useState<any[]>([]);
  const { currentTenant } = useTenants();

  /**
   * Refs used for the file download links
   * we need to trigger automatically for
   * saving the files.
   */
  const fileDownloadLink = useRef<any>(null);

  /**
   * Prepare the vehicle registrations table.
   */
  useEffect(() => {
    const inv = invoices?.map((data: any) => ({
      ...data.userData,
      ...data,
    }));

    setRows(inv && inv.length ? inv.map((v: any) => v) : []);
  }, [invoices]);

  const setItemsRange = useCallback(
    (currentPageNumber: number, currentPageSize: number): void => {
      const totalItemsCount = total;
      const numberOfItemsPerPage = currentPageSize;
      const page = currentPageNumber;

      // page: 0 => 10 start: 0 end: 9
      // page: 1 => 10 start: 11 end: 20
      const start = page * numberOfItemsPerPage;
      // start = start < 0 ? 0 : start;
      const end = Math.min(start + numberOfItemsPerPage - 1, totalItemsCount);
      /**
       * Set the changed pagination properties on the
       * global context.
       */
      setPagination({ range: [start, end], pageSize: currentPageSize });
      // Set the current page in the component state.
      setCurrentPage(page);
    },
    [setPagination, setCurrentPage, total],
  );

  return (
    <Grid container>
      <Grid
        item
        md={12}
      >
        <div style={{ height: pagination.pageSize > 10 ? '85vh' : 630 }}>
          <DataGridPro
            components={{
              LoadingOverlay: CustomLoadingOverlay,
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  No rows in DataGrid
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  There are no results to display
                </Stack>
              ),
            }}
            sx={{ backgroundColor: 'white' }}
            rows={rows}
            columns={Columns(setTriggerAction, setRefreshList, fileDownloadLink, currentTenant)}
            loading={loading}
            paginationMode="server"
            page={currentPage}
            columnBuffer={Columns(setTriggerAction, setRefreshList, fileDownloadLink, currentTenant)?.length + 1} // important for testing
            pageSize={pagination.pageSize}
            rowsPerPageOptions={[100, 500, 1000, 2000]}
            onPageChange={(page) => !loading && setItemsRange(page, pagination.pageSize)}
            onPageSizeChange={(newPageSize) => !loading && setItemsRange(currentPage, newPageSize)}
            pagination={true}
            rowCount={total}
            onSelectionModelChange={(newSelection: any) => setSelectedIds(newSelection)}
            selectionModel={selectedIds}
            checkboxSelection
          />
        </div>
        {/* A custom component that allows admins to jump to a specific page. */}
        <JumpTo
          currentPage={currentPage}
          jump={(page: number) => !loading && setItemsRange(page, pagination.pageSize)}
        />
      </Grid>

      {/* Following CSV links are used to download the CSV files. */}
      <a
        ref={fileDownloadLink}
        style={{ visibility: 'hidden' }}
      >
        Download FILE
      </a>
    </Grid>
  );
};

export default InvoiceList;
