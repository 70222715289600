export const getColumns = (_setTriggerAction: Function, _setRefreshList: Function, _fileDownloadLink: any) => [
  {
    field: 'tenant',
    headerName: 'Tenant',
    width: 100,
  },
  {
    field: 'name',
    headerName: 'Document',
    width: 200,
  },
];
