import { vehicleClient } from '../../../../utils/api/clients';
import { getAccessToken } from '../../../../utils/auth';
import { QuotaSaleModel } from '../dialogs/EditQuotaSalesDialog/quota.model';

interface PatchQuotaInput {
  id: string;
  tenant: string;
  body: Pick<QuotaSaleModel, 'productOption'>;
}

export const patchQuota = async (quotaInput: PatchQuotaInput) => {
  const token = await getAccessToken();

  return vehicleClient.PATCH('/quota-sales/{uuid}', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: { quotaSale: { productOption: quotaInput.body.productOption as any } },
    params: {
      path: {
        uuid: `${quotaInput.id}`,
      },
      query: {
        tenant: quotaInput.tenant,
      },
    },
  });
};
