import React, { useState } from 'react';
import { Button, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const HeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  padding: '8px',
}));

const DataKeyCell = styled(TableCell)(() => ({
  fontWeight: 'bold',
  width: '100px',
  padding: '8px',
}));

const DataContentCell = styled(TableCell)(() => ({
  padding: '8px',
}));

export const UserDetailsTable = ({ userData }: { userData: Record<string, any> }) => {
  const personalDataKeys = [
    'isCompany',
    'company',
    'tenant',
    'firstName',
    'lastName',
    'username',
    'email',
    'phoneNumber',
    'vatId',
    'taxNumber',
    'iban',
    'debtorNumber',
  ];
  const addressDataKeys = ['country', 'city', 'street', 'zip', 'houseNumber'];
  const otherDataKeys = Object.keys(userData).filter(
    (key) => !personalDataKeys.includes(key) && !addressDataKeys.includes(key),
  );
  const [isOtherDataOpen, setIsOtherDataOpen] = useState(false);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <HeaderCell colSpan={2}>Personal Data</HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {personalDataKeys.map((key) => (
            <TableRow key={key}>
              <DataKeyCell style={{ whiteSpace: 'nowrap' }}>{key}</DataKeyCell>
              <DataContentCell style={{ whiteSpace: 'nowrap' }}>{userData[key]?.toString() || ''}</DataContentCell>
            </TableRow>
          ))}
        </TableBody>

        <TableHead>
          <TableRow>
            <HeaderCell colSpan={2}>Address Data</HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addressDataKeys.map((key) => (
            <TableRow key={key}>
              <DataKeyCell style={{ whiteSpace: 'nowrap' }}>{key}</DataKeyCell>
              <DataContentCell style={{ whiteSpace: 'nowrap' }}>{userData[key]?.toString() || ''}</DataContentCell>
            </TableRow>
          ))}
        </TableBody>

        <TableHead>
          <TableRow>
            <HeaderCell colSpan={2}>Other Data</HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <DataContentCell colSpan={2}>
              <Button onClick={() => setIsOtherDataOpen(!isOtherDataOpen)}>
                {isOtherDataOpen ? 'Hide other data' : 'Show other data'}
              </Button>
            </DataContentCell>
          </TableRow>
          {isOtherDataOpen &&
            otherDataKeys.sort().map((key) => (
              <TableRow key={key}>
                <DataKeyCell style={{ whiteSpace: 'nowrap' }}>{key}</DataKeyCell>
                <DataContentCell style={{ whiteSpace: 'nowrap' }}>{userData[key]?.toString() || ''}</DataContentCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
