import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

const shouldInitDatadog = process.env.NODE_ENV !== 'development';

const DatadogInit = () => {
  useEffect(() => {
    if (!shouldInitDatadog) {
      return;
    }

    datadogRum.init({
      applicationId: '0c2bda24-3547-4682-8a7c-8893c1f3b4c4',
      clientToken: 'pubbe2ed267b0470bf80f4f24518a487919',
      site: 'datadoghq.eu',
      service: `carbonify-admin-frontend`,
      env: process.env.NODE_ENV === 'production' ? 'prod' : 'staging',
      version: process.env.REACT_APP_VERSION || 'unknown',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      beforeSend: (event: any) => {
        if (event?.error?.message && event?.error?.message.includes('MUI: License key expired')) {
          return false;
        }
        return true;
      },
    });
  }, []);

  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
};

export default DatadogInit;
