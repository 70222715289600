import { useEffect, useState } from 'react';
import { Box, MenuItem, Select, Typography } from '@mui/material';

type ITenantSelectProps = {
  tenants: string[];
  selectedTenant?: string;
  callback?: Function;
  enableNoSelection?: boolean;
  mandatory?: boolean;
};
export const TenantSelect = ({
  tenants,
  selectedTenant,
  callback,
  enableNoSelection,
  mandatory,
}: ITenantSelectProps) => {
  const [value, setValue] = useState<string>(enableNoSelection ? 'none' : '');

  const handleChange = (event: any) => {
    const value = event.target.value === 'none' ? '' : event.target.value;
    callback?.(value);
  };

  useEffect(() => {
    if (selectedTenant === undefined) {
      return;
    }
    setValue(selectedTenant);
  }, [selectedTenant]);

  return (
    <Box>
      {!!tenants?.length && (
        <Select
          size="small"
          value={value}
          defaultValue={enableNoSelection ? 'none' : ''}
          onChange={(event) => handleChange(event)}
          label={selectedTenant}
        >
          {enableNoSelection && (
            <MenuItem
              key=""
              value="none"
            >
              - Bitte auswählen -
            </MenuItem>
          )}
          {tenants.map((tenant: string) => (
            <MenuItem
              key={`${tenant}}`}
              value={tenant}
            >
              {tenant}
            </MenuItem>
          ))}
        </Select>
      )}
      {!tenants && (
        <Typography
          component="div"
          sx={{ flex: 1, color: 'red', ml: 1 }}
        >
          Keine Tenant gefunden.
        </Typography>
      )}
      {!!mandatory && (
        <Typography
          component="div"
          sx={{ flex: 1, color: 'red', ml: 1 }}
        >
          Keine Tenant gefunden.
        </Typography>
      )}
    </Box>
  );
};
