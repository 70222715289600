import { Fragment, useContext, useEffect } from 'react';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Card, CardContent, Divider, List, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';

import { GlobalContext } from '../context/GlobalState';

import { FILTER_TYPE_ENUM, IFilterItem } from './PredefinedFilters';

/**
 * Renders a filter menu usually placed on the
 * left side of a list.
 *
 * @param props { filters: IFilterItem[] }
 * @returns
 */
function FilterMenu(props: { filters: IFilterItem[] }) {
  const { predefinedFilters, setPredefinedFilters, setChecksum } = useContext(GlobalContext);

  const selectedFiltersIds = predefinedFilters.map((filter) => filter.id);

  // TODO - Check if this is necessary.
  useEffect(() => {
    if (predefinedFilters && !predefinedFilters.length) {
      // Clear the checksum data received previously.
      setChecksum(null);
    }
  }, [predefinedFilters, setChecksum]);

  /**
   * Handles the toggling of the filters.
   *
   * @param filterId number
   * @returns void
   */
  const handleToggle = (filterId: number) => {
    /**
     * Find the filter that was just clicked
     * by the user and select / unselect it
     * and also add it to the list of predefined
     * filters.
     */
    const foundFilter = props.filters.find((f) => f.id === filterId);
    if (!foundFilter) return;
    const isFilterAlreadySelected = selectedFiltersIds.includes(filterId);

    if (isFilterAlreadySelected) {
      /* Removing the filter from the list of predefined filters. */
      setPredefinedFilters(predefinedFilters.filter((filter) => filter.id !== foundFilter.id));
      return;
    }

    /* Checking if a same-category filter is already selected. */
    const previousFilter = predefinedFilters.find(
      (item) => Object.keys(item.filter)?.[0] === Object.keys(foundFilter.filter)?.[0],
    );
    /* Remove any same-category filter and add the new one. */
    setPredefinedFilters([...predefinedFilters.filter((filter) => filter.id !== previousFilter?.id), foundFilter]);
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <List
          sx={{ bgcolor: 'background.paper' }}
          component="nav"
        >
          {props.filters.map((filter) => {
            const isSelected = selectedFiltersIds.includes(filter.id);
            if (filter.type === FILTER_TYPE_ENUM.DIVIDER) {
              return (
                <Fragment key={filter.id}>
                  <li>
                    <Typography
                      sx={{ mt: 2, ml: 2 }}
                      color="text.secondary"
                      display="block"
                      variant="caption"
                    >
                      {filter.name}
                    </Typography>
                  </li>
                  <Divider component="li" />
                </Fragment>
              );
            } else {
              return (
                <MenuItem
                  sx={{ mt: 1 }}
                  key={filter.id}
                  disabled={!filter.active}
                  selected={isSelected}
                  onClick={() => handleToggle(filter.id)}
                >
                  <ListItemIcon sx={{ color: isSelected ? '#1976d2' : '' }}>
                    {filter.icon ? filter.icon : <FormatListBulletedIcon />}
                  </ListItemIcon>
                  <ListItemText
                    id={filter.name}
                    primary={filter.name}
                  />
                </MenuItem>
              );
            }
          })}
        </List>
      </CardContent>
    </Card>
  );
}

export default FilterMenu;
