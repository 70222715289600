import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';

import { useTenants } from '../../../../tenant';
import { BootstrapDialogTitle } from '../../../common/dialog/BootstrapDialog';
import ResultsTable, { IDenseTableRow } from '../../../common/dialog/ResultsTable/ResultsTable';
import { IWizardStep } from '../../../common/wizard-steps';
import { ContextActionEnum, GlobalContext } from '../../../context/GlobalState';
import { DialogProgressCard } from '../../../vehicles-admin/dialogs/utils/DialogProgressCard';
import { fetchLatestQuotaIds } from '../../api/fetchLatestQuotaIds';
import { markPaidAllQuotaIds } from '../../api/markAsPaid';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface IPayQuotaSalesDialogProps {
  selectedIds: string[];
  setModal: Function;
  total: number;
}

interface IPayTriggerAction {
  type: ContextActionEnum;
  progress: number;
  // quotaSaleIds: number[];
}

/**
 * Defines the wizard states that
 * the user needs to follow.
 */
const PayQuotasStateSteps: IWizardStep[] = [
  { id: 0, name: 'Fetch Quota IDs' },
  { id: 1, name: 'Pay Quotas' },
  { id: 2, name: 'Finished' },
];

export default function PayQuotaSalesDialog(props: IPayQuotaSalesDialogProps) {
  const { currentTenant } = useTenants();
  // Get some required properties from the global state.
  const { modal } = useContext(GlobalContext);
  // Defines the current state of the wizard.
  const [currentState, setCurrentState] = useState<IWizardStep>(PayQuotasStateSteps[0]);
  // Define the local actions so we know when to show the ProgressCard.
  const [trigger, setTrigger] = useState<IPayTriggerAction | null>(null);
  // Holds all the quota sale ids that we obtained.
  const [quotaSaleIds, setQuotaSaleIds] = useState<IDenseTableRow[]>([]);

  /**
   * Create the table rows that we will
   * use to display the status of the
   * following actions.
   */
  useEffect(() => {
    const all: IDenseTableRow[] = props.selectedIds.map((id: string, index: number) => ({
      order: index + 1,
      quotaId: id,
      fetchStatus: 1,
      payStatus: 1,
    }));
    setQuotaSaleIds(all);
  }, []);

  return (
    <div>
      <BootstrapDialog
        onClose={() => props.setModal(null)}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => props.setModal(null)}
        >
          {modal?.type.toString()}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Stepper
            activeStep={currentState.id}
            alternativeLabel
            sx={{ mb: 3 }}
          >
            {PayQuotasStateSteps.map((state) => (
              <Step key={state.id}>
                <StepLabel>{state.name}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {quotaSaleIds && quotaSaleIds.length !== 0 && (
            <Box sx={{ mt: 4, width: 550, flexGrow: 1, height: '30vh' }}>
              <ResultsTable rows={quotaSaleIds} />
            </Box>
          )}

          {trigger && (
            <Box sx={{ mt: 3 }}>
              <DialogProgressCard trigger={trigger} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {currentState.id === 0 && (
            <Button
              onClick={() =>
                fetchLatestQuotaIds(quotaSaleIds, setTrigger, setCurrentState, currentTenant, PayQuotasStateSteps[1])
              }
            >
              Next - Fetch Quota IDs
            </Button>
          )}
          {currentState.id === 1 && (
            <Button
              onClick={() =>
                markPaidAllQuotaIds(quotaSaleIds, setTrigger, setCurrentState, PayQuotasStateSteps[2], currentTenant)
              }
            >
              Next - Pay them
            </Button>
          )}
          {currentState.id === 2 && <Button onClick={() => props.setModal(null)}>Done</Button>}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
