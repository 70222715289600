import { Fragment, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

import { IButtonState, IFormField } from '../../../../common/form-generator/interfaces';
import { IDuplicateQuotaVehicleData, IQuotaSale, IVehicle } from '../../../api/dto';
import { rejectQuota } from '../../../api/rejectQuota';

import DuplicateQuotaWarningRow from './DuplicateQuotaWarningRow';

const DuplicateQuotaWarning = (props: {
  vehicle: IVehicle;
  setShouldSave: any;
  buttonStates: IButtonState[];
  fields: IFormField[];
  setFields: any;
  saveSuccessful: boolean;
}) => {
  // Holds the list of button states that can be shown in here.
  const [acceptedButtonStates, setAcceptedButtonStates] = useState<IButtonState[] | null>(null);
  const acceptedButtonStateKeys = ['approved'];
  const [quotaSaleIdForRejection, setQuotaSaleIdForRejection] = useState<string | undefined>();
  const [saveInitiated, setSaveInitiated] = useState<boolean>(false);

  /**
   * Get the UBA year of the quota that is
   * reported as duplicate.
   *
   * Note: we need this to know which quota
   * id to reject.
   */
  const getUbaYear = () => {
    if (props.vehicle.duplicateQuota?.duplicates?.length) {
      return props.vehicle.duplicateQuota.duplicates[0].uba_year;
    }
    return undefined;
  };

  /**
   * Get the quota sale ID that we can currently
   * REJECT or null if there is none.
   */
  const getQuotaSaleIdFromList = (ubaYear?: number): string | undefined => {
    /**
     * Check if a matching quota sale exists on the current vehicle
     * so we know if we can show the REJECT button.
     */
    const quotaSaleExists = props.vehicle.quotaSales?.find(
      (quota: IQuotaSale) => quota.uba_year === ubaYear && quota.status === 'USER_REQUESTED',
    );
    // Return the ID of the quota sale we can reject.
    return quotaSaleExists?.uuid;
  };

  /**
   * The function `handleRejectQuota` sets a flag to save changes to a vehicle and then handles a
   * REJECT quota request.
   */
  const handleVehicleSave = () => {
    // Indicate that the save request was initiated by this component.
    setSaveInitiated(true);
    // Save the changes to the vehicle if any.
    props.setShouldSave(true);
  };

  useEffect(() => {
    if (!props.buttonStates) return;
    const acceptedStates = props.buttonStates.filter((state: IButtonState) =>
      acceptedButtonStateKeys.includes(state.key),
    );
    const activeStates = acceptedStates.map((state: IButtonState) => ({ ...state, active: true }));
    setAcceptedButtonStates([...activeStates]);
  }, [props.buttonStates]);

  /**
   * Monitor if the save request was successful and
   * was initiated from this component.
   */
  useEffect(() => {
    if (!props.saveSuccessful || !saveInitiated || !quotaSaleIdForRejection) return;
    // Reset the save initiated flag.
    setSaveInitiated(false);
    // Handle the REJECT quota request.
    rejectQuota(quotaSaleIdForRejection, props.vehicle.tenant);
  }, [props.saveSuccessful]);

  /**
   * Check if the reject button should be
   * available for the user or not.
   */
  useEffect(() => {
    /**
     * Check if the reported duplicate quotas refer to any of
     * the current quotaSales that the vehicle already contains.
     */
    setQuotaSaleIdForRejection(getQuotaSaleIdFromList(getUbaYear()));
  }, []);

  if (!props?.vehicle?.duplicateQuota) {
    return <></>;
  }

  return (
    <Box sx={styles().container}>
      {/* The title. */}
      <Typography sx={styles().title}>Diese FIN ist bereits im System hintergelegt</Typography>

      <Box sx={styles().quotaList}>
        <Typography sx={styles().quotaListTitle}>Quotenanträge gestellt von:</Typography>

        {props.vehicle.duplicateQuota.duplicates
          // .filter((q: IDuplicateQuotaYear) => q.isDuplicate)
          .map((q: IDuplicateQuotaVehicleData) => (
            <DuplicateQuotaWarningRow
              key={q.uuid}
              quotaWarning={q}
            />
          ))}
      </Box>

      <Box sx={styles().footer}>
        {/* The SAVE button */}
        <Button onClick={() => props.setShouldSave(true)}>Trotzdem speichern</Button>

        {/* Configured button states. */}
        <Fragment>
          {!!props.vehicle && acceptedButtonStates && acceptedButtonStates.length && (
            <>
              {/* The configured action buttons */}
              {acceptedButtonStates.map((buttonState) => (
                <Button
                  key={buttonState.key}
                  disabled={!buttonState.active}
                  onClick={() => {
                    buttonState.onClick(
                      props.vehicle?.uuid,
                      props.setShouldSave,
                      props.fields,
                      props.setFields,
                      props.vehicle,
                      null,
                      true,
                    );
                  }}
                >
                  {buttonState.label}
                </Button>
              ))}
            </>
          )}
        </Fragment>

        {/* The REJECT button. */}
        {quotaSaleIdForRejection && (
          <Button
            onClick={() => handleVehicleSave()}
            sx={styles().rejectButton}
          >
            Ablehnen
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default DuplicateQuotaWarning;

const styles = (): any => ({
  container: {
    mt: 4,
    mx: 4,
    border: '2px solid red',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    p: 2,
  },
  title: {
    fontSize: '20px',
    fontWeight: 700,
    textAlign: 'center',
    mb: 4,
  },
  quotaList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    alignItems: 'center',
  },
  quotaListTitle: {
    width: '88%',
    fontSize: '19px',
    fontWeight: 700,
    textAlign: 'left',
  },
  footer: {
    mt: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
    px: 4,
  },
  saveButton: {
    border: '2px solid #45639a',
    height: '40px',
    background: '#4472c4',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    fontWidth: 900,
    fontSize: '16px',
    textTransform: 'none',
    '&:hover': {
      background: '#8DA2C8',
      border: '2px solid #8DA2C8',
    },
  },
  rejectButton: {
    border: '2px solid #BE0002',
    height: '40px',
    background: '#ff0002',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    fontWidth: 900,
    fontSize: '16px',
    textTransform: 'none',
    '&:hover': {
      background: '#FF8384',
      border: '2px solid #FF8384',
    },
  },
});
