import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';

import { ContextScopeEnum } from '../../context/GlobalState';

/**
 * Check if the marketable filter has been selected
 * and we have a few selections present.
 *
 * @param predefinedFilters IFilterItem[]
 * @param selectedIds string[]
 * @returns boolean
 */
const DownloadB2BtoXML = (props: { downloadXML: Function; context: ContextScopeEnum; selectedIds: string[] }) => {
  const tab = props.context === ContextScopeEnum.INVOICING;
  const hasNoneSelected = !props.selectedIds.length;
  const show = tab && hasNoneSelected;

  if (show) {
    return (
      <Button
        color="inherit"
        onClick={() => props.downloadXML()}
      >
        <DownloadIcon />
        &nbsp;Download B2B XML
      </Button>
    );
  }
  return <></>;
};

export default DownloadB2BtoXML;
