const VinRegExp = /^[A-HJ-NPR-Z0-9]{17}$/;

const VinTranslationNumbersTable = {
  A: 1,
  J: 1,
  B: 2,
  K: 2,
  S: 2,
  C: 3,
  L: 3,
  T: 3,
  D: 4,
  M: 4,
  U: 4,
  E: 5,
  N: 5,
  V: 5,
  F: 6,
  O: 6,
  W: 6,
  G: 7,
  P: 7,
  X: 7,
  H: 8,
  Q: 8,
  Y: 8,
  I: 9,
  R: 9,
  Z: 9,
  '0': 0,
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
  '6': 6,
  '7': 7,
  '8': 8,
  '9': 9,
};

/**
 * Utility function for calculating the check digit for a VIN value.
 *
 * @param vin the VIN for which the check digit is calculated
 * @returns the check digit as a string; if the check digit cannot be calculated
 * based on the provided VIN value, then an empty string is returned.
 */
export const calculateVinCheckDigit = (vin?: string) => {
  if (!vin) {
    return '';
  }

  if (!VinRegExp.test(vin)) {
    return '';
  }

  let sum = 0;
  for (let i = 0; i < vin.length; i++) {
    const translatedNumber = VinTranslationNumbersTable[vin.charAt(i) as keyof typeof VinTranslationNumbersTable] ?? 1;
    const position = 17 - i;
    const weight = position > 9 ? position - 8 : position + 1;
    sum += translatedNumber * weight;
  }

  const checkDigitNumber = sum % 11;
  const checkDigit = checkDigitNumber === 10 ? 'X' : String(checkDigitNumber);

  return checkDigit;
};
