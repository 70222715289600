import { formatDate, formatDateTime } from '../../../utils/date';

export const Columns = [
  {
    field: 'tenant',
    headerName: 'Tenant',
    width: 100,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
  },
  {
    field: 'uuid',
    headerName: 'UUID',
    width: 300,
  },
  {
    field: 'vehicleId',
    headerName: 'Vehicle ID',
    type: 'number',
    width: 90,
  },
  {
    field: 'vehicleUuid',
    headerName: 'Vehicle UUID',
    width: 300,
  },
  {
    field: 'quotaSaleWaveId',
    headerName: 'Quota Wave ID',
    type: 'number',
    width: 90,
  },
  {
    field: 'quotaSaleWaveUuid',
    headerName: 'Quota Wave UUID',
    width: 300,
  },
  {
    field: 'invoiceId',
    headerName: 'Invoice ID',
    type: 'number',
    width: 230,
  },
  {
    field: 'productOption',
    headerName: 'Product Option',
    width: 130,
  },
  {
    field: 'productPrice',
    headerName: 'Product Price',
    width: 130,
  },
  {
    field: 'productPriceBonusValue',
    headerName: 'Welcome Bonus',
    width: 130,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 130,
  },
  {
    field: 'uba_year',
    headerName: 'UBA Year',
    width: 130,
  },
  {
    field: 'updatedAt',
    headerName: 'Updated',
    width: 160,
    valueGetter: (params: any) => formatDateTime(params.row.updatedAt),
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 120,
    valueGetter: (params: any) => formatDate(params.row.createdAt),
  },
  {
    field: 'submittedAt',
    headerName: 'Submitted At',
    width: 120,
    valueGetter: (params: any) => formatDate(params.row.submittedAt),
  },
  {
    field: 'approvalDate',
    headerName: 'Approval Date',
    width: 120,
    valueGetter: (params: any) => formatDate(params.row.approvalDate),
  },
  {
    field: 'declineDate',
    headerName: 'Decline Date',
    width: 120,
    valueGetter: (params: any) => formatDate(params.row.declineDate),
  },
  {
    field: 'soldAt',
    headerName: 'Sold At',
    width: 120,
    valueGetter: (params: any) => formatDate(params.row.soldAt),
  },
  {
    field: 'payoutDate',
    headerName: 'Payout Date',
    width: 120,
    valueGetter: (params: any) => formatDate(params.row.payoutDate),
  },
  {
    field: 'firstName',
    headerName: 'Vorname',
    width: 120,
  },
  {
    field: 'lastName',
    headerName: 'Nachname',
    width: 120,
  },
  {
    field: 'licensePlate',
    headerName: 'Kennzeichen',
    width: 120,
  },
  {
    field: 'carId',
    headerName: 'FIN',
    width: 220,
  },
  {
    field: 'utmSource',
    headerName: 'UTM Source',
    width: 120,
    valueGetter: (params: any) => params.row.trackingParameters?.utmSource ?? '',
  },
  {
    field: 'utmMedium',
    headerName: 'UTM Medium',
    width: 120,
    valueGetter: (params: any) => params.row.trackingParameters?.utmMedium ?? '',
  },
  {
    field: 'utmCampaign',
    headerName: 'UTM Campaign',
    width: 120,
    valueGetter: (params: any) => params.row.trackingParameters?.utmCampaign ?? '',
  },
];
