enum VehicleClassesEnum {
  M1 = 'M1',
  M3 = 'M3',
  N1 = 'N1',
  N2 = 'N2',
  N3 = 'N3',
  L1e = 'L1e',
  L2e = 'L2e',
  L3e = 'L3e',
  L3eA1 = 'L3e-A1',
  L3eB = 'L3e-B',
  L4e = 'L4e',
  L4eA1 = 'L4e-A1',
  L4eB = 'L4e-B',
  L5e = 'L5e',
  L6e = 'L6e',
  L7e = 'L7e',
  Andere = 'Andere',
}

export const availableVehicleClasses = [
  { name: 'M1 (PKW)', value: VehicleClassesEnum.M1 },
  { name: 'M3 (Bus)', value: VehicleClassesEnum.M3 },
  { name: 'N1 (leichte Nutzfahrzeuge)', value: VehicleClassesEnum.N1 },
  { name: 'N2 (schwere Nutzfahrzeuge)', value: VehicleClassesEnum.N2 },
  { name: 'N3 (schwere Nutzfahrzeuge)', value: VehicleClassesEnum.N3 },
  { name: 'L1e (alle Unterklassen)', value: VehicleClassesEnum.L1e },
  { name: 'L2e (alle Unterklassen)', value: VehicleClassesEnum.L2e },
  { name: 'L3e (zulassungspflichtig)', value: VehicleClassesEnum.L3e },
  { name: 'L3e-A1', value: VehicleClassesEnum.L3eA1 },
  { name: 'L3e-B (Leichtkrafrad B)', value: VehicleClassesEnum.L3eB },
  { name: 'L4e (zulassungspflichtig)', value: VehicleClassesEnum.L4e },
  { name: 'L4e-A1', value: VehicleClassesEnum.L4eA1 },
  { name: 'L4e-B', value: VehicleClassesEnum.L4eB },
  { name: 'L5e (alle Unterklassen)', value: VehicleClassesEnum.L5e },
  { name: 'L6e (alle Unterklassen)', value: VehicleClassesEnum.L6e },
  { name: 'L7e (alle Unterklassen)', value: VehicleClassesEnum.L7e },
  { name: 'Andere', value: VehicleClassesEnum.Andere },
];
