import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';

import { FILTER_TYPE_ENUM, IFilterItem } from '../../common/PredefinedFilters';
import { ContextActionEnum, ContextScopeEnum } from '../../context/GlobalState';

/**
 * Trigger the Quota Sale approval process for
 * the selected vehicles.
 *
 * @param predefinedFilters IFilterItem[]
 * @param selectedIds string[]
 * @returns boolean
 */
const CreateUBASubmissionQuotaSalesAction = (props: {
  predefinedFilters: IFilterItem[];
  selectedIds: string[];
  setModal: Function;
  context: ContextScopeEnum;
}) => {
  const hasRequiredContext = props.context === ContextScopeEnum.QUOTAS;
  const hasPredefinedFilters = !!props.predefinedFilters && !!props.predefinedFilters.length;
  const isUserRequested = props?.predefinedFilters?.find((filter) => filter?.type === FILTER_TYPE_ENUM.USER_REQUESTED);
  const hasNoneSelected = !props.selectedIds.length;
  const show = hasPredefinedFilters && hasNoneSelected && isUserRequested && hasRequiredContext;

  if (show) {
    return (
      <Button
        color="inherit"
        onClick={() => props.setModal({ type: ContextActionEnum.CREATE_UBA_SUBMISSION, ids: props.selectedIds })}
      >
        <DownloadIcon />
        &nbsp;UBA Abgabe erstellen
      </Button>
    );
  }
  return <></>;
};

export default CreateUBASubmissionQuotaSalesAction;
