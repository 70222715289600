import { useContext, useState } from 'react';
import { Copyright } from '@mui/icons-material';
import { Alert, Box, Container, Grid, Snackbar, Toolbar } from '@mui/material';

import ProgressCard from '../common/cards/ProgressCard';
import { ContextActionEnum, GlobalContext } from '../context/GlobalState';

import BulkEmailDialog from './dialogs/BulkEmailDialog';
import DeleteUserDialog from './dialogs/DeleteUserDialog/DeleteUserDialog';
import EditUserDialog from './dialogs/EditUserDialog/EditUserDialog';
import SearchUsers from './SearchUsers';
import UsersList from './UsersList';

const Users = () => {
  /**
   * Get the required context properties to be able
   * to react to their changes.
   */
  const {
    selectedUsernames,
    setSelectedUsernames,
    modal,
    users,
    setModal,
    triggerAction,
    setTriggerAction,
    sendBulkEmails,
    total,
  } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(true);

  /**
   * Set the selected template from the template
   * dialog locally so we can take action.
   *
   * @param template string
   * @returns void
   */
  const sendEmails = (usernames: string[], template: string) => {
    // Close the modal since we don't need it anymore.
    setModal(null);
    // Start the action of sending the bulk emails.
    sendBulkEmails(usernames, template);
  };

  /**
   * Handle the completed action of sending emails.
   * We need to empty the selected rows and remove
   * the ProgressCard
   */
  const sendComplete = () => {
    // Close the action progress card.
    setTriggerAction(null);
    // De-select the previously selected rows.
    setSelectedUsernames([]);
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />

      <Container
        sx={{ mt: 4, mb: 4 }}
        maxWidth={false}
      >
        <Grid
          container
          spacing={2}
        >
          {triggerAction && (
            <Grid
              item
              xs={12}
            >
              <ProgressCard />
            </Grid>
          )}

          <Grid
            item
            xs={12}
          >
            <SearchUsers setIsLoading={setIsLoading} />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <UsersList isLoading={isLoading} />
          </Grid>
        </Grid>
        <Copyright sx={{ pt: 4 }} />
      </Container>

      {modal && modal.type === ContextActionEnum.BULK_EMAIL && (
        <BulkEmailDialog
          selectedUsernames={selectedUsernames}
          setModal={setModal}
          sendBulkEmails={sendEmails}
          total={total}
        />
      )}

      {modal && modal.type === ContextActionEnum.ADD_USER && (
        <EditUserDialog
          setModal={setModal}
          user={selectedUsernames?.length === 1 ? users?.find((u) => u.username === selectedUsernames[0]) : null}
        />
      )}

      {modal && modal.type === ContextActionEnum.DELETE_USER && (
        <DeleteUserDialog
          setModal={setModal}
          users={selectedUsernames}
        />
      )}

      {triggerAction && triggerAction.progress && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          autoHideDuration={6000}
          onClose={sendComplete}
        >
          <Alert
            onClose={sendComplete}
            severity="success"
            sx={{ width: '100%' }}
          >
            {`${triggerAction.type.toString()} completed successfully!`}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default Users;
