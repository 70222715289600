import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { CardActionArea, LinearProgress } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { GlobalContext } from '../../context/GlobalState';

const ProgressCard = () => {
  const { triggerAction } = useContext(GlobalContext);
  const [currentAction, setCurrentAction] = useState<string>('No action running yet, waiting for response...');
  const [currentProgress, setCurrentProgress] = useState<number>(0);
  /**
   * Update each time the global state trigger
   * action progress changes.
   */
  useEffect(() => {
    if (triggerAction && triggerAction.progress) {
      setCurrentAction(triggerAction.type.toString());
      setCurrentProgress(parseInt(triggerAction.progress.toFixed(2), 10));
    }
  }, [triggerAction]);

  return (
    <Card sx={{ width: '100%' }}>
      <CardActionArea>
        <CardContent>
          {/* Show the current action title being performed. */}
          <Typography
            gutterBottom
            variant="h5"
            component="div"
          >
            {currentAction} {`${currentProgress}%` || ''}
          </Typography>

          {/* Show the linear progress of emails being sent. */}
          <LinearProgress
            variant="determinate"
            value={currentProgress}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ProgressCard;
