import { Box, Chip, Typography } from '@mui/material';

import { IFormField } from '../../common/form-generator/interfaces';
import { TemplateMapItem } from '../template-placeholder';

type Props = {
  templateMap: TemplateMapItem;
  textPartReference: any;
  callback: (startPos: number, endPos: number, placeholder: string, fields: IFormField[]) => void;
  setFields: Function;
  fields: IFormField[];
};

const ShowPlaceholders = (props: Props) => {
  // console.log(props.textPartReference);

  const insertPlaceholder = (placeholder: string) => {
    const currentField = props.textPartReference.current;
    // Call the callback function to insert the placeholder in the field value.
    props.callback(currentField.selectionStart, currentField.selectionEnd, placeholder, props.fields);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#dedede',
        p: 2,
      }}
    >
      {/* Title. */}
      <Typography
        sx={{
          fontSize: '16px',
          color: '#1976d2',
          mt: 0.5,
        }}
      >
        Erkannte Vorlage: {props.templateMap?.template}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            color: '#999999',
          }}
        >
          Verfügbare Platzhalter:
        </Typography>
        {/* List of placeholders */}
        <div>
          {props.templateMap?.placeholders.map((placeholder) => (
            <Chip
              key={placeholder}
              label={placeholder}
              variant="outlined"
              onClick={() => insertPlaceholder(placeholder)}
              size="small"
              sx={{ ml: 1 }}
            />
          ))}
        </div>
      </Box>
    </Box>
  );
};

export default ShowPlaceholders;
