import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_URL } from '../../constants/api';
import { fetcher } from '../../utils/fetcher';

const api = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/users`,
    fetchFn: (input, options) => fetcher(input, options, undefined, undefined, true),
  }),
  endpoints: (builder) => ({
    getUsersByEmail: builder.query<any, { email: string; tenant: string }>({
      query: ({ email, tenant }) => ({
        url: `?email=${encodeURIComponent(email)}&tenant=${tenant}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetUsersByEmailQuery } = api;
export default api;
