import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * It sends a POST request to the `/users/{username}/resetPassword` endpoint
 * @param {string} username - The username of the user you want to reset the password for.
 * @returns A promise that resolves to the response from the server.
 */
export const resetPassword = async (username: string, tenant: string | null) => {
  const url = `${API_URL}/users/${username}/resetPassword`;
  const query = { tenant };
  return await fetcher(queryString.stringifyUrl({ url, query }), {
    method: 'POST',
  });
};
