import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';
import { createQuotaData } from '../dialogs/RequestQuotaDialog/RequestQuotaTable';

/**
 * It creates a quota for a vehicle registration
 * @param {createQuotaData}  - vrId - The vehicle registration ID.
 * @returns A promise.
 */
export const createQuota = ({ vrId, uba_year, productOption, tenant }: createQuotaData) => {
  /* Creating a url with the API_URL, vrId, and uba_year. */
  const url = `${API_URL}/vehicle-registrations/${vrId}/quota-sale/${uba_year}`;
  const method = 'POST';
  const query = { tenant };
  return fetcher(queryString.stringifyUrl({ url, query }), {
    method,
    body: JSON.stringify({
      productOption,
    }),
  });
};
