import { createContext, useContext } from 'react';
import { Authenticator, translations } from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify/utils';

import '@aws-amplify/ui-react/styles.css';

I18n.putVocabularies(translations);
I18n.setLanguage('de'); // TODO Sync with i18n lng

type AuthContextData = {
  signOut?: () => void;
};

const AuthContext = createContext<AuthContextData>({});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  return (
    <Authenticator hideSignUp>
      {(auth) => <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>}
    </Authenticator>
  );
}

export function useAuth(): AuthContextData {
  return useContext(AuthContext);
}
