import toast from 'react-hot-toast';
import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * Calls the required endpoint to download the
 * backend generated XML file for the B2B sample
 * invoices.
 */
export const downloadFile = async (
  type: string,
  link: string,
  fileName: string,
  fileDownloadLink: any,
  tenant?: string,
) => {
  // Empty the existing CSV data.
  toast(`Downloading ${type} file. Please wait...`);
  const url = `${API_URL}${link}`;
  const query = { tenant };
  const fullUrl = queryString.stringifyUrl({ url, query });
  await fetcher(fullUrl).then((data: any) => {
    const a = fileDownloadLink.current;
    a.download = fileName;
    a.href = data.link;
    a.target = '_blank';
    a.click();
    a.href = '';
  });
};
