import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';
import { IDenseTableRow } from '../../common/dialog/ResultsTable/ResultsTable';
import { IField, SellQuotasStates } from '../dialogs/SellQuotasDialog/SellQuotasDialog';

interface IPriceData {
  fixedPriceM1: IField;
  fixedPriceM3: IField;
  fixedPriceN1: IField;
  fixedPriceN2: IField;
  fixedPriceN3: IField;
}

/**
 * Fetch the latest quota sale id for the list of
 * provided vehicle ids.
 */
export const markAsSold = (
  currentState: IDenseTableRow[],
  priceData: IPriceData,
  triggerActionCallback: Function,
  setCurrentState: Function,
  stateWhenDone: any,
  ids: string[],
  tenant: string | null,
) => {
  // Prepare the body data.
  const body = priceData && {
    uuids: ids,
    fixed_price_m1: priceData?.fixedPriceM1?.value,
    fixed_price_m3: priceData?.fixedPriceM3?.value,
    fixed_price_n1: priceData?.fixedPriceN1?.value,
    fixed_price_n2: priceData?.fixedPriceN2?.value,
    fixed_price_n3: priceData?.fixedPriceN3?.value,
  };
  // Prepare the endpoint we need to call.
  const url = `${API_URL}/quota-sales/uba_approved/markSold`;
  const query = { tenant };
  return fetcher(queryString.stringifyUrl({ url, query }), {
    method: 'PUT',
    body: JSON.stringify(body),
  }).then(() => {
    triggerActionCallback({
      type: SellQuotasStates[3],
      progress: 100,
    });
    setCurrentState(stateWhenDone);
  });
};
