import { useEffect, useState } from 'react';
import { Card, CardActionArea, CardContent, LinearProgress, Typography } from '@mui/material';

import { ITriggerAction } from '../../../context/GlobalState';
import { ISellTriggerAction } from '../../../quotas-admin/dialogs/SellQuotasDialog/SellQuotasDialog';

export const DialogProgressCard = (props: { trigger: ITriggerAction | ISellTriggerAction }) => {
  const [currentAction, setCurrentAction] = useState<string>('No action running yet, waiting for response...');
  const [currentProgress, setCurrentProgress] = useState<number>(0);
  /**
   * Update each time the global state trigger
   * action progress changes.
   */
  useEffect(() => {
    if (props.trigger && props.trigger.progress) {
      const title = props.trigger.type.toString().length > 1 ? props.trigger.type.toString() : 'Marking quotas as sold';
      setCurrentAction(title);
      setCurrentProgress(parseInt(props.trigger.progress.toFixed(2), 10));
    }
  }, [props.trigger]);

  return (
    <Card sx={{ width: '100%' }}>
      <CardActionArea>
        <CardContent>
          {/* Show the current action title being performed. */}
          <Typography
            gutterBottom
            variant="h6"
            component="div"
          >
            {currentAction} {`${currentProgress}%` || ''}
          </Typography>

          {/* Show the linear progress of emails being sent. */}
          <LinearProgress
            variant="determinate"
            value={currentProgress}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
