import { Card, CardMedia } from '@mui/material';

export default function MediaCard(props: { title?: string; image: string }) {
  return (
    <Card sx={{ maxWidth: 650 }}>
      <CardMedia
        component="img"
        image={props.image}
      />
      {/* <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {props.title}
        </Typography>
      </CardContent> */}
    </Card>
  );
}
