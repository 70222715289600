import { useContext, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Copyright } from '@mui/icons-material';
import { Alert, Box, Container, Grid, Snackbar, Toolbar } from '@mui/material';

import ProgressCard from '../common/cards/ProgressCard';
import FilterMenu from '../common/FilterMenu';
import { vrStatusFilters } from '../common/PredefinedFilters/vehicleFilters';
import { ContextActionEnum, GlobalContext } from '../context/GlobalState';

import DeleteVehiclesDialog from './dialogs/DeleteVehiclesDialog/DeleteVehiclesDialog';
import EditVehicleDialog from './dialogs/EditVehicleDialog/EditVehicleDialog';
import RequestQuotaDialog from './dialogs/RequestQuotaDialog/RequestQuotaDialog';
import VehiclesList from './VehiclesList';

function Vehicles() {
  const [searchParams, _setSearchParams] = useSearchParams();
  /**
   * Get all the required properties from the
   * global context.
   */
  const {
    modal,
    vehicles,
    triggerAction,
    setTriggerAction,
    setModal,
    selectedIds,
    setSelectedIds,
    predefinedFilters,
    setPredefinedFilters,
  } = useContext(GlobalContext);
  // Used to find out if the vehicle details modal should open.
  const hasOneSelected = selectedIds && selectedIds.length === 1;
  const hasNoneSelected = !selectedIds.length;

  /**
   * Monitor if there was a user action that should
   * open the vehicle edit modal.
   */
  const shouldOpenVehicleEditModal = useMemo(() => {
    return (
      (hasOneSelected || hasNoneSelected) &&
      (modal?.type === ContextActionEnum.ADD_VEHICLE ||
        modal?.type === ContextActionEnum.REQUEST_QUOTA_FROM_VEHICLE_FORM)
    );
  }, [selectedIds, modal]);

  // Find the ID of the next vehicle
  const findNextVehicleId = () => {
    if (!hasOneSelected) return null;

    const currentVehicleIndex = vehicles?.findIndex((v) => v?.uuid === selectedIds[0]);
    if (currentVehicleIndex < 0) return null;

    const nextVehicle = vehicles[currentVehicleIndex + 1];
    return nextVehicle?.uuid ?? null;
  };

  /**
   * Handle the completed action of sending emails.
   * We need to empty the selected rows and remove
   * the ProgressCard
   */
  const sendComplete = () => {
    // Close the action progress card.
    setTriggerAction(null);
    // De-select the previously selected rows.
    setSelectedIds([]);
    // Refresh the currently shown list of vehicles.
    setPredefinedFilters([...predefinedFilters]);
  };

  const showRequestQuotaDialog =
    modal?.type &&
    [ContextActionEnum.REQUEST_QUOTA, ContextActionEnum.REQUEST_QUOTA_FROM_VEHICLE_FORM].includes(modal.type);

  const onRequestQuotaDialogClose =
    modal?.type === ContextActionEnum.REQUEST_QUOTA_FROM_VEHICLE_FORM
      ? () => setModal({ type: ContextActionEnum.ADD_VEHICLE })
      : undefined;

  useEffect(() => {
    const editVehicleUuid = searchParams.get('editVehicleUuid');

    if (editVehicleUuid) {
      setSelectedIds([editVehicleUuid]);
      setModal({ type: ContextActionEnum.ADD_VEHICLE });
    }
  }, [vehicles]);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />

      <Container
        sx={{ mt: 4, mb: 4 }}
        maxWidth={false}
      >
        <Grid
          container
          spacing={2}
        >
          {triggerAction && (
            <Grid
              item
              xs={12}
            >
              <ProgressCard />
            </Grid>
          )}

          {/* The sidebar for the filters. */}
          <Grid item>
            <FilterMenu filters={vrStatusFilters} />
          </Grid>

          <Grid
            item
            xs
          >
            <VehiclesList />
          </Grid>
        </Grid>
        <Copyright sx={{ pt: 4 }} />
      </Container>

      {/* The Request Quota for Vehicles Dialog. */}
      {showRequestQuotaDialog && (
        <RequestQuotaDialog
          selectedIds={selectedIds}
          setModal={setModal}
          onClose={onRequestQuotaDialogClose}
        />
      )}

      {modal && modal.type === ContextActionEnum.DELETE_VEHICLES && (
        <DeleteVehiclesDialog
          setModal={setModal}
          vehicles={selectedIds}
        />
      )}

      {/* Add new vehicle dialog. */}
      {shouldOpenVehicleEditModal && (
        <EditVehicleDialog
          setModal={setModal}
          setSelectedIds={setSelectedIds}
          vehicle={selectedIds && selectedIds.length === 1 ? vehicles.find((v) => v.uuid === selectedIds[0]) : null}
          nextVehicleId={findNextVehicleId()}
        />
      )}

      {triggerAction && triggerAction.progress > -1 && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          autoHideDuration={6000}
          onClose={sendComplete}
        >
          <Alert
            onClose={sendComplete}
            severity="success"
            sx={{ width: '100%' }}
          >
            {`${triggerAction.type.toString()} completed successfully!`}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}

export default Vehicles;
