import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';
import { IDenseTableRow } from '../../common/dialog/ResultsTable/ResultsTable';
import { ContextActionEnum } from '../../context/GlobalState';

/**
 * It takes an array of numbers, and returns a promise that resolves to null
 * @param {string[]} ids - string[] - an array of uudis to mark as submitted
 * @returns Nothing
 */
const markAsSubmitted = (ids: (string | undefined)[], tenant: string | null): Promise<any> => {
  if (!ids.length) return new Promise((resolve) => resolve(null));
  const url = `${API_URL}/quota-sales/markSubmitted`;
  const query = { tenant };
  return fetcher(queryString.stringifyUrl({ url, query }), {
    method: 'PUT',
    body: JSON.stringify({ quotaSaleIds: ids }),
  }).then();
};

/**
 * It takes a list of quota sale ids, and marks them as submitted
 * @param {IDenseTableRow[]} currentState - The current state of the table.
 * @param {Function} triggerActionCallback - This is the function that will be called to update the
 * state of the parent component.
 * @param {Function} setCurrentState - This is the function that will be used to set the state of the
 * component.
 * @param {any} stateWhenDone - The state to set when the operation is done.
 */
export const markSubmittedAllQuotaIds = (
  currentState: IDenseTableRow[],
  triggerActionCallback: Function,
  setCurrentState: Function,
  stateWhenDone: any,
  tenant: string | null,
) => {
  if (currentState && currentState.length) {
    (async () => {
      let currentRequest = 1;

      const quotaSaleIds = currentState.map((row) => row.quotaId);
      const resp = await markAsSubmitted(quotaSaleIds, tenant);
      const body = await resp?.data;

      currentState.forEach((row) => {
        try {
          const found = body.find((qs: any) => qs.uuid === row.quotaId)?.uuid;
          if (!found) throw new Error();
          row.approveStatus = 200;
          // Set the trigger action update.
          triggerActionCallback({
            type: ContextActionEnum.SUBMIT_QUOTAS,
            progress: (currentRequest++ * 100) / currentState.length,
          });
        } catch (error) {
          row.approveStatus = 409;
          // Set the trigger action update.
          triggerActionCallback({
            type: ContextActionEnum.SUBMIT_QUOTAS,
            progress: (currentRequest++ * 100) / currentState.length,
          });
        }
      });
      setCurrentState(stateWhenDone);
    })();
  }
};
