import { Navigate, useLocation } from 'react-router-dom';

import { GlobalProvider } from '../components/Admin/context/GlobalState';
import Dashboard from '../components/Admin/Dashboard';
import { AuthProvider } from '../components/auth';
import { TenantProvider } from '../components/tenant';

const AdminRoute = () => {
  const location = useLocation();
  if (location.pathname === '/') {
    return <Navigate to="/users" />;
  }
  return (
    <AuthProvider>
      <TenantProvider>
        <GlobalProvider>
          <Dashboard />
        </GlobalProvider>
      </TenantProvider>
    </AuthProvider>
  );
};

export default AdminRoute;
