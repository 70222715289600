import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_URL } from '../../constants/api';
import { fetcher } from '../../utils/fetcher';

const api = createApi({
  reducerPath: 'tenantConfigurator',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/admin/tenants/config`,
    fetchFn: (input, options) => fetcher(input, options, undefined, undefined, true),
  }),
  endpoints: (builder) => ({
    getTenantConfig: builder.query<any, { tenant: string }>({
      query: ({ tenant }) => {
        return {
          url: `?tenant=${tenant}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useLazyGetTenantConfigQuery } = api;
export default api;
