import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

import { IGenerateInvoices } from './generateInvoices';

/**
 * It sends invoices to customers
 * @param  - `limit` is the number of invoices to send
 * @param  - `skip` is the number of invoices to skip
 */
export const sendInvoices = ({ take, skip, tenant }: IGenerateInvoices) => {
  const url = `${API_URL}/quota-sales/sendInvoice`;
  const query = { tenant, take, skip };
  const fullUrl = queryString.stringifyUrl({ url, query });
  return fetcher(fullUrl, {
    method: 'POST',
  });
};
