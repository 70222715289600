import { useCallback, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { URLSearchParams } from 'url';

type UseSearchParamsWorkaround = () => [URLSearchParams, ReturnType<typeof useSearchParams>[1]];

/**
 * FIXME setSearchParams is not stable between renders, which contradicts the
 * documentation IMO (It's stated it works like useState) and brings some
 * troubles: without this workaround you can't put `setSearchParams` into
 * the dependency array without causing unwanted re-renders. This is
 * a workaround to make it stable.
 *
 * Possible solution is to use another router library.
 */
export const useFixedSearchParams: UseSearchParamsWorkaround = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const persistedSetSearchParams = useRef(setSearchParams);
  useEffect(() => {
    persistedSetSearchParams.current = setSearchParams;
  }, [setSearchParams]);

  const fixedSetSearchParams: typeof setSearchParams = useCallback((searchParams) => {
    persistedSetSearchParams.current(searchParams);
  }, []);
  return [searchParams, fixedSetSearchParams];
};
