import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import { de } from './de';
import { en } from './en';

i18n.use(initReactI18next).init({
  resources: {
    en,
    de,
  },
  lng: 'de',
  fallbackLng: 'en',
  interpolation: {
    // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    escapeValue: false,
  },
});
