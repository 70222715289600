/**
 * Defines the object that helps us identify
 * the corresponding translation key by using
 * the field key.
 */
export interface FieldTitle {
  /**
   * Holds the field key name which
   * we use to identify the field
   * application wide.
   */
  key: string;
  /**
   * Holds the corresponding translation
   * for the associated field key.
   */
  translation: string;
}

/**
 * Contains the translation keys for the
 * form fields.
 */
export const fieldTitles: FieldTitle[] = [
  { key: 'id', translation: 'ID' },
  { key: 'uuid', translation: 'UUID' },
  { key: 'tenant', translation: 'Tenant' },
  { key: 'debtorNumber', translation: 'Debitor' },
  { key: 'isCompany', translation: 'B2B' },
  { key: 'createdAt', translation: 'Anmeldedatum' },
  { key: 'firstName', translation: 'Vorname' },
  { key: 'lastName', translation: 'Nachname' },
  { key: 'gender', translation: 'Geschlecht' },
  { key: 'company', translation: 'Firma' },
  { key: 'email', translation: 'Email' },
  { key: 'phoneNumber', translation: 'Phone Number' },
  { key: 'vehicleRegistrations', translation: 'Fahrzeuge' },
  { key: 'invoices', translation: 'Rechnungen' },
  { key: 'street', translation: 'Straße' },
  { key: 'addressAddOn', translation: 'Adresszusatz' },
  { key: 'zip', translation: 'PLZ' },
  { key: 'city', translation: 'Stadt' },
  { key: 'country', translation: 'Land' },
  { key: 'branch', translation: 'Niederlassung' },
  { key: 'accountOwner', translation: 'Kontoinhaber' },
  { key: 'iban', translation: 'IBAN' },
  { key: 'vatReclaimEntitled', translation: 'Vorsteuerabzugsberechtigt' },
  { key: 'tenant', translation: 'Tenant' },
  { key: 'taxNumber', translation: 'Steuernummer' },
  { key: 'vatId', translation: 'USt-ID' },
  { key: 'uniqueId', translation: 'Unique ID' },
  { key: 'acceptedTAndC', translation: 'AGB' },
  { key: 'marketingOptIn', translation: 'Marketing' },
  { key: 'priceUpdateOptIn', translation: 'Preisupdate' },
  { key: 'ownerID', translation: 'Owner ID' },
  { key: 'ownerUsername', translation: 'Owner Username' },
  { key: 'creationDate', translation: 'Created On' },
  { key: 'vehicleRegistrationStatus', translation: 'Status' },
  { key: 'quotaSaleStatus', translation: 'Quota Status' },
  { key: 'carbonifyAuditor', translation: 'Prüfer' },
  { key: 'registrationDate', translation: 'Zugelassen (Feld I)' },
  { key: 'licensePlate', translation: 'Kennzeichen' },
  { key: 'carId', translation: 'FIN' },
  { key: 'mainInspectionDate', translation: 'HU Datum' },
  { key: 'registeredAt', translation: 'Zulassungsdatum' },
  { key: 'registeredUntil', translation: 'Abmeldedatum' },
  { key: 'firstRegisteredAt', translation: 'Erstzulassung' },
  { key: 'manufacturerCode', translation: 'Hersteller Code' },
  { key: 'manufacturer', translation: 'Hersteller' },
  { key: 'modelCode', translation: 'Modell Code' },
  { key: 'model', translation: 'Modell' },
  { key: 'vehicleClass', translation: 'Fahrzeugklasse' },
  { key: 'checkBev', translation: 'Elektro' },
  { key: 'auditStatus', translation: 'Status (alt)' },
  { key: 'remoteEmail', translation: 'Email ' },
  { key: 'uba2022', translation: '2022' },
  { key: 'uba2023', translation: '2023' },
  { key: 'uba2024', translation: '2024' },
  { key: 'acceptedTAndC2023', translation: 'AGB 2023' },
  { key: 'isNoRightOfRevocation', translation: 'No Right Of Revocation' },
  { key: 'raffleCheckbox', translation: 'Raffle' },
  { key: 'regFlowComplete', translation: 'Registration Flow' },
  { key: 'remarketing2023', translation: 'Remarketing 2023' },
  { key: 'membershipCode', translation: 'Vertragsnummer' },
  { key: 'smatricsCardId', translation: 'Smatrics KartenNr' },
  { key: 'welcomeBonus', translation: 'Welcome Bonus' },
  { key: 'productPriceBonusValue', translation: 'Welcome Bonus Value' },
  { key: 'internalNote', translation: 'Internal Note' },

  // Template editor.
  { key: 'TemplateName', translation: 'Vorlagenname' },
  { key: 'SubjectPart', translation: 'Fachteil' },
  { key: 'TextPart', translation: 'Textteil' },
  { key: 'HtmlPart', translation: 'Html' },
  { key: 'Tenant', translation: 'Tenant' },
];
