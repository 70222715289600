import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';
import { IDenseTableRow } from '../../common/dialog/ResultsTable/ResultsTable';
import { IWizardStep } from '../../common/wizard-steps';
import { ContextActionEnum } from '../../context/GlobalState';

/**
 * It fetches the latest quota sales for a given array of quota registration ids
 * @param {string[]} ids - string[]
 * @returns An array of objects.
 */
const fetchLatestQuotaIDs = (ids: string[], tenant: string | null): Promise<any> => {
  if (!ids.length) return new Promise((resolve) => resolve(null));
  // Add the required query params.
  const params = {
    filter: JSON.stringify({ uuid: { in: ids } }),
  };
  // Stringify the query params.
  const stringParams = `${queryString.stringify(params)}`;
  // Build up the complete url
  const url = `${API_URL}/quota-sales?${stringParams}`;
  const query = { tenant };
  return fetcher(queryString.stringifyUrl({ url, query }), {
    method: 'GET',
  }).then();
};

/**
 * Fetch the latest quota sale id for the list of
 * provided quota ids.
 */
export const fetchLatestQuotaIds = (
  currentState: IDenseTableRow[],
  triggerActionCallback: Function,
  setCurrentState: Function,
  tenant: string | null,
  stateWhenDone?: IWizardStep,
) => {
  (async () => {
    let currentRequest = 1;
    const quotasIds: string[] = [];
    currentState.forEach((row) => {
      if (row.quotaId) {
        quotasIds.push(row.quotaId);
      }
    });
    const response = await fetchLatestQuotaIDs(quotasIds, tenant);
    const body = await response?.data;

    currentState.forEach((row) => {
      try {
        const found = body.find((qs: any) => qs.uuid === row.quotaId)?.uuid;
        if (!found) throw new Error();
        row.fetchStatus = 200;
        // Set the trigger action update.
        triggerActionCallback({
          type: ContextActionEnum.BULK_FETCH_LATEST_QUOTAS,
          progress: (currentRequest++ * 100) / currentState.length,
        });
      } catch (error) {
        row.fetchStatus = 404;
        // Set the trigger action update.
        triggerActionCallback({
          type: ContextActionEnum.BULK_FETCH_LATEST_QUOTAS,
          progress: (currentRequest++ * 100) / currentState.length,
        });
      }
    });
    if (typeof stateWhenDone !== 'undefined') {
      setCurrentState(stateWhenDone);
    }
  })();
};
