import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * It sends an email to a list of users
 * @param {string[]} usernames - An array of usernames to send the email to.
 * @param {string} template - The name of the template you want to send.
 */
export const sendAllEmails = async (usernames: string[], template: string, tenant: string | null): Promise<any> => {
  const body = {
    usernames,
    template,
  };
  const url = `${API_URL}/users/bulkmail`;
  const query = { tenant };
  return await fetcher(queryString.stringifyUrl({ url, query }), {
    method: 'POST',
    body: JSON.stringify(body),
  });
};
