import { API_URL } from '../../constants/api';
import { fetcher, StatusError } from '../fetcher';

export const getTenants = async (): Promise<string[]> => {
  const url = `${API_URL}/tenants`;
  const response = await fetcher(`${url}`, { method: 'GET' }, undefined, { skipReadOfBody: true });
  if (!response.ok) throw new StatusError(response.status, response.statusText);

  const tenants = await response.json();

  if (!Array.isArray(tenants) || !tenants.every((tenant) => typeof tenant === 'string')) {
    throw new Error('Invalid tenant list');
  }

  //sorting tenants alphabetically
  const sortedTenants: string[] = tenants.sort();
  return sortedTenants;
};
