export interface IFieldStateCondition {
  /**
   * The field name on which it
   * depends on.
   */
  key: string;
  /**
   * The field value on which
   * it depends on.
   */
  value?: any;
  /**
   * The validator on which
   * it depends on.
   */
  validator?: (value: any) => string;
}

export interface IButtonState {
  /**
   * The name of the button
   * so we can identify it
   */
  key: string;
  /**
   * The label to be used when
   * rendering the button.
   */
  label: string;
  /**
   * Indicates if the button
   * should be clickable or not.
   */
  active: boolean;
  /**
   * List of conditions for the
   * current action button.
   */
  conditions: IFieldStateCondition[];
  /**
   * The callback method that should
   * be executed when the action button
   * is pressed.
   */
  onClick: Function;
}

/**
 * Enumerates the types of form configuration
 * that are available through the FormHelper.
 */
export enum FormConfigurationTypeEnum {
  USER,
  VEHICLE,
  TEMPLATE,
  INVOICE_TEMPLATE,
  QUOTA_SALE,
}

/**
 * Defines the active tab
 * object used between this
 * component and the FormGenerator.
 */
export interface IActiveTab {
  current: number;
  total: number;
}

/**
 * Defines the fields format of
 * the editvehicle form.
 */
export interface IFormField {
  id: number;
  key: string;
  name: string;
  value: string | number | Date | boolean;
  /**
   * Contains the initial value of the
   * current field.
   */
  initialValue?: string | number | Date | boolean;
  /**
   * Indicates in which mode should the
   * field be shown. If this property is empty
   * then it will be always shown.
   */
  mode?: string;
  file?: File;
  /**
   * Not used for now!
   * Replaced by a validator.
   */
  required?: boolean;
  /**
   * Indicates if the field should be hidden
   * on the form but the object will still
   * contain it. So on submit, the field will
   * be there.
   */
  hidden?: boolean;
  /**
   * A list of conditions which indicate
   * when the current field should be
   * displayed inside the form or not.
   *
   * Here you can setup dependencies with
   * other fields and states.
   */
  hiddenWhen?: IFieldStateCondition[];
  /**
   * Indicates how many columns should the current
   * field take up (min 1 max 3).
   */
  columns?: number;
  /**
   * Indicates if the field should be removed on
   * 'create' or 'update'. Leave empty for no
   * filtering.
   */
  filterOn?: string;
  /**
   * Indicates that the current field should be
   * skipped from the submit form if the value
   * didn't change.
   */
  saveOnlyIfChanged?: boolean;
  /**
   * Contains the list of validators that are
   * callback functions. They will be executed
   * when form is validated or when field value
   * changes.
   */
  validators?: ((value: string, length?: number) => string)[];
  /**
   * Indicates the type of the current field.
   * This will instruct the form generator to
   * use a certain component when rendering it.
   */
  type: string;
  options?: IFieldOption[];
  disabled?: boolean;
  validationErrorText?: string;
  color?: 'success' | 'error' | 'primary' | 'secondary' | 'info' | 'warning';
  message?: string;
  label?: string;
  checkIsActive?: (arg0: IFormField[]) => boolean;
  inactivePlaceholderText?: string;
  disabledPlaceholderText?: string;
}

/**
 * Defines the selectable option for
 * a dropdown field.
 */
export interface IFieldOption {
  name: string;
  value: string | number | null;
}

/**
 * Defines the requiredField type
 * which is used to configure the
 * current form.
 */
interface IRequiredField {
  id: number;
  fieldName: string;
  type: string;
  required?: boolean;
  disabled?: boolean;
  options?: IFieldOption[];
  url?: string;
  /**
   * Remove this field from the list of
   * fields before being rendered by the
   * form generator.
   */
  filterOn?: string;
  /**
   * Indicates that the current field should be
   * skipped from the submit form if the value
   * didn't change.
   */
  saveOnlyIfChanged?: boolean;
  /**
   * Skip this field when parsing the list
   * of fields when they are prepared for
   * submitting.
   */
  skipSave?: string;
  /**
   * Indicates the number of columns the field
   * will occupy. Maximum is 3!!!
   */
  columns?: number;
  hidden?: boolean;
  /**
   * A list of conditions which indicate
   * when the current field should be
   * displayed inside the form or not.
   *
   * Here you can setup dependencies with
   * other fields and states.
   */
  hiddenWhen?: IFieldStateCondition[];
  /**
   * Indicates if the field should
   * be shown only in a specific mode.
   * (create or edit).
   */
  mode?: string;
  /**
   * Disable this field only if loaded
   * in a specified mode.
   */
  disableIfMode?: string;
  message?: string;
  label?: string;
  checkIsActive?: (arg0: IFormField[]) => boolean;
  inactivePlaceholderText?: string;
  disabledPlaceholderText?: string;
  getAppendText?: (arg0: IFormField) => string;
}

/**
 * Defines the current group configuration.
 * A group basically represents a subset of
 * fields in the current form. When multiple
 * groups are configured then the app will try
 * to separate these groups into entities that
 * are in regard to a separate type of
 * information.
 */
interface IGroupConfig {
  /**
   * The index number used for the react
   * reconciliation algorhitm.
   */
  index: number;
  /**
   * Enumerates the fields that we
   * wish to display in this group.
   */
  fields: string[];
}

/**
 * Defines the tab configurations for
 * a form.
 */
interface ITabConfig {
  /**
   * The index number used for the
   * react reconciliation algorhitm.
   */
  index: number;
  /**
   * The name that will be displayed
   * for the current tab.
   */
  name: string;
  /**
   * Contains the group configurations.
   * Each group contains multiple rows
   * of fields.
   */
  groups: IGroupConfig[];
}

/**
 * Defines the configuration object for
 * a form.
 */
export interface IFormConfig {
  /**
   * The list of tabs that will be
   * rendered in the current form.
   */
  tabs: ITabConfig[];
  /**
   * The individual configuration of
   * each field inside the form.
   */
  fieldsConfig: IRequiredField[];
}
