import queryString from 'query-string';

import { API_URL } from '../../../../../constants/api';
import { fetcher } from '../../../../../utils/fetcher';

/**
 * Delete the quota sale with the
 * provided uuid.
 *
 * @param uuid string
 * @param tenant string
 * @returns Promise<any>
 */
export const deleteQuotaSale = (uuid: string, tenant: string | null): Promise<any> => {
  if (!uuid) return new Promise((resolve, reject) => reject(null));
  const url = `${API_URL}/quota-sales/${uuid}`;
  const query = { tenant };
  return fetcher(queryString.stringifyUrl({ url, query }), {
    method: 'DELETE',
  }).then();
};
