import createClient from 'openapi-fetch';

import { API_URL } from '../../constants/api';
import type { paths as VehiclePaths } from '../../types/openapi/vehicle';

export const vehicleClient = createClient<VehiclePaths>({
  baseUrl: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
