import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';

import { ContextActionEnum, ContextScopeEnum } from '../../context/GlobalState';

/**
 * "If the context is invoicing and no invoices are selected, show a button to download bank transfer
 * exports."
 *
 * The function is a React component, which is a function that returns a React element. The React
 * element is a button that calls the setModal function when clicked
 * @param props - { setModal: Function, context: ContextScopeEnum, selectedIds:
 * number[] }
 * @returns A button that will download bank transfer exports.
 */
const DownloadBankTransferExportsAction = (props: {
  setModal: Function;
  context: ContextScopeEnum;
  selectedIds: string[];
}) => {
  const tab = props.context === ContextScopeEnum.INVOICING;
  const hasNoneSelected = !props.selectedIds.length;
  const show = tab && hasNoneSelected;

  if (show) {
    return (
      <Button
        color="inherit"
        onClick={() => props.setModal({ type: ContextActionEnum.BANK_TRANSFER_EXPORTS })}
      >
        <DownloadIcon />
        &nbsp;Bank Transfer Exports
      </Button>
    );
  }
  return <></>;
};

export default DownloadBankTransferExportsAction;
