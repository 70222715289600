import { FC, useEffect, useState } from 'react';
import { Alert, Box, LinearProgress, ThemeProvider, useTheme } from '@mui/material';

import { API_URL } from '../../../constants/api';
import { fetcher } from '../../../utils/fetcher';
import { PdfPreview } from '../pdf-preview';

import { IFormField } from './form-generator/interfaces';
import { FileDropzone } from './FileDropzone';

const style = {
  width: 780,
  height: 350,
  display: 'flex',
  overflow: 'hidden',
  justifyContent: 'space-between',
  alignItems: 'center',
};

interface ECarsImage {
  file: File;
  key: string;
  uuid: string;
}

interface IVehicleDropzonesProps {
  name: string;
  text: string;
  field: IFormField;
  onChange: (field: IFormField, event: any) => void;
}

/**
 * Used for uploading the front and back images
 * of a vehicle.
 */
export default function VehicleImageDropzone(props: IVehicleDropzonesProps) {
  const theme = useTheme();
  /**
   * Holds the 2 temporary S3 bucket urls
   * received from the endpoint.
   */
  const [tempURLs, setTempURLs] = useState<any>(null);
  /**
   * Process:
   * 1. Fetch the upload URLs for the front and back images.
   * 2. User uploads front or back image file.
   * 3. We trigger the upload of that file to the already
   * fetched specific URL.
   * 4.
   */
  const [image, setImage] = useState<ECarsImage | null>(null);
  // Holds the temporary upload urls.
  // Manually indicate that there is a request pending.
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * On initialisation, fetch the temp upload URL.
   */
  useEffect(() => {
    setLoading(true);
    getUploadURLs().then((response) => {
      setTempURLs(response);
      setLoading(false);
    });
  }, []);

  /**
   * Get the upload URLs we will use as temporary S3 bucket
   * link to upload both images.
   */
  const getUploadURLs = async (): Promise<any> => {
    return await fetcher(`${API_URL}/vehicle-registrations/signed-urls`);
  };

  /**
   * Save the provided file on the current vehicle object.
   *
   * @param file File
   * @returns void
   */
  const setFile = async (file: File, isBackImage?: boolean) => {
    const name = !isBackImage ? 'front' : 'back';
    const parsedUploadUrl = new URL(isBackImage ? tempURLs.backUrl : tempURLs.frontUrl);
    const key = decodeURIComponent(parsedUploadUrl.pathname.slice(1));
    /**
     * Set the image object once it was
     * attached.
     */
    setImage({ file, key: `${name}side`, uuid: tempURLs.uuid });
    /**
     * Figure out which field and which URL do we need
     * to use depending on the isBackImage param.
     */
    const url = !isBackImage ? tempURLs.frontUrl : tempURLs.backUrl;

    try {
      // Try to upload a file to an url
      await fetcher(
        url,
        {
          method: 'PUT',
          body: file,
        },
        {
          Key: key,
        },
      ).then((data) => {
        props.onChange(props.field, { target: { value: key } });
        return data;
      });
    } catch (err) {
      // Catch http error
      // console.log({ err });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {/* Show loading indicator. */}
      {loading && <LinearProgress />}
      <Box sx={style}>
        {/* When the image was not uploaded yet. */}
        {!loading && !props.field.value && (
          <FileDropzone
            name={props.name}
            text={props.text}
            preview={image ? URL.createObjectURL(image.file) : ''}
            setFile={(file: File) => setFile(file, props.name.includes('back'))}
          />
        )}

        {/* When image was ALREADY uploaded. */}
        {!loading && !!props.field.value && <VehicleFilePreview file={image?.file} />}
      </Box>
    </ThemeProvider>
  );
}

const VehicleFilePreview: FC<{ file?: File }> = ({ file }) => {
  const isPdfFile = file?.type === 'application/pdf';

  return isPdfFile ? (
    <PdfPreview file={file} />
  ) : (
    <Alert
      severity="success"
      sx={{ width: '100%' }}
    >
      Bild bereits hochgeladen!
    </Alert>
  );
};
