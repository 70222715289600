import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import { Button } from '@mui/material';

import { IFilterItem } from '../../common/PredefinedFilters';
import { ContextActionEnum, ContextScopeEnum } from '../../context/GlobalState';

/**
 * Check if the marketable filter has been selected
 * and we have a few selections present.
 *
 * @param predefinedFilters IFilterItem[]
 * @param selectedIds string[]
 * @returns boolean
 */
const AddVehicleAction = (props: {
  predefinedFilters: IFilterItem[];
  selectedIds: string[];
  setModal: Function;
  context: ContextScopeEnum;
}) => {
  const hasOneOrNoSelection = (props.selectedIds && props.selectedIds.length === 1) || !props.selectedIds.length;
  const hasVehicleContext = props.context === ContextScopeEnum.VEHICLES;
  const isSelection = props.selectedIds && props.selectedIds.length;
  const show = hasOneOrNoSelection && hasVehicleContext;
  const isEdit = isSelection;

  if (show) {
    return (
      <Button
        color="inherit"
        onClick={() => props.setModal({ type: ContextActionEnum.ADD_VEHICLE })}
      >
        <ElectricCarIcon />
        &nbsp;{isEdit ? 'Edit vehicle' : 'New Vehicle'}
      </Button>
    );
  }
  return <></>;
};

export default AddVehicleAction;
