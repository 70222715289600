import * as React from 'react';
import { useEffect, useState } from 'react';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Paper } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { blue } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { templateNames } from '../../../../utils/api/templateNames';
import { useTenants } from '../../../tenant';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface IBulkEmailDialogProps {
  selectedUsernames: string[];
  setModal: Function;
  sendBulkEmails: Function;
  total: number;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2 }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function BulkEmailDialog(props: IBulkEmailDialogProps) {
  // Set the template loading state.
  const [loading, setLoading] = useState<boolean>(false);
  // Set the list of templates that have been remotely fetched.
  const [templates, setTemplates] = useState<any[]>([]);
  // Holds the selected template name.
  // We use this to highlight the user selection.
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');

  const { currentTenant } = useTenants();

  useEffect(() => {
    if (currentTenant) {
      setLoading(true);
      templateNames(currentTenant)
        .then((result) => {
          setTemplates([...result.templateNames]);
          setLoading(false);
        })
        .catch((error) => console.error(error));
    }
  }, [currentTenant]);

  /**
   * Handle the selection of a template.
   */
  const handleSelect = (templateName: string) => {
    setSelectedTemplate(templateName);
    // props.sendBulkEmails(props.selectedUsernames, templateName);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={() => props.setModal(null)}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => props.setModal(null)}
        >
          Send bulk email action.
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {/* General action description. */}
          <Typography>
            You are about to send bulk email to {props.selectedUsernames.length} selected{' '}
            {props.selectedUsernames.length > 1 ? 'users' : 'user'} out of a total of {props.total}.
          </Typography>

          {/* Indication about increasing bulk emails number. */}
          {/* <Typography>
            Please adjust the slider if you want to change the number of bulk emails you want to send.
          </Typography> */}

          {/* Selection slider. */}
          {/* <Slider
            aria-label="Temperature"
            size='medium'
            defaultValue={props.selectedUsernames.length}
            valueLabelDisplay="auto"
            step={100}
            onChange={handleSelectionChange}
            marks
            min={10}
            max={props.total}
          /> */}

          {/* Select template description. */}
          <Typography gutterBottom>
            Please chose the template you wish to use for sending out the bulk emails.
          </Typography>

          <Box
            display="flex"
            justifyContent=""
          >
            <Paper style={{ width: '100%', maxHeight: 300, overflow: 'auto' }}>
              {/* Show the list of templates if loaded. */}
              {!loading && templates && templates.length > 0 && (
                <List sx={{ pt: 1 }}>
                  {templates.map((template: any) => (
                    <ListItem
                      key={template}
                      button
                      selected={selectedTemplate === template}
                      onClick={() => handleSelect(template)}
                    >
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                          <ChromeReaderModeIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={template} />
                    </ListItem>
                  ))}
                </List>
              )}

              {/* Show progress indicator if templates still loading. */}
              {loading && <CircularProgress />}
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            disabled={!selectedTemplate}
            onClick={() => props.sendBulkEmails(props.selectedUsernames, selectedTemplate)}
          >
            Confirm
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
