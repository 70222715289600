import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';
import { IPagination } from '../../context/GlobalState';

/**
 * Make an API request using the provided url.
 */
export const getUploadsPromise = (
  sort: string[],
  pagination: IPagination,
  predefinedFilters: any[],
  tenant: string,
): Promise<any> => {
  // Calculate the range.
  const range = [pagination.range[0], pagination.range[1]];
  // Add the required query params.
  const params = {
    filter: JSON.stringify(predefinedFilters?.length ? predefinedFilters[0].filter : []),
    range: JSON.stringify(range),
    sort: JSON.stringify(sort),
    tenant,
  };
  // Stringify the query params.
  const stringParams = `${queryString.stringify(params)}`;
  // Build up the complete url
  const url = `${API_URL}/pdf-uploads?${stringParams}`;

  return fetcher(url)
    .then((data) => data)
    .catch((error) => {
      return error;
    });
};
