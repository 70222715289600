import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Button, TextField } from '@mui/material';

// import { useLazyGetInvoicesByEmailQuery } from '../../../services/invoices';
import { useLazyGetUsersByEmailQuery } from '../../../services/users';
import api from '../../../services/users/api';
import { useLazyGetVehiclesByUserIdQuery } from '../../../services/vehicles';
import { useTenants } from '../../tenant';
import { ContextActionEnum, GlobalContext } from '../context/GlobalState';
import EditUserDialog from '../users-admin/dialogs/EditUserDialog/EditUserDialog';

import { UserDetailsTable } from './UserDetailsTable';
// import UserInvoicesDetails from './UserInvoicesDetails';
import UserVehicleRegistrations from './UserVehiclesDetails';

export const UserInfo = () => {
  const { modal, setModal } = useContext(GlobalContext);

  const { currentTenant } = useTenants();
  const [userEmailSearchInput, setUserEmailSearchInput] = useState('');
  const [isDataVisible, setIsDataVisible] = useState(false);
  const dispatch = useDispatch();

  const [getUsersByEmail, { data: usersData, isLoading: usersIsLoading, error: usersError }] =
    useLazyGetUsersByEmailQuery();
  const [getVehiclesByUserId, { data: vehiclesData, isLoading: vehiclesIsLoading, error: vehiclesError }] =
    useLazyGetVehiclesByUserIdQuery();
  // const [getInvoicesByEmailQuery, { data: invoicesData, isLoading: invoicesIsLoading, error: invoicesError }] =
  //   useLazyGetInvoicesByEmailQuery();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserEmailSearchInput(event.target.value);
  };

  const handleSearchClick = useCallback(() => {
    getUsersByEmail({ email: userEmailSearchInput, tenant: currentTenant });
  }, [currentTenant, getUsersByEmail, userEmailSearchInput]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleSearchClick();
      }
    },
    [handleSearchClick],
  );

  const handleClearClick = () => {
    setUserEmailSearchInput('');
    setIsDataVisible(false);
  };

  const handleOnUserEdit = (newUserData: any) => {
    setModal(null);

    const updateQuery: any = api.util.updateQueryData(
      'getUsersByEmail',
      { email: userEmailSearchInput, tenant: currentTenant },
      (draft) => {
        const user = draft.find((user: any) => user.username === newUserData.username);
        if (user) {
          Object.assign(user, newUserData);
        }
      },
    );

    dispatch(updateQuery);
  };

  useEffect(() => {
    setIsDataVisible(true);
    if (usersData && usersData.length > 0) {
      getVehiclesByUserId({ userId: usersData[0].username, tenant: currentTenant });
      // getInvoicesByEmailQuery({ email: usersData[0].username, tenant: currentTenant });
    }
  }, [currentTenant, getVehiclesByUserId, usersData]);

  return (
    <Wrapper>
      <h1>User Details</h1>
      <SearchContainer>
        <SearchField
          id="emailSearch"
          value={userEmailSearchInput}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        <Button
          id="searchButton"
          type="button"
          variant="contained"
          onClick={handleSearchClick}
        >
          Search
        </Button>
        <Button
          variant="outlined"
          id="clearSearchButton"
          type="button"
          onClick={handleClearClick}
        >
          Clear
        </Button>
      </SearchContainer>
      {isDataVisible && usersIsLoading && <div>Loading...</div>}
      {isDataVisible && usersError && <div>Error fetching users 😭</div>}
      {isDataVisible && usersData && usersData.length > 0 && (
        <DataContainer>
          <MasterDataContainer>
            <SectionTitle>
              Master Data <Button onClick={() => setModal({ type: ContextActionEnum.ADD_USER })}>Edit User</Button>
            </SectionTitle>
            <UserDetailsTable userData={usersData[0]} />
          </MasterDataContainer>

          {modal && modal.type === ContextActionEnum.ADD_USER && (
            <EditUserDialog
              setModal={setModal}
              user={usersData[0]}
              onSubmit={handleOnUserEdit}
            />
          )}

          <VehiclesContainer>
            <div>
              <SectionTitle>Vehicles</SectionTitle>
              {vehiclesData && vehiclesData.length > 0 && (
                <Counter>({vehiclesData[0].vehicleRegistrations.length})</Counter>
              )}
            </div>
            {vehiclesIsLoading && <div>Loading...</div>}
            {vehiclesError && <div>Error fetching vehicles 😭</div>}
            {vehiclesData && vehiclesData.length > 0 && (
              <UserVehicleRegistrations vehicleRegistrations={vehiclesData[0].vehicleRegistrations} />
            )}
          </VehiclesContainer>

          {/* <InvoicesContainer>
            <SectionTitle>Invoices</SectionTitle>
            {invoicesIsLoading && <div>Loading...</div>}
            {invoicesError && <div>Error fetching invoices 😭</div>}
            {invoicesData?.invoices && invoicesData.invoices.length > 0 && (
              <UserInvoicesDetails invoices={invoicesData.invoices} />
            )}
          </InvoicesContainer> */}
        </DataContainer>
      )}
      {isDataVisible && (!usersData || usersData.length) === 0 && <div>No user found</div>}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  padding: 0 20px 20px 20px;
  margin-top: 60px;
  width: 100%;
`;
const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const SearchField = styled(TextField)`
  min-width: 400px;
`;
const DataContainer = styled.div`
  width: 100%;
`;
const MasterDataContainer = styled.div``;
const VehiclesContainer = styled.div``;

// TODO: Remove this after invoices endpoint is implemented
// const InvoicesContainer = styled.div`
//   display: none;
// `;
const SectionTitle = styled.h2`
  display: inline-block;
  margin-bottom: 5px;
  margin-top: 40px;
`;

const Counter = styled.span`
  position: relative;
  font-size: small;
  margin-left: 5px;
  top: -2px;
  display: inline-block;
`;
