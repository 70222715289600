import { useContext, useEffect, useState } from 'react';
import { Grid, LinearProgress, Stack } from '@mui/material';
import { DataGridPro, GridOverlay } from '@mui/x-data-grid-pro';

import JumpTo from '../common/JumpTo';
import { GlobalContext } from '../context/GlobalState';

import { Columns } from './Columns';

/**
 * Custom loading overlay to indicate to the user that
 * requested data is being loaded from the backend.
 */
function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

export default function VehiclesList() {
  const { vehicles, loading, pagination, setPagination, total, selectedIds, setSelectedIds } =
    useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rows, setRows] = useState<any[]>([]);

  /**
   * Prepare the vehicle registrations table.
   */
  useEffect(() => {
    setRows(vehicles && vehicles.length ? vehicles.map((v) => v) : []);
  }, [vehicles]);

  const getItemsRange = (currentPageNumber: number, currentPageSize: number): void => {
    const totalItemsCount = total;
    const numberOfItemsPerPage = currentPageSize;
    const page = currentPageNumber;

    // page: 0 => 10 start: 0 end: 9
    // page: 1 => 10 start: 11 end: 20
    const start = page * numberOfItemsPerPage;
    // start = start < 0 ? 0 : start;
    const end = Math.min(start + numberOfItemsPerPage - 1, totalItemsCount);
    /**
     * Set the changed pagination properties on the
     * global context.
     */
    setPagination({ range: [start, end], pageSize: currentPageSize });
    // Set the current page in the component state.
    setCurrentPage(page);
  };

  return (
    <Grid container>
      <Grid
        item
        md={12}
      >
        <div style={{ height: pagination.pageSize > 10 ? '85vh' : 630, width: '100%' }}>
          <DataGridPro
            components={{
              LoadingOverlay: CustomLoadingOverlay,
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  No rows in DataGrid
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  There are no results to display
                </Stack>
              ),
            }}
            sx={{ backgroundColor: 'white' }}
            rows={rows}
            getRowId={(row) => row.uuid}
            columns={Columns}
            loading={loading}
            paginationMode="server"
            page={currentPage}
            columnBuffer={Columns?.length + 1} // important for testing
            pageSize={pagination.pageSize}
            rowsPerPageOptions={[100, 500, 1000, 2000]}
            onPageChange={(page) => !loading && getItemsRange(page, pagination.pageSize)}
            onPageSizeChange={(newPageSize) => !loading && getItemsRange(currentPage, newPageSize)}
            pagination={true}
            rowCount={total}
            onSelectionModelChange={(selectionModel: any) => setSelectedIds(selectionModel)}
            selectionModel={selectedIds}
            checkboxSelection
          />
        </div>
        {/* A custom component that allows admins to jump to a specific page. */}
        <JumpTo
          currentPage={currentPage}
          jump={(page: number) => !loading && getItemsRange(page, pagination.pageSize)}
        />
      </Grid>
    </Grid>
  );
}
