import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';

import { BootstrapDialogTitle } from './BootstrapDialog';

interface Props {
  title: string;
  content: string;
  confirmCallback: any;
  cancelCallback: any;
}

const styles = () => {
  return {
    deleteButton: {
      backgroundColor: '#fa5b53',
      color: '#ffffff',
      ':hover': {
        backgroundColor: '#C02119',
        color: '#ffffff',
      },
    },
  };
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    maxWidth: '30vw',
    maxHeight: '30vh',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const DeleteConfirmationDialog: React.FC<Props> = ({ title, content, confirmCallback, cancelCallback }) => {
  return (
    <div>
      <BootstrapDialog
        onClose={() => cancelCallback()}
        aria-labelledby="customized-dialog-title"
        maxWidth="md"
        open={true}
      >
        {/* The title of the current dialog */}
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => cancelCallback()}
        >
          {title.toUpperCase()}
        </BootstrapDialogTitle>

        {/* The content of the confirmation modal. */}
        <DialogContent
          dividers
          sx={{ height: 600 }}
        >
          {content}
        </DialogContent>

        {/* The footer containing the actions available in this dialog. */}
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* The cancel button. */}
          <Button onClick={() => cancelCallback()}>Abbrechen</Button>

          {/* The SAVE button */}
          <Button
            sx={styles().deleteButton}
            onClick={() => confirmCallback()}
          >
            Bestätigen
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default DeleteConfirmationDialog;
