import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Button, ButtonGroup } from '@mui/material';

interface PreviewVehicleIdsProps {
  vehicleIds: Map<number, string>;
  variant?: 'text' | 'outlined' | 'contained';
  limit?: number;
  customElement?: EmotionJSX.Element;
  handleClick: any;
}

/**
 * It renders a list of vehicle ids, and when one is clicked, it opens a modal to edit the vehicle
 * @param {PreviewVehicleIdsProps}  - `vehicleIds` - An array of vehicle IDs to display.
 */
const PreviewVehicleIds = ({ vehicleIds, variant, limit, customElement, handleClick }: PreviewVehicleIdsProps) => {
  return (
    <>
      <ButtonGroup
        variant={variant ?? 'text'}
        size="small"
      >
        {Array.from(vehicleIds.entries())
          .sort(([idA], [idB]) => idA - idB)
          .slice(0, limit ?? vehicleIds.size)
          .map(([id, uuid]) => (
            <Button
              key={uuid}
              onClick={() => handleClick(uuid)}
            >
              {id}
            </Button>
          ))}
        {customElement}
      </ButtonGroup>
    </>
  );
};

export default PreviewVehicleIds;
