import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

import { IVehicle } from './dto';

/**
 * Save the provided vehicle object.
 *
 * @param vehicle IVehicleRegistrationRow
 */
export const saveVehicle = (vehicle: IVehicle, tenant: string | null): Promise<any> => {
  /**
   * Decide if we are dealing with a new or
   * existing vehicle. The request props changed
   * based on that.
   */
  const id = vehicle.uuid ? vehicle.uuid : '';
  /* It's creating an array of years that the vehicle should be marketed for. */
  const uba_years = [];
  if (vehicle.uba2022) uba_years.push(2022);
  if (vehicle.uba2023) uba_years.push(2023);
  if (vehicle.uba2024) uba_years.push(2024);

  /* delete checkbox values. */
  delete vehicle.uba2022;
  delete vehicle.uba2023;
  delete vehicle.uba2024;
  delete vehicle.welcomeBonus;
  // Add the sub params if it's a POST.
  const url = `${API_URL}/vehicle-registrations/${id}`;
  const method = vehicle.id ? 'PUT' : 'POST';

  const productPriceBonusValue =
    vehicle.productPriceBonusValue && typeof vehicle.productPriceBonusValue === 'string'
      ? parseInt(vehicle.productPriceBonusValue, 10)
      : undefined;
  /**
   * Send the request to the provided endpoint
   * and check if the response was successful
   * or not.
   */
  const query = { tenant: vehicle.tenant ?? tenant };
  return fetcher(queryString.stringifyUrl({ url, query }), {
    method,
    body: JSON.stringify({ ...vehicle, uba_years, productPriceBonusValue }),
  });
};
