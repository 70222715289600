import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * It updates the status of a vehicle registration
 * @param {string} vehicleId - The uuid of the vehicle you want to update
 * @param action - 'reupload'
 * @returns The response from the API call.
 */
export const updateVehicleStatus = async (vehicleId: string, action: 'reupload', tenant: string) => {
  const url = `${API_URL}/vehicle-registrations/${vehicleId}/${action}`;
  const query = { tenant };
  return await fetcher(queryString.stringifyUrl({ url, query }), {
    method: 'PUT',
  });
};
