import { useEffect, useRef, useState } from 'react';
import { DialogActions, DialogContent, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { BootstrapDialog, BootstrapDialogTitle } from '../../../common/dialog/BootstrapDialog';
import FormGenerator from '../../../common/form-generator/FormGenerator';
import { FormConfigurationTypeEnum, IActiveTab, IFormField } from '../../../common/form-generator/interfaces';
import ShowPlaceholders from '../../components/ShowPlaceholders';
import { TemplateMapItem, templatePlaceholderMapping } from '../../template-placeholder';

/**
 * Defines the properties required by the
 * current dialog component.
 */
interface IEditTemplateDialogProps {
  setModal: Function;
  template: any;
  isEdit: boolean;
}

export default function EditTemplateDialog(props: IEditTemplateDialogProps) {
  // Holds the vehicle data.
  const [activeTab, setActiveTab] = useState<IActiveTab>({
    current: 1,
    total: 1,
  });
  // Indicates if the user wishes to save the data or not.
  const [shouldSave, setShouldSave] = useState<boolean>(false);
  // Indicates if the form has a validation error.
  const [isValid, setIsValid] = useState<boolean>(true);
  // Holds the found placeholders for the current template.
  const [templateMap, setTemplateMap] = useState<TemplateMapItem | null>(null);
  // Contains the references for all the input fields.
  const textPartReference = useRef();
  // The callback to set the TextPart.
  const [callback, setCallback] = useState<((startPos: number, endPos: number, placeholder: string) => void) | null>(
    null,
  );
  // Holds the list of fields.
  const [fields, setFields] = useState<IFormField[]>([]);

  /**
   * Handle the next wizard step when
   * the user wishes to advance based
   * on the current tab.
   */
  const handleStepAdvance = () => {
    /**
     * Check if we are on the last tab and
     * if not then switch to it.
     */
    activeTab.current < activeTab.total
      ? setActiveTab({ current: activeTab.current + 1, total: activeTab.total })
      : setShouldSave(true);
  };

  /**
   * Get the template placeholders for the
   * currently loaded template.
   */
  useEffect(() => {
    let foundMap = templatePlaceholderMapping.find((t) => t.template === props.template?.TemplateName);
    if (!foundMap) {
      foundMap = templatePlaceholderMapping.find((t) => t.template === 'CustomTemplate');
    }
    setTemplateMap(foundMap || null);
  }, []);

  return (
    <div>
      <BootstrapDialog
        onClose={() => props.setModal(null)}
        aria-labelledby="customized-dialog-title"
        maxWidth="md"
        open={true}
      >
        {/* The title of the current dialog */}
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => props.setModal(null)}
        >
          {props.template ? 'Vorlage bearbeiten' : 'Neue Vorlage hinzufügen'}
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{ height: 600 }}
        >
          {/* Show the template map if available. */}
          {templateMap && (
            <ShowPlaceholders
              templateMap={templateMap as TemplateMapItem}
              textPartReference={textPartReference}
              callback={
                callback as (startPos: number, endPos: number, placeholder: string, fields: IFormField[]) => void
              }
              setFields={setFields}
              fields={fields}
            ></ShowPlaceholders>
          )}

          <FormGenerator
            type={FormConfigurationTypeEnum.TEMPLATE}
            mode={props.isEdit ? 'edit' : 'create'}
            currentObject={props.template}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            shouldSave={shouldSave}
            setShouldSave={setShouldSave}
            isValid={isValid}
            setIsValid={setIsValid}
            inputReference={textPartReference}
            setCallback={setCallback}
            setFields={setFields}
            fields={fields}
          />
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Validation error message */}
          {!isValid && (
            <Typography
              component="div"
              sx={{ flex: 1, color: 'red', ml: 1 }}
            >
              Das Formular enthält Validierungsfehler
            </Typography>
          )}

          {/* The SAVE button */}
          <div
            style={{
              flex: 1,
              width: 50,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button onClick={handleStepAdvance}>{activeTab.current < activeTab.total ? 'Nächste' : 'Speichern'}</Button>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
