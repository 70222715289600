import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

export interface IGenerateInvoices {
  take: number; // was named limit
  skip: number;
  tenant: string;
}

/**
 * It generates invoices for all the quota sales that have not been invoiced yet
 * @param  - `limit` - the number of invoices to generate
 * @param  - `skip` - the number of invoices to skip
 */
export const generateInvoices = ({ take, skip, tenant }: IGenerateInvoices) => {
  const url = `${API_URL}/quota-sales/generateInvoice`;
  const query = { tenant, take, skip };
  const fullUrl = queryString.stringifyUrl({ url, query });
  return fetcher(fullUrl, {
    method: 'POST',
  });
};
