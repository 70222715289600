import queryString from 'query-string';

import { API_URL } from '../../constants/api';
import { fetcher } from '../fetcher';
/**
 * It takes in a username or email and returns a Cognito user object
 * @param  - username - the username of the user
 */
export const getCognitoUser = ({
  username,
  email,
  tenant,
}: {
  username?: string;
  email?: string;
  tenant: string | null;
}) => {
  const stringParams = queryString.stringify({ username, email, tenant });
  const url = `${API_URL}/users/user?${stringParams}`;

  return fetcher(url, {
    method: 'GET',
  });
};
