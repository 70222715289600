import toast from 'react-hot-toast';
import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * Market the vehicle with the provided id.
 *
 * @param id string
 * @returns Promise<any>
 */
export const rejectQuota = async (quotaId: string, tenant?: string): Promise<any> => {
  if (!quotaId) return new Promise((resolve) => resolve(null));
  const url = `${API_URL}/quota-sales/${quotaId}/markRejected`;
  const query = { tenant };
  const result = await fetcher(queryString.stringifyUrl({ url, query }), {
    method: 'PUT',
  });
  if (!result.error) {
    toast.success(`Quota with id ${quotaId} REJECTED successfully.`);
  } else {
    toast.error(`Quota rejection failed for id ${quotaId}: ${result?.error}`);
  }
};
