import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

export interface IEmailTemplate {
  TemplateName: string;
  SubjectPart: string;
  TextPart: string;
  Tenant: string;
}

/**
 * Make an API request using the provided url.
 */
export const getTemplatesPromise = (tenant: string): Promise<any> => {
  const url = `${API_URL}/bulkmail/templates/tenants/${tenant}`;

  return fetcher(url)
    .then((data) => data)
    .catch((error) => {
      return error;
    });
};
