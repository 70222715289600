import { IButtonState, IFieldStateCondition, IFormField } from './interfaces';
import { validatorsConfigurationByFieldName } from './validators';

export interface FieldValidationResult {
  fieldName: string;
  validationErrors: string[];
}

/**
 * Provides some methods to perform validation
 * related tasks.
 */
export class ValidationHelper {
  /**
   * Validator configuration by
   * field name.
   */
  private validatorsByFieldName = validatorsConfigurationByFieldName;

  /**
   * Parse and run the validators on the provided field
   * and then update the validity of the form by using
   * the provided setIsValid callback.
   *
   * @param field IFormField
   * @param newValue any
   * @param setIsValid Function
   * @returns boolean
   */
  public validate(field: IFormField, newValue: any, setIsValid: Function): string | undefined {
    const validationResults = field.validators?.map((v) => v(newValue as string));
    const hasValidationError = validationResults?.find((v) => !!v);
    setIsValid(!hasValidationError);
    return hasValidationError;
  }

  /**
   * Get the list of validator objects
   * using the provided list of validator
   * names.
   *
   * @param fields IFormField[]
   * @returns IFormField[]
   */
  public attachValidators(fields: IFormField[]): IFormField[] {
    return fields.map((f) => ({ ...f, validators: this.findValidators(f.key) }));
  }

  /**
   * Get the list of validators for the provided
   * field name.
   *
   * @param fieldName string
   * @returns
   */
  private findValidators(fieldName: string): ((value: string, length?: number) => string)[] {
    // Find the field validators configuration by field name.
    const foundValidatorConfig = this.validatorsByFieldName.find((vbfn) => vbfn.fieldName === fieldName);
    // Return empty list if not found.
    return foundValidatorConfig ? foundValidatorConfig.validators : [];
  }

  /**
   * Check if the provided list of fields have
   * invalid fields between them.
   *
   * @param fields IFormField[]
   * @returns boolean
   */
  public isFormValid(fields: IFormField[]): boolean {
    return this.validateForm(fields).length === 0;
  }

  /**
   * Validates the provided list of fields and return an array with the validation errors.
   *
   * @param fields the fields to be validated
   * @returns an array containing all the validation errors; if there are no errors, then an empty
   * array is returned.
   */
  public validateForm(fields: IFormField[]): FieldValidationResult[] {
    /**
     * Parse through each field and validate
     * each validator attached to them.
     */
    const validatorTests = fields.map((f) => ({
      fieldName: f.key,
      validationErrors: f.validators?.map((v) => v(f.value as string)).filter((v) => !!v) ?? [],
    }));
    /**
     * Filter out the validation results
     * which were null since they indicate
     * valid fields.
     */
    const fieldsWithValidationErrors = validatorTests.filter((t) => !!t.validationErrors.length);
    /**
     * Parse through the fields with validation
     * errors and set the first validation error
     * text on each of those fields.
     */
    fieldsWithValidationErrors.forEach(
      (ve) => (fields.find((f) => f.key === ve.fieldName)!.validationErrorText = ve.validationErrors[0]),
    );
    /**
     * If there is at least 1 result it means
     * that the form is invalid.
     */
    return fieldsWithValidationErrors;
  }

  /**
   * Update the provided button state depending on
   * the depending field from the list of provided
   * fields.
   *
   * @param buttonState IButtonState
   * @param fields IFormField[]
   * @param setButtonState Function
   * @returns void
   */
  public updateButtonStates(buttonStates: IButtonState[], fields: IFormField[], setButtonStates: Function): void {
    // Parse through the list of button states.
    const newButtonStates = buttonStates.map((buttonState) => {
      /**
       * If the buttonState has conditions then parse
       * through each of them and check if all of them
       * pass the validator test.
       */
      if (buttonState.conditions && buttonState.conditions.length) {
        /**
         * Run the validators from each of the
         * conditions.
         */
        const conditionResult = this.executeValidators(fields, buttonState.conditions);
        /**
         * Check if all the conditions returned
         * true, otherwise we should set the
         * button state to active = false.
         */
        const shouldBeActive = conditionResult.filter((r) => !!r).length == buttonState.conditions.length;
        return { ...buttonState, active: shouldBeActive };
      }
      return { ...buttonState };
    });
    // Set the newly computed button states.
    setButtonStates(newButtonStates);
  }

  /**
   * Parse through the list of provided validators
   * and run each of them and return a list of
   * results.
   *
   * @param validators
   */
  public executeValidators(fields: IFormField[], conditions: IFieldStateCondition[]): boolean[] {
    return conditions.map((condition) => {
      // Find the field to which the condition refers to.
      const foundDependingField = fields.find((f) => f.key === condition.key);
      // If it has a depending value then check the value
      if (foundDependingField && condition.value) {
        return foundDependingField.value === condition.value;
      }
      /**
       * If it has a depending validator then check the
       * validator result.
       */
      if (foundDependingField && condition.validator) {
        return !condition.validator(foundDependingField.value as string);
      }
      return true;
    });
  }

  /**
   * Check if any of the fields on the provided list
   * should be hidden based on the conditions contained
   * inside the hiddenWhen properties.
   *
   * @param fields IFormField[]
   * @returns IFormField[]
   */
  public filterFieldsByHiddenWhenConditions(fields: IFormField[]): IFormField[] {
    return fields.filter((field) => {
      const conditionResults = this.executeValidators(fields, field.hiddenWhen as IFieldStateCondition[]);
      return !conditionResults.filter((c) => c).length;
    });
  }
}
