import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Card, CardContent, Divider, Grid, styled, Typography } from '@mui/material';

import api from '../../../services/vehicles/api';
import { formatDate } from '../../../utils/date';
import { useTenants } from '../../tenant';
import { IVehicle } from '../vehicles-admin/api/dto';
import EditVehicleDialog from '../vehicles-admin/dialogs/EditVehicleDialog/EditVehicleDialog';

import VehiclesQuotasDetails from './VehicleQuotasDetails';

const VehicleRegistrationsTable = ({ vehicleRegistrations }: { vehicleRegistrations: any[] }) => {
  const dispatch = useDispatch();
  const { currentTenant } = useTenants();
  const [editVehicle, setEditVehicle] = useState<any>(null);

  const isValidDate = (dateString: string | number | Date) => {
    return !isNaN(new Date(dateString).getTime());
  };

  const handleVehicleDataUpdate = (newVehicle: IVehicle) => {
    const updateQuery: any = api.util.updateQueryData(
      'getVehiclesByUserId',
      { userId: newVehicle.ownerUsername, tenant: currentTenant },
      (draft) => {
        draft.find((data: any) => {
          const vehicle = data.vehicleRegistrations.find((vehicle: IVehicle) => vehicle.uuid === newVehicle.uuid);

          if (vehicle) {
            Object.assign(vehicle, newVehicle);
          }
        });
      },
    );
    dispatch(updateQuery);
  };

  return (
    <Box>
      {vehicleRegistrations.map((registration) => (
        <Card
          key={registration.id}
          sx={{ mb: 2 }}
        >
          <CardContent>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Typography variant="body1">
                  License Plate: <strong>{registration.licensePlate}</strong>
                </Typography>
                <Typography variant="body1">
                  Owner Name: {`${[registration.firstName, registration.lastName].filter(Boolean).join(' ')}`}
                </Typography>
                <Typography variant="body1">Class: {registration.vehicleClass}</Typography>
                <Typography variant="body1">UUID: {registration.uuid}</Typography>
                <Typography variant="body1">VIN: {registration.carId}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Typography variant="body1">
                  Registration Date:{' '}
                  {isValidDate(registration.registeredAt) ? formatDate(registration.registeredAt) : ''}
                </Typography>
                <Typography variant="body1">
                  Deregistration Date:{' '}
                  {registration.registeredUntil && isValidDate(registration.registeredUntil)
                    ? formatDate(registration.registeredUntil)
                    : 'not available'}
                </Typography>
                <Typography variant="body1">Status: {registration.status}</Typography>
              </Grid>
            </Grid>

            <Button onClick={() => setEditVehicle(registration)}>Edit Vehicle</Button>
            <Divider sx={{ my: 1 }} />
            <VehiclesQuotasDetails vehicleUuid={registration.uuid} />
          </CardContent>
        </Card>
      ))}

      {editVehicle && (
        <EditVehicleDialog
          setModal={setEditVehicle}
          vehicle={editVehicle}
          setSelectedIds={() => {
            return;
          }}
          nextVehicleId={null}
          onSubmit={handleVehicleDataUpdate}
        />
      )}
    </Box>
  );
};

const UserVehicleRegistrations = ({ vehicleRegistrations }: { vehicleRegistrations: any[] }) => {
  const activeVehicleRegistrations = vehicleRegistrations.filter(
    (vehicle) => !vehicle.registeredUntil || vehicle.registeredUntil > new Date().toISOString(),
  );
  const deregisteredVehicleRegistrations = vehicleRegistrations.filter(
    (vehicle) => vehicle.registeredUntil && vehicle.registeredUntil <= new Date().toISOString(),
  );

  return (
    <>
      <SectionTitle>Active Vehicle Registrations</SectionTitle>
      {activeVehicleRegistrations && activeVehicleRegistrations.length > 0 && (
        <Counter>({activeVehicleRegistrations.length})</Counter>
      )}
      {activeVehicleRegistrations.length > 0 ? (
        <VehicleRegistrationsTable vehicleRegistrations={activeVehicleRegistrations} />
      ) : (
        <Typography variant="body1">No registered vehicles found.</Typography>
      )}

      <SectionTitle>Deregistered Vehicle Registrations</SectionTitle>
      {deregisteredVehicleRegistrations && deregisteredVehicleRegistrations.length > 0 && (
        <Counter>({deregisteredVehicleRegistrations.length})</Counter>
      )}
      {deregisteredVehicleRegistrations.length > 0 ? (
        <VehicleRegistrationsTable vehicleRegistrations={deregisteredVehicleRegistrations} />
      ) : (
        <Typography variant="body1">No deregistered vehicles found.</Typography>
      )}
    </>
  );
};

const SectionTitle = styled('h3')`
  display: inline-block;
`;

const Counter = styled('span')`
  position: relative;
  font-size: small;
  margin-left: 5px;
  top: -2px;
  display: inline-block;
`;

export default UserVehicleRegistrations;
