import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * It makes a GET request to the `/users/csv/all` endpoint, and returns the data
 * @returns A promise that resolves to the data returned from the API.
 */
export const getUsersCSV = (tenant: string | null): Promise<any> => {
  // Build up the complete url
  const url = `${API_URL}/users/csv/all`;
  const query = { tenant };
  return fetcher(queryString.stringifyUrl({ url, query }), {
    method: 'GET',
  })
    .then((data) => data)
    .catch((error) => {
      return error;
    });
};
