import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import { Button } from '@mui/material';

import { ContextActionEnum, ContextScopeEnum } from '../../context/GlobalState';

/**
 * Check if the marketable filter has been selected
 * and we have a few selections present.
 *
 * @param selectedIds string[]
 * @returns boolean
 */
const EditQuotaSaleAction = (props: { selectedIds: string[]; setModal: Function; context: ContextScopeEnum }) => {
  const hasQuotasContext = props.context === ContextScopeEnum.QUOTAS;
  const hasOneSelected = props.selectedIds && props.selectedIds.length === 1;
  const show = hasOneSelected && hasQuotasContext;

  if (show) {
    return (
      <Button
        color="inherit"
        onClick={() => props.setModal({ type: ContextActionEnum.EDIT_QUOTA })}
      >
        <ElectricCarIcon />
        &nbsp;{'Edit quota'}
      </Button>
    );
  }
  return <></>;
};

export default EditQuotaSaleAction;
