export enum FILTER_TYPE_ENUM {
  DIVIDER,
  UBA_2022,
  UBA_2023,
  UBA_2024,
  PO_INSTANT,
  PO_STANDARD,
  PO_BONUS,
  PO_NOT_SELECTED,
  USER_PREPARED,
  USER_REQUESTED,
  UBA_SUBMITTED,
  UBA_APPROVED,
  UBA_FAILED,
  SOLD,
  INVOICE_GENERATED,
  INVOICE_SENT,
  PAID_OUT,
  REJECTED,
  UPLOADED,
  AI_PARSED,
  INCOMPLETE,
  INCOMPLETE_RESUBMIT,
  VEHICLE_AUDITED,
}

/**
 * Defines a filter object used to fetch different
 * types of data in relation to the list with which
 * it is being used together with.
 */
export interface IFilterItem {
  id: FILTER_TYPE_ENUM;
  name: string;
  type?: FILTER_TYPE_ENUM;
  filter?: any;
  checked?: boolean;
  active?: boolean;
  icon?: any;
}
