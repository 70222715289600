import { useContext } from 'react';
import { Copyright } from '@mui/icons-material';
import { Alert, Box, Container, Grid, Snackbar, Toolbar } from '@mui/material';

import { GlobalContext } from '../context/GlobalState';

import UploadsList from './UploadsList';

const Uploads = () => {
  /**
   * Get the required context properties to be able
   * to react to their changes.
   */
  const { setSelectedIds, triggerAction, setTriggerAction } = useContext(GlobalContext);

  const actionComplete = () => {
    // Close the action progress card.
    setTriggerAction(null);
    // De-select the previously selected rows.
    setSelectedIds([]);
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />

      <Container
        sx={{ mt: 4, mb: 4 }}
        maxWidth={false}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <UploadsList />
          </Grid>
        </Grid>
        <Copyright sx={{ pt: 4 }} />
      </Container>

      {/* {modal && modal.type === ContextActionEnum.ADD_TEMPLATE &&
        <EditTemplateDialog
          setModal={setModal}
          template={selectedIds && selectedIds.length === 1 ? templates.find(t => t.id === selectedIds[0]): null}
        />
      } */}

      {triggerAction && triggerAction.progress && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          autoHideDuration={6000}
          onClose={actionComplete}
        >
          <Alert
            onClose={actionComplete}
            severity="success"
            sx={{ width: '100%' }}
          >
            {`${triggerAction.type.toString()} erfolgreich beendet!`}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default Uploads;
