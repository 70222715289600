import { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Stack, TextField, useTheme } from '@mui/material';

import { DEFAULT_PAGE_SIZE } from '../../../constants/pagination';
import { useFixedSearchParams } from '../../../hooks/useFixedSearchParams';
import { useTenants } from '../../tenant';
import { GlobalContext, IPagination } from '../context/GlobalState';

import { getUsersPromise } from './api/getUsers';
import { searchUsersPromise } from './api/searchUsers';

type SearchCallback = (
  params: {
    tenant: string;
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    usernames: string;
    pagination: IPagination;
    total: number;
  },
  options: {
    setIsLoading: (value: boolean) => void;
    setData: (data: unknown) => void;
  },
) => void;

const SearchUsers = (props: { setIsLoading: (value: boolean) => void }) => {
  const [searchParams, setSearchParams] = useFixedSearchParams();

  const { setUsers, total, setTotal, pagination } = useContext(GlobalContext);
  const { currentTenant } = useTenants();
  const theme = useTheme();
  const [username, setUsername] = useState(searchParams.get('username') || '');
  const [email, setEmail] = useState(searchParams.get('email') || '');
  const [firstName, setFirstName] = useState(searchParams.get('firstName') || '');
  const [lastName, setLastName] = useState(searchParams.get('lastName') || '');
  const [usernames, setUsernames] = useState(searchParams.get('usernames') || '');

  const AllSetters: any = {
    setUsername: setUsername,
    setUsernames: setUsernames,
    setEmail: setEmail,
    setFirstName: setFirstName,
    setLastName: setLastName,
  };

  const handleFieldUpdate = (setterName: string, value: string) => {
    setUsername('');
    setEmail('');
    setFirstName('');
    setLastName('');
    setUsernames('');

    const theValue = setterName === 'setEmail' ? value.toLowerCase() : value;
    AllSetters[setterName](theValue);
  };

  const search: SearchCallback = useCallback(
    async (
      { username, email, firstName, lastName, usernames, tenant, pagination, total },
      { setIsLoading, setData },
    ) => {
      setIsLoading(true);
      try {
        if (usernames) {
          const { data, totalCount } = await searchUsersPromise(tenant, pagination, total, usernames);
          setData(data);
          setTotal(parseInt(totalCount) || 0);
        } else {
          const { data, totalCount } = await getUsersPromise(
            {
              username,
              email,
              firstName,
              lastName,
            },
            pagination,
            total,
            tenant,
          );
          setData(data);
          setTotal(parseInt(totalCount) || 0);
        }
        setIsLoading(false);
      } catch (err) {
        toast.error('failed to user data');
        setIsLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    setSearchParams((searchParams) => {
      searchParams.delete('username');
      searchParams.delete('email');
      searchParams.delete('firstName');
      searchParams.delete('lastName');
      searchParams.delete('usernames');

      searchParams.delete('range');
      searchParams.delete('pageSize');
      searchParams.append('range', pagination.range.join(','));
      searchParams.set('pageSize', (pagination.pageSize || DEFAULT_PAGE_SIZE).toString());

      username && searchParams.set('username', username);
      email && searchParams.set('email', email);
      firstName && searchParams.set('firstName', firstName);
      lastName && searchParams.set('lastName', lastName);
      usernames && searchParams.set('usernames', usernames);
      return searchParams;
    });
    const delaySearch = setTimeout(
      () =>
        search(
          {
            username,
            email,
            firstName,
            lastName,
            usernames,
            tenant: currentTenant,
            pagination,
            total,
          },
          // FIXME: fix type for setUsers in GlobalContext
          { setIsLoading: props.setIsLoading, setData: setUsers as (data: unknown) => void },
        ),
      800,
    );
    return () => clearTimeout(delaySearch);
  }, [
    username,
    email,
    firstName,
    lastName,
    usernames,
    search,
    props.setIsLoading,
    setUsers,
    currentTenant,
    setSearchParams,
    pagination,
  ]);

  return (
    <Stack
      direction="row"
      spacing={theme.spacing(2)}
    >
      <TextField
        label="Username"
        variant="outlined"
        value={username}
        onChange={(event) => handleFieldUpdate('setUsername', event.target.value)}
      />
      <TextField
        label="Email"
        variant="outlined"
        value={email}
        onChange={(event) => handleFieldUpdate('setEmail', event.target.value)}
      />
      <TextField
        label="First Name"
        variant="outlined"
        value={firstName}
        onChange={(event) => handleFieldUpdate('setFirstName', event.target.value)}
      />
      <TextField
        label="Last Name"
        variant="outlined"
        value={lastName}
        onChange={(event) => handleFieldUpdate('setLastName', event.target.value)}
      />
      <TextField
        label="Usernames"
        variant="outlined"
        value={usernames}
        onChange={(event) => handleFieldUpdate('setUsernames', event.target.value)}
        helperText="Bulk search users by usernames (comma seperated)"
      />
    </Stack>
  );
};

export default SearchUsers;
