import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';
import { IDenseTableRow } from '../../common/dialog/ResultsTable/ResultsTable';
import { ContextActionEnum } from '../../context/GlobalState';

// /**
//  * Market the vehicle with the provided id.
//  *
//  * @param id number
//  * @returns Promise<any>
//  */
// const approveQuotaID = (id: number): Promise<any> => {
//   if (!id) return new Promise((resolve) => resolve(null));
//   const url = `${API_URL}/quota-sales/${id}/markApproved`;
//   return fetcher(`${url}`, {
//     method: 'PUT',
//   }).then();
// };

/**
 * Market multiple vehicles with the provided ids.
 * @param {number[]} ids - number[] - an array of quota IDs to approve
 * @returns Promise<any>
 */
const approveQuotaIDs = (ids: (string | undefined)[], tenant: string | null): Promise<any> => {
  if (!ids.length) return new Promise((resolve) => resolve(null));
  const url = `${API_URL}/quota-sales/markApproved`;
  const query = { tenant };
  return fetcher(queryString.stringifyUrl({ url, query }), {
    method: 'PUT',
    body: JSON.stringify({ quotaSaleIds: ids }),
  }).then();
};

/**
 * Fetch the latest quota sale id for the list of
 * provided vehicle ids.
 */
export const approveAllQuotaIds = (
  currentState: IDenseTableRow[],
  triggerActionCallback: Function,
  setCurrentState: Function,
  stateWhenDone: any,
  tenant: string | null,
) => {
  /**
   * Decide which endpoint we have to
   * call.
   */
  if (currentState && currentState.length) {
    (async () => {
      let currentRequest = 1;

      const quotaSaleIds = currentState.map((row) => row.quotaId);
      const response = await approveQuotaIDs(quotaSaleIds, tenant);
      const body = await response?.data;

      currentState.forEach((row) => {
        try {
          const found = body.find((qs: any) => qs.uuid === row.quotaId)?.uuid;
          if (!found) throw new Error();
          row.approveStatus = 200;
          // Set the trigger action update.
          triggerActionCallback({
            type: ContextActionEnum.BULK_APPROVE_QUOTA_SALES,
            progress: (currentRequest++ * 100) / currentState.length,
          });
        } catch (error) {
          row.approveStatus = 409;
          // Set the trigger action update.
          triggerActionCallback({
            type: ContextActionEnum.BULK_APPROVE_QUOTA_SALES,
            progress: (currentRequest++ * 100) / currentState.length,
          });
        }
      });
      setCurrentState(stateWhenDone);
    })();
  }
};
