import { Box, Button, Typography } from '@mui/material';

import { formatQuotaDate } from '../../../../../utils/date';

import { IQuotaSaleItem } from './VehicleOverview';

const styles = (): any => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      borderRadius: '8px',
      border: '1px solid gray',
      width: '240px',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid gray',
      width: '100%',
      py: 1,
      px: 2,
    },
    year: {
      width: '50px',
      display: 'block',
      padding: 2,
      backgroundColor: '#1876d2',
      borderRadius: '8px',
      textAlign: 'center',
      color: '#ffffff',
    },
    detailsContainer: {
      width: '100%',
      py: 1,
      px: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    detailsRow: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 2,
      '& p.MuiTypography-body1': {
        fontSize: '15px',
      },
    },
    footerContainer: {
      width: '100%',
      borderTop: '1px solid gray',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      py: 1,
      px: 2,
      '& button.delete': {
        backgroundColor: '#fa5b53',
        color: '#ffffff',
        ':hover': {
          backgroundColor: '#C02119',
          color: '#ffffff',
        },
      },
    },
  };
};

/**
 * Render a card that displays specific quota
 * details to the admin.
 */
const QuotaCard = (props: { quota: IQuotaSaleItem; showConfirmationModal: any }) => {
  return (
    <Box sx={styles().container}>
      {/* The card title information. */}
      <Box sx={styles().titleContainer}>
        {/* Shows the current quota id. */}
        <span>Quota ID: {props.quota.id}</span>

        {/* Shows the current quota year. */}
        <span style={styles().year}>{props.quota.uba_year}</span>
      </Box>

      {/* Contains the quota details. */}
      <Box sx={styles().detailsContainer}>
        {/* The createdAt row. */}
        <Box sx={styles().detailsRow}>
          <Typography variant="body1">Created at</Typography>
          <Typography variant="body1">{formatQuotaDate(props.quota.createdAt)}</Typography>
        </Box>

        {/* The submittedAt row. */}
        <Box sx={styles().detailsRow}>
          <Typography variant="body1">Submitted at:</Typography>
          <Typography variant="body1">{formatQuotaDate(props.quota.submittedAt)}</Typography>
        </Box>

        {/* The approvalDate row. */}
        <Box sx={styles().detailsRow}>
          <Typography variant="body1">Approval:</Typography>
          <Typography variant="body1">{formatQuotaDate(props.quota.approvalDate)}</Typography>
        </Box>

        {/* The declineDate row. */}
        <Box sx={styles().detailsRow}>
          <Typography variant="body1">Decline:</Typography>
          <Typography variant="body1">{formatQuotaDate(props.quota.declineDate)}</Typography>
        </Box>

        {/* The soldAt row. */}
        <Box sx={styles().detailsRow}>
          <Typography variant="body1">Sold:</Typography>
          <Typography variant="body1">{formatQuotaDate(props.quota.soldAt)}</Typography>
        </Box>

        {/* The payoutDate row. */}
        <Box sx={styles().detailsRow}>
          <Typography variant="body1">Payout:</Typography>
          <Typography variant="body1">{formatQuotaDate(props.quota.payoutDate)}</Typography>
        </Box>

        {/* The status row. */}
        <Box sx={styles().detailsRow}>
          <Typography variant="body1">Status:</Typography>
          <Typography variant="body1">{props.quota.status}</Typography>
        </Box>

        {/* The product option row. */}
        <Box sx={styles().detailsRow}>
          <Typography variant="body1">Product option:</Typography>
          <Typography variant="body1">{props.quota.productOption ?? '---'}</Typography>
        </Box>

        {/* The product price row. */}
        <Box sx={styles().detailsRow}>
          <Typography variant="body1">Product price:</Typography>
          <Typography variant="body1">{props.quota.productPrice ?? '---'}</Typography>
        </Box>
      </Box>

      {/* The card footer containing the actions. */}
      <Box sx={styles().footerContainer}>
        {/* The delete button (opens confirmation first.) */}
        <Button
          className="delete"
          onClick={() => props.showConfirmationModal(props.quota)}
        >
          Löschen
        </Button>
      </Box>
    </Box>
  );
};

export default QuotaCard;
