import { combineReducers, configureStore } from '@reduxjs/toolkit';

import invoicesApi from '../services/invoices';
import quotasApi from '../services/quotas/api';
import tenantConfiguratorApi from '../services/tenantConfigurator/api';
import usersApi from '../services/users/api';
import vehiclesApi from '../services/vehicles/api';

const rootReducer = combineReducers({
  // APIs
  [usersApi.reducerPath]: usersApi.reducer,
  [vehiclesApi.reducerPath]: vehiclesApi.reducer,
  [quotasApi.reducerPath]: quotasApi.reducer,
  [invoicesApi.reducerPath]: invoicesApi.reducer,
  [tenantConfiguratorApi.reducerPath]: tenantConfiguratorApi.reducer,
});

export const makeStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        usersApi.middleware,
        vehiclesApi.middleware,
        quotasApi.middleware,
        invoicesApi.middleware,
        tenantConfiguratorApi.middleware,
      ),
    devTools: true,
  });

type RootState = ReturnType<typeof rootReducer>;
