import { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface InvalidVinConfirmationProps {
  open: boolean;
  onClose?: (invalidVinConfirmed: boolean) => any;
}

export const InvalidVinConfirmation: FC<InvalidVinConfirmationProps> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={() => {}}
    >
      <DialogTitle>FIN Prüfung</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Die FIN scheint nicht korrekt zu sein. Sie muss aus 17 Zeichen bestehen, keine Sonderzeichen und O, I, Q
          enthalten. Bitte prüfe die FIN erneut.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose?.(false)}>Abbrechen</Button>
        <Button
          autoFocus
          onClick={() => onClose?.(true)}
        >
          Trotzdem speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};
