import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * It takes an array of ids and an action, and then it makes a PUT request to the API with the ids and
 * action
 * @param  - ids - an array of quota sale ids
 * @returns A promise that resolves to null.
 */
export const bulkUpdateQuotaStatus = ({
  ids,
  action,
  tenant,
}: {
  ids: string[];
  action: string;
  tenant: string | null;
}) => {
  if (!ids.length) return new Promise((resolve) => resolve(null));
  const query = { tenant };
  const url = `${API_URL}/quota-sales/${action}`;
  return fetcher(queryString.stringifyUrl({ url, query }), {
    method: 'PUT',
    body: JSON.stringify({ quotaSaleIds: ids }),
  });
};
