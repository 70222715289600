import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * Save the provided vehicle object.
 *
 * @param vehicle string
 * @param tenant string
 */
export const switchVehicleImages = (vehicleUuid: string, tenant: string): Promise<any> => {
  const url = `${API_URL}/vehicle-registrations/${vehicleUuid}/switch-images`;
  const method = 'PATCH';

  const query = { tenant };
  return fetcher(queryString.stringifyUrl({ url, query }), {
    method,
  });
};
