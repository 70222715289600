import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * Make an API request using the provided url.
 */
export const getInvoiceTemplatesPromise = (tenant: string): Promise<any> => {
  const url = `${API_URL}/v2/invoice/template`;
  const query = { tenant };
  const fullUrl = queryString.stringifyUrl({ url, query });

  return fetcher(fullUrl)
    .then((data) => data)
    .catch((error) => {
      return error;
    });
  // Handle errors related to wrong status (401...).
  // error && handleStatusError(error);
};
