import React, { Fragment, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { Divider, Typography } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useAuth } from '../auth';

import { ContextScopeEnum, GlobalContext } from './context/GlobalState';

export const SidebarMenu = () => {
  const navigate = useNavigate();
  const [searchQuery, _setSearchQuery] = useSearchParams();
  const { tenant } = Object.fromEntries(searchQuery.entries());
  /**
   * FIXME This is a workaround to make tenant sustainable across
   * the app. Make it a part of url and this will be removed.
   */
  const persistendSearchParams = new URLSearchParams({ tenant });
  // Get a few props from the global scope.
  const { currentScope } = useContext(GlobalContext);

  const { signOut } = useAuth();

  return (
    <React.Fragment>
      <Fragment>
        <li>
          <Typography
            sx={{ mt: 2, ml: 2 }}
            color="text.secondary"
            display="block"
            variant="caption"
          >
            Verwaltung
          </Typography>
        </li>
        <Divider component="li" />
      </Fragment>

      <ListItemButton
        onClick={() => navigate({ pathname: '/users', search: persistendSearchParams.toString() })}
        selected={currentScope === ContextScopeEnum.USERS}
      >
        <ListItemIcon sx={{ color: currentScope === ContextScopeEnum.USERS ? '#1976d2' : '' }}>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Benutzer" />
      </ListItemButton>

      <ListItemButton
        onClick={() => navigate({ pathname: '/templates', search: persistendSearchParams.toString() })}
        selected={currentScope === ContextScopeEnum.TEMPLATES}
      >
        <ListItemIcon sx={{ color: currentScope === ContextScopeEnum.TEMPLATES ? '#1976d2' : '' }}>
          <HistoryEduIcon />
        </ListItemIcon>
        <ListItemText primary="Vorlagen" />
      </ListItemButton>

      <ListItemButton
        onClick={() => navigate({ pathname: '/vehicles', search: persistendSearchParams.toString() })}
        selected={currentScope === ContextScopeEnum.VEHICLES}
      >
        <ListItemIcon sx={{ color: currentScope === ContextScopeEnum.VEHICLES ? '#1976d2' : '' }}>
          <DirectionsCarIcon />
        </ListItemIcon>
        <ListItemText primary="Fahrzeuge" />
      </ListItemButton>

      <ListItemButton
        onClick={() => navigate({ pathname: '/quotas', search: persistendSearchParams.toString() })}
        selected={currentScope === ContextScopeEnum.QUOTAS}
      >
        <ListItemIcon sx={{ color: currentScope === ContextScopeEnum.QUOTAS ? '#1976d2' : '' }}>
          <RequestQuoteIcon />
        </ListItemIcon>
        <ListItemText primary="Quotas" />
      </ListItemButton>

      <ListItemButton
        onClick={() => navigate({ pathname: '/invoicing', search: persistendSearchParams.toString() })}
        selected={currentScope === ContextScopeEnum.INVOICING}
      >
        <ListItemIcon sx={{ color: currentScope === ContextScopeEnum.INVOICING ? '#1976d2' : '' }}>
          <ReceiptIcon />
        </ListItemIcon>
        <ListItemText primary="Rechnungen" />
      </ListItemButton>

      <ListItemButton
        onClick={() => navigate({ pathname: '/invoice-templates', search: persistendSearchParams.toString() })}
        selected={currentScope === ContextScopeEnum.INVOICE_TEMPLATES}
      >
        <ListItemIcon sx={{ color: currentScope === ContextScopeEnum.INVOICE_TEMPLATES ? '#1976d2' : '' }}>
          <AutoFixHighIcon />
        </ListItemIcon>
        <ListItemText primary="Rechnungen Vorlagen" />
      </ListItemButton>

      <ListItemButton
        onClick={() => navigate({ pathname: '/uploads', search: persistendSearchParams.toString() })}
        selected={currentScope === ContextScopeEnum.PDF_UPLOADS}
      >
        <ListItemIcon sx={{ color: currentScope === ContextScopeEnum.PDF_UPLOADS ? '#1976d2' : '' }}>
          <PictureAsPdfIcon />
        </ListItemIcon>
        <ListItemText primary="PDF Uploads" />
      </ListItemButton>

      <ListItemButton
        onClick={() => navigate({ pathname: '/user-info', search: searchQuery.toString() })}
        selected={currentScope === ContextScopeEnum.USER_INFO}
      >
        <ListItemIcon sx={{ color: currentScope === ContextScopeEnum.USER_INFO ? '#1976d2' : '' }}>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="User Info" />
      </ListItemButton>

      {signOut && (
        <>
          <Divider sx={{ my: 1 }} />
          <ListItemButton onClick={signOut}>
            <ListItemIcon sx={{ color: '#F00' }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </>
      )}
    </React.Fragment>
  );
};
