import { Box } from '@mui/material';

import QuotaCard from '../QuotaCard';
import { IQuotaSaleItem } from '../VehicleOverview';

const styles = () => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      gap: 2,
    },
  };
};

/**
 * Renders all the provided quota sales in a
 * series of formatted cards.
 */
const QuotaCards = (props: { quotaSales: IQuotaSaleItem[]; showConfirmationModal: any }) => {
  return (
    <Box sx={styles().container}>
      {/* Parse the list of quota sales and render the cards. */}
      {props.quotaSales.map((quota: IQuotaSaleItem) => (
        <QuotaCard
          key={quota.uuid}
          quota={quota}
          showConfirmationModal={props.showConfirmationModal}
        />
      ))}
    </Box>
  );
};

export default QuotaCards;
