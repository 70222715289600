import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './i18n';

import InvoiceTemplates from './components/Admin/invoice-templates-admin/InvoiceTemplates';
import Invoices from './components/Admin/invoicing-admin/Invoices';
import Uploads from './components/Admin/pdf-uploads/Uploads';
import Quotas from './components/Admin/quotas-admin/Quotas';
import Templates from './components/Admin/templates-admin/Templates';
import UserInfo from './components/Admin/user-info';
import Users from './components/Admin/users-admin/Users';
import Vehicles from './components/Admin/vehicles-admin/Vehicles';
import AdminPanel from './pages/AdminPanel';
import LoginRoute from './pages/LoginRoute';
import Ping from './pages/Ping';

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route
        path="/"
        element={<AdminPanel />}
      >
        <Route
          path="users"
          element={<Users />}
        />
        <Route
          path="user-info"
          element={<UserInfo />}
        />
        <Route
          path="templates"
          element={<Templates />}
        />
        <Route
          path="vehicles"
          element={<Vehicles />}
        />
        <Route
          path="quotas"
          element={<Quotas />}
        />
        <Route
          path="invoicing"
          element={<Invoices />}
        />
        <Route
          path="invoice-templates"
          element={<InvoiceTemplates />}
        />
        <Route
          path="uploads"
          element={<Uploads />}
        />
      </Route>
      <Route
        path="/login"
        element={<LoginRoute />}
      />
      <Route
        path="/ping"
        element={<Ping />}
      />
    </Routes>
  </BrowserRouter>
);

export default App;
