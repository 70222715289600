import { formatDate, formatDateTime } from '../../../utils/date';

export const Columns = [
  {
    field: 'tenant',
    headerName: 'Tenant',
    width: 100,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
  },
  {
    field: 'uuid',
    headerName: 'UUID',
    width: 300,
  },
  {
    field: 'ownerUsername',
    headerName: 'Owner',
    width: 300,
  },
  {
    field: 'updatedAt',
    headerName: 'Updated',
    width: 160,
    valueGetter: (params: any) => formatDateTime(params.row.updatedAt),
  },
  {
    field: 'createdAt',
    headerName: 'Created On',
    width: 120,
    valueGetter: (params: any) => formatDate(params.row.createdAt),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 130,
  },
  {
    field: 'quotaSales',
    headerName: 'Quota Status',
    width: 130,
    renderCell: (params: any) =>
      params.row.quotaSales && params.row.quotaSales.length && params.row.quotaSales[0].status,
  },
  {
    field: 'quotaSaleCreatedAt',
    headerName: 'UBA Submitted On',
    width: 160,
    valueGetter: (params: any) => formatDate(params?.row?.quotaSales?.[0]?.createdAt),
  },
  {
    field: 'carbonifyAuditor',
    headerName: 'Prüfer',
    width: 120,
  },
  {
    field: 'firstName',
    headerName: 'Vorname',
    width: 120,
  },
  {
    field: 'lastName',
    headerName: 'Nachname',
    width: 120,
  },
  {
    field: 'street',
    headerName: 'Strasse',
    width: 120,
  },
  {
    field: 'zip',
    headerName: 'PLZ',
    width: 80,
  },
  {
    field: 'city',
    headerName: 'Stadt',
    width: 120,
  },
  {
    field: 'registeredAt',
    headerName: 'Zugelassen (Feld I)',
    width: 120,
    valueGetter: (params: any) => formatDate(params.row.registeredAt),
  },
  {
    field: 'registeredUntil',
    headerName: 'Registered Until', // TODO: get the right translation
    width: 120,
    valueGetter: (params: any) => formatDate(params.row.registeredUntil),
  },
  {
    field: 'licensePlate',
    headerName: 'Kennzeichen',
    width: 120,
  },
  {
    field: 'carId',
    headerName: 'FIN',
    width: 120,
  },
  {
    field: 'mainInspectionDate',
    headerName: 'HU Datum',
    width: 120,
    valueGetter: (params: any) => {
      const { mainInspectionDate, registeredUntil } = params.row;
      return registeredUntil && mainInspectionDate ? formatDate(mainInspectionDate) : null;
    },
  },
  {
    field: 'firstRegisteredAt',
    headerName: 'Erstzulassung',
    width: 120,
    valueGetter: (params: any) => formatDate(params.row.firstRegisteredAt),
  },
  {
    field: 'manufacturer',
    headerName: 'Hersteller',
    width: 120,
  },
  {
    field: 'model',
    headerName: 'Modell',
    width: 120,
  },
  {
    field: 'vehicleClass',
    headerName: 'Fahrzeugklasse',
    width: 120,
  },
  {
    field: 'bevChecked',
    headerName: 'Elektro',
    width: 90,
  },
  {
    field: 'internalNote',
    headerName: 'Internal Note',
    width: 110,
  },
  {
    field: 'utmSource',
    headerName: 'UTM Source',
    width: 120,
    valueGetter: (params: any) => params.row.trackingParameters?.utmSource ?? '',
  },
  {
    field: 'utmMedium',
    headerName: 'UTM Medium',
    width: 120,
    valueGetter: (params: any) => params.row.trackingParameters?.utmMedium ?? '',
  },
  {
    field: 'utmCampaign',
    headerName: 'UTM Campaign',
    width: 120,
    valueGetter: (params: any) => params.row.trackingParameters?.utmCampaign ?? '',
  },
];
