import toast from 'react-hot-toast';
import { Button } from '@mui/material';

import { formatDate } from '../../../utils/date';

import { downloadFile } from './api/downloadFile';
import { toggleMarkInvoicePromise } from './api/markInvoice';

/**
 * Flag the provided upload id.
 *
 * @param uploadId number
 * @returns void
 */
const markInvoiceSample = (
  invoiceId: number,
  mark: boolean,
  setTriggerAction: Function,
  setRefreshList: Function,
  tenant: string,
) => {
  toggleMarkInvoicePromise(invoiceId, mark, setTriggerAction, setRefreshList, tenant)
    /**
     * Notify the user the upload that
     * the upload has been flagged.
     */
    .then(() => toast.success('Upload als bearbeitet markiert".'))
    .catch(() => toast.error('Upload als NICHT bearbeitet markiert.'));
};

export const Columns = (
  setTriggerAction: Function,
  setRefreshList: Function,
  fileDownloadLink: any,
  tenant: string,
) => [
  {
    field: 'tenant',
    headerName: 'Tenant',
    width: 100,
  },
  {
    field: 'debtorNumber',
    headerName: 'Debtor',
    width: 200,
  },
  {
    field: 'id',
    headerName: 'Invoice',
    width: 200,
  },
  {
    field: 'isCompany',
    headerName: 'Is company',
    width: 120,
  },
  {
    field: 'vatReclaimEntitled',
    headerName: 'Vorsteuerabzugsberechtigt',
    width: 200,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
  },
  {
    field: 'company',
    headerName: 'Company',
    width: 100,
    valueGetter: (params: any) => (!params.row.company ? 'none' : params.row.company),
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 300,
  },
  {
    field: 'accountOwner',
    headerName: 'Kontoinhaber',
    width: 200,
  },
  {
    field: 'bankAccountIBAN',
    headerName: 'IBAN',
    width: 200,
  },
  // Add download links
  {
    field: 'vehicleListLink',
    headerName: 'Vehicle List',
    width: 160,
    renderCell: (params: any) => {
      return (
        <Button
          variant="outlined"
          color="error"
          onClick={() =>
            downloadFile(
              'Vehicle List',
              `/v2/invoice/${params.id}/download/vehiclesList`,
              `vehicle_list_${params.invoiceId}_${formatDate(new Date())}`,
              fileDownloadLink,
              tenant,
            )
          }
        >
          DOWNLOAD
        </Button>
      );
    },
  },
  {
    field: 'creditNoteLink',
    headerName: 'Credit Note',
    width: 160,
    renderCell: (params: any) => {
      return (
        <Button
          variant="outlined"
          color="error"
          onClick={() =>
            downloadFile(
              'Credit Note',
              `/v2/invoice/${params.id}/download/creditNote`,
              `credit_note_${params.invoiceId}_${formatDate(new Date())}`,
              fileDownloadLink,
              tenant,
            )
          }
        >
          DOWNLOAD
        </Button>
      );
    },
  },
  {
    field: 'sampleInvoiceLink',
    headerName: 'Sample Invoice',
    width: 160,
    renderCell: (params: any) => {
      return (
        <Button
          variant="outlined"
          color="error"
          onClick={() =>
            downloadFile(
              'Sample Invoice',
              `/v2/invoice/${params.id}/download/sampleInvoice`,
              `sample_invoice_${params.invoiceId}_${formatDate(new Date())}`,
              fileDownloadLink,
              tenant,
            )
          }
        >
          DOWNLOAD
        </Button>
      );
    },
  },
  {
    field: 'actionButton',
    headerName: 'Markieren als',
    width: 150,
    cellClassName: 'center--cell',
    disableClickEventBubbling: true,
    renderCell: (params: any) => {
      return (
        <Button
          disabled={
            !params.row.isB2BSample ||
            !params.row.isB2B ||
            !params.row.vatReclaimEntitled ||
            params.row.invoiced ||
            params.row.issued
          }
          variant="contained"
          color="success"
          onClick={() => markInvoiceSample(params.row.id, true, setTriggerAction, setRefreshList, tenant)}
        >
          MARK INVOICED
        </Button>
      );
    },
  },
];
