import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { fetcher } from '../../../../utils/fetcher';

/**
 * Make an API request using the provided url.
 */
export const getCSVExport = (tenant: string | null, isB2C?: boolean): Promise<any> => {
  // Build up the complete url
  const url = `${API_URL}/invoice/csv-export/${isB2C ? 'b2c' : 'b2b'}`;
  const query = { tenant };
  return fetcher(queryString.stringifyUrl({ url, query }), {
    method: 'GET',
  })
    .then((data) => data)
    .catch((error) => {
      return error;
    });
};
