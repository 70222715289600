import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

function BooleanIcon(props: { success: boolean }) {
  return (
    <div style={{ width: '100px', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
      {props.success && <CheckCircleOutlineIcon color="success" />}
      {!props.success && <HighlightOffIcon color="warning" />}
    </div>
  );
}

export default BooleanIcon;
