import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';

import { ContextScopeEnum } from '../../context/GlobalState';

/**
 * Check if the marketable filter has been selected
 * and we have a few selections present.
 *
 * @param predefinedFilters IFilterItem[]
 * @param selectedUsernames string
 * @returns boolean
 */
const GetDebtorB2BCSVAction = (props: {
  downloadDebtorCSV: Function;
  context: ContextScopeEnum;
  selectedUsernames: string[];
}) => {
  const show = props.context === ContextScopeEnum.USERS;
  const hasNoneSelected = !props.selectedUsernames.length;

  if (show && hasNoneSelected) {
    return (
      <Button
        color="inherit"
        onClick={() => props.downloadDebtorCSV(false)}
      >
        <DownloadIcon />
        &nbsp;Debtor B2B CSV
      </Button>
    );
  }
  return <></>;
};

export default GetDebtorB2BCSVAction;
