import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_URL } from '../../constants/api';
import { fetcher } from '../../utils/fetcher';

type Filter = Record<string, string> & { vehicleUuid?: string };

const api = createApi({
  reducerPath: 'quotasApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/quota-sales`,
    fetchFn: (input, options) => fetcher(input, options, undefined, undefined, true),
  }),
  endpoints: (builder) => ({
    getQuotasByVehicleUuid: builder.query<any, { filter?: Filter; tenant: string }>({
      query: ({ filter, tenant }) => {
        let url = `?tenant=${tenant}`;

        if (filter && Object.keys(filter).length > 0) {
          url = `${url}&filter=${JSON.stringify(filter)}`;
        }

        return {
          url,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useLazyGetQuotasByVehicleUuidQuery } = api;
export default api;
