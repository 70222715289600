import { IFormConfig } from '../form-generator/interfaces';

export const templateFormConfig: IFormConfig = {
  /**
   * Here you can configure the layout of
   * the current form into tabs.
   */
  tabs: [
    {
      index: 1,
      name: 'Vorlageninformationen',
      groups: [
        // First row.
        {
          index: 1,
          fields: ['Tenant'],
        },
        {
          index: 2,
          fields: ['TemplateName'],
        },
        // Second row.
        {
          index: 3,
          fields: ['SubjectPart'],
        },
        // Third row.
        {
          index: 4,
          fields: ['HtmlPart', 'TextPart'],
        },
      ],
    },
  ],
  /**
   * Here you can configure the fields individually
   * so the field types and options can be handled
   * correctly by the form.
   */
  fieldsConfig: [
    { id: 1, fieldName: 'id', type: 'number', hidden: true },
    { id: 2, fieldName: 'TemplateName', type: 'string', disableIfMode: 'edit' },
    { id: 3, fieldName: 'SubjectPart', type: 'string', columns: 3 },
    { id: 4, fieldName: 'TextPart', type: 'textarea', columns: 3 },
    { id: 5, fieldName: 'HtmlPart', type: 'textarea', columns: 3 },
  ],
};
