import { useState } from 'react';
import toast from 'react-hot-toast';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useTenants } from '../../../../tenant';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../common/dialog/BootstrapDialog';
import InvoiceBatch from '../../../common/dialog/InvoiceBatch';
import { sendInvoices } from '../../../users-admin/api/sendInvoices';

interface SendInvoicesDialogProps {
  selectedIds: string[];
  setModal: Function;
}

export default function SendInvoicesDialog(props: SendInvoicesDialogProps) {
  const [limit, setLimit] = useState(300);
  const [skip, setSkip] = useState(0);
  const { currentTenant } = useTenants();

  const handleInvoiceTrigger = async () => {
    try {
      const res = await sendInvoices({ take: limit, skip, tenant: currentTenant });
      if (res.status !== 204) throw new Error('');
      toast.success('Invoice send triggered');
    } catch (error) {
      toast.error('Invoice send failed');
    } finally {
      props.setModal(null);
    }
  };

  return (
    <BootstrapDialog
      onClose={() => props.setModal(null)}
      aria-labelledby="customized-dialog-title"
      open={true}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => props.setModal(null)}
      >
        Send invoices
      </BootstrapDialogTitle>
      <DialogContent
        dividers
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Typography align="center">
          Are you sure you want to bulk send invoices for all quotas in status INVOICE_GENERATED?
        </Typography>
        <InvoiceBatch
          limit={limit}
          skip={skip}
          setLimit={setLimit}
          setSkip={setSkip}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setModal(null)}>Cancel</Button>
        <Button onClick={handleInvoiceTrigger}>Send invoices</Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
