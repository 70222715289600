import { IFormConfig } from '../form-generator/interfaces';

/* A form configuration. */
export const invoiceTemplateFormConfig: IFormConfig = {
  /**
   * Here you can configure the layout of
   * the current form into tabs.
   */
  tabs: [
    {
      index: 1,
      name: 'Vorlageninformationen',
      groups: [
        // First row.
        {
          index: 1,
          fields: ['id'],
        },
        // Second row.
        {
          index: 2,
          fields: ['content'],
        },
      ],
    },
  ],
  /**
   * Here you can configure the fields individually
   * so the field types and options can be handled
   * correctly by the form.
   */
  fieldsConfig: [
    { id: 1, fieldName: 'id', type: 'string', disabled: true },
    { id: 2, fieldName: 'content', type: 'textarea', columns: 3 },
    { id: 3, fieldName: 'tenant', type: 'string', hidden: true },
  ],
};
