import { vehicleClient } from '../../../../utils/api/clients';
import { getAccessToken } from '../../../../utils/auth';
import { IFilterItem } from '../../common/PredefinedFilters';
import { IPagination } from '../../context/GlobalState';

/**
 * Make an API request using the provided url.
 */
export const getVehiclesPromise = async (
  tenant: string,
  predefinedFilter?: IFilterItem,
  sort?: string[],
  pagination?: IPagination,
) => {
  // Calculate the range.
  const range = [pagination?.range?.[0], pagination?.range?.[1]];
  const token = await getAccessToken();

  return vehicleClient.GET('/vehicle-registrations', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      query: {
        filter: predefinedFilter! && JSON.stringify(predefinedFilter?.filter || {}),
        range: pagination! && JSON.stringify(range),
        sort: sort! && JSON.stringify(sort),
        tenant,
      },
    },
  });
};

export const getVehiclesByUsernamePromise = async (username: string, tenant: string) =>
  // @ts-expect-error FIXME
  vehicleClient.POST('/vehicle-registrations/users', {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    body: {
      usernames: [username],
    },
    params: {
      query: {
        tenant,
      },
    },
  });

export const getProductOptionsPromise = async (tenant: string) =>
  vehicleClient.GET('/admin/configuration/product-options', {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    params: {
      query: {
        tenant,
      },
    },
  });
