import queryString from 'query-string';

import { API_URL } from '../../../../constants/api';
import { DEFAULT_PAGE_SIZE } from '../../../../constants/pagination';
import { fetcher } from '../../../../utils/fetcher';
import { IPagination } from '../../context/GlobalState';
import { getVehiclesByUsernames } from '../../vehicles-admin/api/getVehiclesByUsernames';

export interface IUserSearchFilters {
  username?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
}

/**
 * It gets all the users from the API, then gets all the vehicles from the API, then maps the users to
 * their vehicles
 * @param  - {
 * @returns An object with the following properties:
 *   - data: An array of objects with the following properties:
 *     - username: The username of the user.
 *     - email: The email of the user.
 *     - firstName: The first name of the user.
 *     - lastName: The last name of the user.
 */
export const getUsersPromise = async (
  filters: IUserSearchFilters,
  pagination: IPagination,
  total: number,
  tenant?: string,
): Promise<any> => {
  const range = pagination.range ?? [0, total];
  const size = pagination.pageSize || DEFAULT_PAGE_SIZE;
  const page = !range[0] ? 0 : range[0] / size;

  // Add the required query params.
  const params = {
    username: filters.username ?? '',
    email: filters.email ?? '',
    firstName: filters.firstName ?? '',
    lastName: filters.lastName ?? '',
    tenant: tenant ?? '',
    page,
    size,
  };
  // Stringify the query params.
  const stringParams = `${queryString.stringify(params)}`;
  // Build up the complete url
  const url = `${API_URL}/users?${stringParams}`;

  /* A promise that returns the data from the API. */
  const users = await fetcher(url);

  /* Mapping the users to their usernames. */
  const usernames = users.data.map((user: any) => user.username);

  /* Getting all the vehicles from the API and mapping them to their usernames. */
  const vehicles = usernames?.length ? await getVehiclesByUsernames(usernames, tenant ?? null) : null;

  /**
   * 1. Transform received list of vehiclesByUsernames to plain vehicles list.
   * 2. Parse the list and enhance vehicles with the duplicateCheck properties.
   */

  /* Make sure we run the duplication check at this point as well. */

  // const withDuplicateMarkings = markDuplicates(response.data!);

  /* Mapping the users to their vehicles. */
  const data = users.data.map((user: any) => ({
    ...user,
    vehicleRegistrations:
      vehicles?.data?.find((vehicle: any) => vehicle.username === user.username)?.vehicleRegistrations || [],
  }));

  /* Returning the original users object with the data property replaced with the new data. */
  return { ...users, data };
};
