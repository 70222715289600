import type { FetchResponse } from 'openapi-fetch';

import { IChecksum } from '../vehicles-admin/api/getMarketable';

export class ResponseProcessorHelper {
  public process<T extends { responses: any }>(
    response: FetchResponse<T>,
    setChecksum: (value: IChecksum) => void,
    setTotal: (value: number) => void,
  ): void {
    if (response.data?.checksum) {
      setChecksum(response.data.checksum);
    }
    const totalCount =
      response.response.headers.get('x-total-count') ?? response.data?.count ?? response.data?.checksum?.count;
    if (totalCount) {
      setTotal(parseInt(totalCount) || 0);
    }
  }

  /**
   * It processes the response from the server and sets the checksum and total number of items in the
   * store
   * @param {any} response - The response from the server.
   * @param setChecksum - This is a function that will be called to set the checksum value.
   * @param setTotal - (value: number) => void
   * @returns The processed response.
   */
  public processResponse(
    response: any,
    setChecksum: (value: IChecksum) => void,
    setTotal: (value: number) => void,
  ): any {
    // Process the checksum required by the predefined filters.
    let processed = this.processChecksum(response, setChecksum);
    // Process the count object from the response.
    processed = this.processItemsCount(response, setTotal);

    return processed;
  }

  /**
   * It adds a random unique id to all the rows so the data grid doesn't complain.
   * @param {any[]} list - any[] - The list of objects you want to add the id to.
   * @returns An array of objects with a random id and the rest of the object.
   */
  public addIDs(list: any[]): any[] {
    /**
     * Add random unique id to all the
     * rows so the data grid doesn't complain.
     */
    return list.map((t: any) => ({ id: Math.floor(Math.random() * 100000), ...t }));
  }

  /**
   * If the response contains a checksum, then set the checksum and return the data
   * @param {any} response - The response from the server.
   * @param setChecksum - (value: IChecksum) => void
   * @returns The response is being returned.
   */
  private processChecksum(response: any, setChecksum: (value: IChecksum) => void): any {
    // Check if the checksum info is there.
    if ('checksum' in response) {
      setChecksum(response.checksum);
      return [...response.data];
    }
    return response;
  }

  /**
   * If the response contains a totalCount property, set the total count and return the data.
   * Otherwise, return the response
   * @param {any} response - the response from the server
   * @param setTotal - (value: number) => void - this is a function that will be called to set the
   * total number of items in the list.
   * @returns The response is being returned.
   */
  private processItemsCount(response: any, setTotal: (value: number) => void): any {
    if ('totalCount' in response) {
      setTotal(parseInt(response.totalCount));
      return [...response.data];
    }
    return response;
  }
}
