import { useContext } from 'react';
import { Copyright } from '@mui/icons-material';
import { Alert, Box, Container, Grid, Snackbar, Toolbar } from '@mui/material';

import ProgressCard from '../common/cards/ProgressCard';
import FilterMenu from '../common/FilterMenu';
import { productOptionFilters, quotaStatusFilters, yearFilters } from '../common/PredefinedFilters/quotaSaleFilters';
import { ContextActionEnum, GlobalContext } from '../context/GlobalState';

import ApproveQuotaSalesDialog from './dialogs/ApproveQuotaSalesDialog/ApproveQuotaSalesDialog';
import CreateUBASubmissionDialog from './dialogs/CreateUBASubmissionDialog';
import DeclineQuotaSalesDialog from './dialogs/DeclineQuotaSalesDialog/DeclineQuotaSalesDialog';
import EditQuotaSalesDialog from './dialogs/EditQuotaSalesDialog/EditQuotaSalesDialog';
import GenerateInvoicesDialog from './dialogs/GenerateInvoicesDialog/GenerateInvoicesDialog';
import PayQuotaSalesDialog from './dialogs/PayQuotaSalesDialog/PayQuotaSalesDialog';
import SellQuotasDialog from './dialogs/SellQuotasDialog/SellQuotasDialog';
import SendInvoicesDialog from './dialogs/SendInvoicesDialog/SendInvoicesDialog';
import SubmitQuotaSalesDialog from './dialogs/SubmitQuotaSalesDialog/SubmitQuotaSalesDialog';
import QuotasList from './QuotasList';

function Quotas() {
  /**
   * Get all the required properties from the
   * global context.
   */
  const {
    modal,
    quotas,
    triggerAction,
    setTriggerAction,
    setModal,
    total,
    selectedIds,
    setSelectedIds,
    predefinedFilters,
    setPredefinedFilters,
  } = useContext(GlobalContext);
  // Used to find out if the vehicle details modal should open.
  const hasOneSelected = selectedIds && selectedIds.length === 1;
  // Find quota sale
  const findQuota = () => {
    if (!hasOneSelected) return null;

    const currentQuotaIndex = quotas?.findIndex((v) => v?.uuid === selectedIds[0]);
    if (currentQuotaIndex < 0) return null;

    return quotas[currentQuotaIndex] ?? null;
  };

  const currentQuota = findQuota();

  /**
   * Handle the completed action of sending emails.
   * We need to empty the selected rows and remove
   * the ProgressCard
   */
  const sendComplete = () => {
    // Close the action progress card.
    setTriggerAction(null);
    // De-select the previously selected rows.
    setSelectedIds([]);
    // Refresh the currently shown list of quotas.
    setPredefinedFilters([...predefinedFilters]);
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />

      {modal && modal.type === ContextActionEnum.EDIT_QUOTA && hasOneSelected && (
        <EditQuotaSalesDialog
          setModal={setModal}
          quota={{ ...currentQuota, vehicleClass: currentQuota?.vehicle?.vehicleClass }}
        />
      )}

      <Container
        sx={{ mt: 4, mb: 4 }}
        maxWidth={false}
      >
        <Grid
          container
          spacing={2}
        >
          {triggerAction && (
            <Grid
              item
              xs={12}
            >
              <ProgressCard />
            </Grid>
          )}

          {/* The sidebar for the filters. */}
          <Grid item>
            <FilterMenu filters={yearFilters} />
            <FilterMenu filters={productOptionFilters} />
            <FilterMenu filters={quotaStatusFilters} />
          </Grid>

          <Grid
            item
            xs
          >
            <QuotasList />
          </Grid>
        </Grid>
        <Copyright sx={{ pt: 4 }} />
      </Container>

      {/* The Generate Invoices Dialog. */}
      {modal?.type === ContextActionEnum.GENERATE_INVOICE && (
        <GenerateInvoicesDialog
          selectedIds={selectedIds}
          setModal={setModal}
        />
      )}

      {/* The Send Invoices Dialog. */}
      {modal?.type === ContextActionEnum.SEND_INVOICE && (
        <SendInvoicesDialog
          selectedIds={selectedIds}
          setModal={setModal}
        />
      )}

      {/* The Submit Quotas Dialog. */}
      {modal && modal.type === ContextActionEnum.SUBMIT_QUOTAS && (
        <SubmitQuotaSalesDialog
          selectedIds={selectedIds}
          setModal={setModal}
          total={total}
        />
      )}

      {/* The Approve Quota Sales Dialog. */}
      {selectedIds &&
        selectedIds.length > 0 &&
        modal &&
        modal.type === ContextActionEnum.SHOW_DIALOG_APPROVE_QUOTA_SALES && (
          <ApproveQuotaSalesDialog
            selectedIds={selectedIds}
            setModal={setModal}
            total={total}
          />
        )}

      {/* The Approve Quota Sales Dialog. */}
      {selectedIds &&
        selectedIds.length > 0 &&
        modal &&
        modal.type === ContextActionEnum.SHOW_DIALOG_DECLINE_QUOTA_SALES && (
          <DeclineQuotaSalesDialog
            selectedIds={selectedIds}
            setModal={setModal}
            total={total}
          />
        )}

      {/* The Approve Quota Sales Dialog. */}
      {modal && modal.type === ContextActionEnum.BULK_SELL_QUOTAS && (
        <SellQuotasDialog
          selectedIds={selectedIds}
          modal={modal}
          setModal={setModal}
          total={total}
        />
      )}

      {/* The CREATE UBA SUBMISSION dialog. */}
      {modal && modal.type === ContextActionEnum.CREATE_UBA_SUBMISSION && (
        <CreateUBASubmissionDialog
          modal={modal}
          setModal={setModal}
        />
      )}

      {/* The Pay Quota Sales Dialog. */}
      {selectedIds && selectedIds.length > 0 && modal && modal.type === ContextActionEnum.MARK_PAID_OUT && (
        <PayQuotaSalesDialog
          selectedIds={selectedIds}
          setModal={setModal}
          total={total}
        />
      )}

      {triggerAction && triggerAction.progress > -1 && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          autoHideDuration={6000}
          onClose={sendComplete}
        >
          <Alert
            onClose={sendComplete}
            severity="success"
            sx={{ width: '100%' }}
          >
            {`${triggerAction.type.toString()} completed successfully!`}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}

export default Quotas;
