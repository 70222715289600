import { parsePhoneNumber } from 'libphonenumber-js';

import { calculateVinCheckDigit } from '../../../../utils/vin-utils';

/**
 * Enumerates the type of validation
 * errors the user might encounter.
 * Based on this enum we identify what
 * type of error message should be
 * displayed to the user.
 */
enum VALIDATION_ERROR_TYPE {
  MIN_LENGTH,
  MAX_LENGTH,
  EMAIL,
  REQUIRED,
  OWNERUSERNAME,
  EQUALS,
  INCORRECT_STATUS,
  INCORRECT_SMATRICS_CARD_ID,
  INCORRECT_VIN,
  PHONE_NUMBER_NO_PREFIX,
  PHONE_NUMBER_INVALID,
  PHONE_NUMBER_STARTS_ZERO,
}

/**
 * Check if the provided value is truthy
 * or falsey.
 *
 * @param value any
 * @returns boolean
 */
export const required = (value: any): string => {
  return value ? '' : errorMessages.find((e) => e.type === VALIDATION_ERROR_TYPE.REQUIRED)!.message;
};

/**
 * Check if there is a value because it depends
 * if the email address was filled and the owner
 * id was found.
 *
 * @param value any
 * @returns boolean
 */
const ownerUsernameFound = (value: any): string => {
  return value ? '' : errorMessages.find((e) => e.type === VALIDATION_ERROR_TYPE.OWNERUSERNAME)!.message;
};

/**
 * Check if the provided value is a valid
 * email address.
 *
 * @param value string
 * @returns boolean
 */
const email = (value: string): string => {
  const regex =
    // eslint-disable-next-line no-control-regex
    /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return regex.test(value) ? '' : errorMessages.find((e) => e.type === VALIDATION_ERROR_TYPE.EMAIL)!.message;
};

/**
 * Check if the provided value has the provided
 * minimum length.
 *
 * @param value string
 * @param length number
 * @returns boolean
 */
const minLength = (value: string, length = 3): string => {
  return value && value.length >= length
    ? ''
    : errorMessages.find((e) => e.type === VALIDATION_ERROR_TYPE.MIN_LENGTH)!.message;
};

/**
 * Check if the provided value matches the
 * provided property value.
 *
 * @param value string
 * @param requiredValue any
 * @returns boolean
 */
export const noValue = (value: any): string => {
  return !value || value === 'No errors'
    ? ''
    : errorMessages.find((e) => e.type === VALIDATION_ERROR_TYPE.EQUALS)!.message;
};

/**
 * Check if the provided value matches the
 * provided property value.
 *
 * @param value string
 * @param requiredValue any
 * @returns boolean
 */
export const hasCorrectStatus = (value: any): string => {
  return ['INCOMPLETE_RESUBMIT', 'UPLOADED', 'AI_PARSED'].includes(value)
    ? ''
    : errorMessages.find((e) => e.type === VALIDATION_ERROR_TYPE.INCORRECT_STATUS)!.message;
};

/**
 * Check if there are any duplicate quota
 * reports.
 */
export const hasDuplicateQuota = (value: any): string => {
  return value && value.duplicatesFound ? 'duplication found' : '';
};

/**
 * It returns an error message if the value is not equal to 'INCOMPLETE'
 * @param {any} value - any - the value of the field being validated
 * @returns A function that takes a value and returns a string.
 */
export const hasIncompleteStatus = (value: any): string => {
  return value === 'INCOMPLETE'
    ? ''
    : errorMessages.find((e) => e.type === VALIDATION_ERROR_TYPE.INCORRECT_STATUS)!.message;
};

/**
 * Check if the field corresponds to a Smatrics Card ID.
 *
 * @param value string
 * @returns string
 */
const isSmatricsCardId = (value: string) => {
  // Fetch the validation message in case we need it.
  const message = errorMessages.find((e) => e.type === VALIDATION_ERROR_TYPE.INCORRECT_SMATRICS_CARD_ID)!.message;
  return value === '' || /^\d{6}$/.test(value) ? '' : message;
};

export const isValidVin = (value: string | undefined, checkDigit?: string) => {
  if (!value) {
    return '';
  }

  if (!checkDigit || checkDigit !== calculateVinCheckDigit(value)) {
    return errorMessages.find((e) => e.type === VALIDATION_ERROR_TYPE.INCORRECT_VIN)!.message;
  }

  return '';
};

const isPhoneNumberValid = (phoneNumber: string | undefined) => {
  if (!phoneNumber || !phoneNumber.length) {
    return '';
  }
  try {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);

    if (!parsedPhoneNumber) {
      const message = errorMessages.find((e) => e.type === VALIDATION_ERROR_TYPE.PHONE_NUMBER_INVALID)!.message;
      return message;
    }

    if (!parsedPhoneNumber.isPossible() || !parsedPhoneNumber.isValid()) {
      const message = errorMessages.find((e) => e.type === VALIDATION_ERROR_TYPE.PHONE_NUMBER_INVALID)!.message;
      return message;
    }
    if (!parsedPhoneNumber.countryCallingCode) {
      const message = errorMessages.find((e) => e.type === VALIDATION_ERROR_TYPE.PHONE_NUMBER_NO_PREFIX)!.message;
      return message;
    }
    // the +1 is from the + sign from the beginning of the phone number
    const nationalNumber = phoneNumber.substring(parsedPhoneNumber.countryCallingCode.length + 1);
    if (nationalNumber.startsWith('0')) {
      const message = errorMessages.find((e) => e.type === VALIDATION_ERROR_TYPE.PHONE_NUMBER_STARTS_ZERO)!.message;
      return message;
    }

    return '';
  } catch (err) {
    const message = errorMessages.find((e) => e.type === VALIDATION_ERROR_TYPE.PHONE_NUMBER_INVALID)!.message;
    return message;
  }
};

/**
 * Defines the error messages based on the type
 * of validation error.
 */
const errorMessages = [
  { type: VALIDATION_ERROR_TYPE.MIN_LENGTH, message: `Sollte mindestens 3 Zeichen haben.` },
  { type: VALIDATION_ERROR_TYPE.MAX_LENGTH, message: `Sollte maximal 30 Zeichen lang sein.` },
  { type: VALIDATION_ERROR_TYPE.EMAIL, message: `Keine gültige E-Mail-Adresse.` },
  { type: VALIDATION_ERROR_TYPE.REQUIRED, message: `Dieses Feld sollte einen Wert enthalten.` },
  { type: VALIDATION_ERROR_TYPE.OWNERUSERNAME, message: `E-Mail ausfüllen und Suchen drücken.` },
  { type: VALIDATION_ERROR_TYPE.EQUALS, message: `Das Feld entspricht nicht dem erforderlichen Wert.` },
  { type: VALIDATION_ERROR_TYPE.INCORRECT_STATUS, message: `Das Fahrzeug hat nicht den richtigen Status.` },
  { type: VALIDATION_ERROR_TYPE.INCORRECT_SMATRICS_CARD_ID, message: `Ungültige KartenNr (6 Ziffern).` },
  { type: VALIDATION_ERROR_TYPE.INCORRECT_VIN, message: `Die FIN scheint nicht korrekt zu sein.` },
  { type: VALIDATION_ERROR_TYPE.PHONE_NUMBER_NO_PREFIX, message: `Die Telefonnummer hat keine Vorwahl.` },
  { type: VALIDATION_ERROR_TYPE.PHONE_NUMBER_INVALID, message: `Die Telefonnummer scheint nicht korrekt zu sein.` },
  {
    type: VALIDATION_ERROR_TYPE.PHONE_NUMBER_STARTS_ZERO,
    message: `Die Telefonnummer darf nach der Vorwahl nicht mit einer 0 beginnen.`,
  },
];

/**
 * The configuration of the validators used
 * for fields.
 */
export const validatorsConfigurationByFieldName = [
  { fieldName: 'firstName', validators: [] },
  { fieldName: 'lastName', validators: [required] },
  { fieldName: 'email', validators: [required, email] },
  { fieldName: 'ownerUsername', validators: [ownerUsernameFound] },
  { fieldName: 'remoteEmail', validators: [required, email] },
  { fieldName: 'TemplateName', validators: [required, minLength] },
  { fieldName: 'SubjectPart', validators: [required, minLength] },
  { fieldName: 'smatricsCardId', validators: [isSmatricsCardId] },
  { fieldName: 'phoneNumber', validators: [isPhoneNumberValid] },
];
