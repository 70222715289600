import { IFormConfig } from '../form-generator/interfaces';

export const quotaFormConfig: IFormConfig = {
  /**
   * Here you can configure the layout of
   * the current form into tabs.
   */
  tabs: [
    {
      index: 1,
      name: 'Quota',
      groups: [
        {
          index: 1,
          fields: ['tenant', 'id', 'uba_year'],
        },
        {
          index: 2,
          fields: ['status', 'vehicleId', 'vehicleClass'],
        },
        {
          index: 3,
          fields: ['productOption', 'productPrice'],
        },
        {
          index: 4,
          fields: ['invoiceId', 'quotaSaleWaveId'],
        },
        {
          index: 5,
          fields: ['createdAt', 'updatedAt', 'submittedAt'],
        },
        {
          index: 6,
          fields: ['approvalDate', 'soldAt', 'payoutDate'],
        },
        {
          index: 7,
          fields: ['declineDate', 'rejectedAt', 'rejectedReason'],
        },
      ],
    },
  ],
  /**
   * Here you can configure the fields individually
   * so the field types and options can be handled
   * correctly by the form.
   */
  fieldsConfig: [
    { id: 1, fieldName: 'tenant', type: 'string', disabled: true },
    { id: 2, fieldName: 'id', type: 'number', disabled: true },
    { id: 3, fieldName: 'vehicleId', type: 'number', disabled: true },
    { id: 4, fieldName: 'invoiceId', type: 'number', disabled: true },
    { id: 5, fieldName: 'quotaSaleWaveId', type: 'number', disabled: true },
    { id: 6, fieldName: 'submittedAt', type: 'string', disabled: true },
    { id: 7, fieldName: 'approvalDate', type: 'string', disabled: true },
    { id: 8, fieldName: 'declineDate', type: 'string', disabled: true },
    { id: 9, fieldName: 'payoutDate', type: 'string', disabled: true },
    { id: 10, fieldName: 'rejectedAt', type: 'string', disabled: true },
    { id: 11, fieldName: 'rejectedReason', type: 'string', disabled: true },
    { id: 12, fieldName: 'soldAt', type: 'string', disabled: true },
    { id: 13, fieldName: 'status', type: 'string', disabled: true },
    { id: 14, fieldName: 'uba_year', type: 'string', disabled: true },
    { id: 15, fieldName: 'createdAt', type: 'string', disabled: true },
    { id: 16, fieldName: 'updatedAt', type: 'string', disabled: true },
    {
      id: 17,
      fieldName: 'productOption',
      type: 'select',
      options: [
        { name: 'INSTANT', value: 'INSTANT' },
        { name: 'STANDARD', value: 'STANDARD' },
        { name: 'BONUS', value: 'BONUS' },
      ],
      saveOnlyIfChanged: true,
    },
    { id: 18, fieldName: 'productPrice', type: 'string', disabled: true },
    { id: 19, fieldName: 'vehicleClass', type: 'string', disabled: true },
    { id: 20, fieldName: 'uuid', type: 'string', disabled: true, hidden: true },
  ],
};
